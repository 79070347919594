<template>
    <div class="row">
        <div class="col-12">
            <div class="page-title-box">
                <BreadCrumbs />
            </div>
        </div>
    </div>

    <div class="row" v-if="itemData">
        <div class="col-lg-4 col-md-4 col-sm-12">
            <div class="card-box">
                <div class="card-content">
                    <div class="header-content text-center">
                        <div v-if="itemData.profile_image">
                            <img class="mb-20 d-block mx-auto rounded-circle img-thumbnail"
                                :src="itemData.profile_image" alt="Profile Image" />

                            <input ref="fileInput" type="file" accept=".png, .jpg" @change="handleFileChange"
                                style="display: none" />
                        </div>

                        <AvatarForInitials :avatarText="getUserInitialsForName(userName)" iconColor="primary"
                            class="avatar-md-150 mb-30 m-auto" v-else />

                        <h4 class="mb-0">{{ userName }}</h4>
                        <p class="mb-0">{{ itemData.email }}</p>
                        <p class="mb-20">{{ itemData.msisdn }}</p>
                    </div>

                    <div class="middle-content mb-100">
                        <div class="details-content">
                            <img src="../../../assets/images/icons/calendar_icon.svg" alt="Mobile Icon" />
                            <div class="details">
                                <p class="muted-text-sm">Added On</p>
                                <p class="result-text">{{ userCreatedAt }}</p>
                            </div>
                        </div>

                        <div class="details-content">
                            <img src="../../../assets/images/icons/folder_icon.svg" class="data-img"
                                alt="Mobile Icon" />

                            <div class="detail-content-group">
                                <p class="data-title">Projects</p>
                                <p class="data-text">{{ itemData.total_projects }}</p>
                            </div>
                        </div>

                        <div class="details-content">
                            <img src="../../../assets/images/icons/users_icon.svg" class="data-img" alt="Mobile Icon" />

                            <div class="detail-content-group">
                                <p class="data-title">Students</p>
                                <p class="data-text">{{ itemData.total_students }}</p>
                            </div>
                        </div>
                    </div>

                    <div class="buttons-content">
                        <RegularButtonBlock buttonText="Edit Profile" iconText="mdi-account-edit"
                            class="custom-button mb-3" @clicked="editAction" />
                    </div>
                </div>
            </div>
        </div>

        <div class="col-lg-8 col-md-4 col-sm-12">
            <div class="card-box-no-pd">
                <div class="page-title-box mb-30">
                    <div class="page-title-gray">
                        <h4>Co-Learner's Profile</h4>
                    </div>
                </div>

                <div class="card-content border-bottom plr-50">
                    <div class="row">
                        <div class="col-lg-6 col-sm-12">
                            <div class="detail-group mb-30">
                                <span class="muted-text-sm">Full Name</span>
                                <p>{{ userName }}</p>
                            </div>

                            <div class="detail-group mb-30">
                                <span class="muted-text-sm">Contact Number</span>
                                <p>{{ itemData.msisdn }}</p>
                            </div>

                            <div class="detail-group mb-30">
                                <span class="muted-text-sm">Country of Residence</span>
                                <p>{{ itemData.country }}</p>
                            </div>

                            <div class="detail-group mb-30">
                                <span class="muted-text-sm">
                                    Bachelor's degree in an education related field?
                                </span>
                                <p>{{ isTrueValue(itemData.has_bachelors_degree) ? 'Yes' : 'No' }}</p>
                            </div>

                            <div class="detail-group mb-30">
                                <span class="muted-text-sm">
                                    Are you familiar with Early Years Education / EYFS / Pre-school education?
                                </span>
                                <p>{{ isTrueValue(itemData.early_years_education) ? 'Yes' : 'No' }}</p>
                            </div>

                            <div class="detail-group mb-30">
                                <span class="muted-text-sm">
                                    LinkedIn
                                </span>
                                <p>{{ itemData.linkedin }}</p>
                            </div>

                            <div class="detail-group mb-30">
                                <span class="muted-text-sm">Residential Address</span>
                                <p>{{ itemData.address }}</p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-12">
                            <div class="detail-group mb-30">
                                <span class="muted-text-sm">Gender</span>
                                <p>{{ itemData._gender }}</p>
                            </div>
                            <div class="detail-group mb-30">
                                <span class="muted-text-sm">Email Address</span>
                                <p>{{ itemData.email }}</p>
                            </div>
                            <div class="detail-group mb-30">
                                <span class="muted-text-sm">State of Residence</span>
                                <p>{{ itemData.state }}</p>
                            </div>

                            <div class="detail-group mb-30">
                                <span class="muted-text-sm">
                                    How did you hear about the KEY app?
                                </span>
                                <p>{{ itemData.how_you_heard_about_us }}</p>
                            </div>

                            <div class="detail-group mb-30">
                                <span class="muted-text-sm">
                                    How many years experience do you have teaching early years / preschool aged
                                    children?
                                </span>
                                <p>{{ itemData.years_of_experience }}</p>
                            </div>

                            <div class="detail-group mb-30">
                                <span class="muted-text-sm">
                                    What is your purpose / objective for using the KEY app?
                                </span>
                                <p>{{ itemData.purpose_using_the_app }}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="ptb-30 plr-50">
                    <p class="dark-p mb-10">Turn on/off to activate or deactivate this account</p>
                    <Switch :switchText="switchTextLabel" v-model="accountActive" @change="handleSwitchToggle"
                        :loading="loading" :disabled="loading" />
                </div>

                <FormModal :isFormModalOpen="isEditFormModalOpen" @closeFormModal="closeEditFormModal"
                    modalHeader="Edit Co-Learner">
                    <EditColearner @closeFormModal="closeEditFormModal" @message="showFlashMessage"
                        :itemData="itemDataForEditing" @refreshPage="fetchItemData" />
                </FormModal>
            </div>
        </div>
    </div>
</template>

<script>
import Switch from "@/components/common/Switch.vue";
import RegularButtonBlock from "@/components/common/RegularButtonBlock.vue";
import BreadCrumbs from "@/components/common/BreadCrumbs.vue";
import { onMounted, ref, watch } from "vue";
import {
    capitalizeFirstLetter,
    formatDateTime,
    getCurrentUser,
    getUserInitialsForName,
    getValidToken
} from '@/utils/helpers'
import { checkAndRefreshToken } from '@/utils/auth';
import { API_BASE_URL } from "@/config.js";
import { flashMessage } from "@/utils/flashMessage.js";
import MessageSliderCopy from "@/components/common/MessageSliderCopy.vue";
import FormModal from "@/components/common/FormModal.vue";
import EditColearner from "@/components/layouts/GodAdmin/Colearners/EditColearner.vue";
import { useRoute } from "vue-router";
import AvatarForInitials from "@/components/common/AvatarForInitials.vue";
import { useToast } from "@/composables/useToast";


export default {
    components: {
        AvatarForInitials,
        EditColearner,
        FormModal,
        Switch,
        RegularButtonBlock,
        BreadCrumbs,
        MessageSliderCopy
    },
    props: {
        id: [String, Number]
    },
    emits: ['showLoader', 'show-loader', 'flashMessage', 'data-loaded'],
    setup(props, { emit }) {
        const { success, error: showError, warning } = useToast();
        const isEditFormModalOpen = ref(false);
        const itemData = ref(null);
        const userName = ref('');
        const userRole = ref('');
        const userCreatedAt = ref('');
        const loading = ref(false);
        const accountActive = ref(false);
        const switchTextLabel = ref('');
        const messageText = ref("");
        const messageType = ref("");
        const displayMessage = ref(false);
        const currentUser = getCurrentUser();
        const router = useRoute();
        const itemId = router.params.id;
        const accessToken = getValidToken();

        const fetchItemData = async () => {
            const apiUrl = `${API_BASE_URL}/teacher/get-teacher/${itemId}`;

            try {
                emit('show-loader');
                const response = await fetch(apiUrl, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    }
                });

                if (response.ok) {
                    const result = await response.json();
                    itemData.value = result.data;

                    accountActive.value = !isTrueValue(result.data.isDeactivated);
                    updateSwitchLabel();
                } else {
                    console.log("response", response);
                    console.error("Error fetching data from the API");
                    showError("Failed to load co-learner data");
                }
            } catch (error) {
                console.error('An error occurred: ', error);
                showError("Failed to fetch co-learner data");
            }
        };

        onMounted(() => {
            fetchItemData()
        })

        watch(itemData, (item) => {
            if (!item) return;

            userName.value = capitalizeFirstLetter(item.first_name, item.last_name);
            userRole.value = capitalizeFirstLetter(item.role_name);
            const { date, time } = formatDateTime(item.created_at);
            userCreatedAt.value = `${date}, ${time}`;

            accountActive.value = !isTrueValue(item.isDeactivated);
            updateSwitchLabel();

            emit('data-loaded');
        })

        const updateSwitchLabel = () => {
            switchTextLabel.value = accountActive.value ? 'Account Active' : 'Account Deactivated';
        };

        const openEditFormModal = () => {
            isEditFormModalOpen.value = true;
        };

        const closeEditFormModal = async (message) => {
            isEditFormModalOpen.value = false;
            if (message) {
                success(message);
            }
        };

        const itemDataForEditing = ref(null)
        const editAction = () => {
            itemDataForEditing.value = {
                firstName: itemData.value.first_name,
                lastName: itemData.value.last_name,
                gender: itemData.value._gender,
                msisdn: itemData.value.msisdn,
                email: itemData.value.email,
                country: itemData.value.country,
                state: itemData.value.state,
                address: itemData.value.address,
                has_bachelors_degree: itemData.value.has_bachelors_degree,
                early_years_education: itemData.value.early_years_education,
                how_you_heard_about_us: itemData.value.how_you_heard_about_us,
                years_of_experience: itemData.value.years_of_experience,
                linkedin: itemData.value.linkedin,
                purpose_using_the_app: itemData.value.purpose_using_the_app,
                id: props.id || itemId
            }

            openEditFormModal()
        }

        const handleSwitchToggle = (event) => {
            if (event && typeof event === 'object') {
                const userId = props.id || itemId;
                toggleUserStatus(userId);
            }
        };

        const toggleUserStatus = async (id) => {
            const apiUrl = `${API_BASE_URL}/teacher/delete-teacher`;
            const accessToken = getValidToken();
            const currentActiveStatus = accountActive.value;

            try {
                await checkAndRefreshToken();
                showLoaders();

                const response = await fetch(apiUrl, {
                    method: 'DELETE',
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`
                    },
                    body: JSON.stringify({
                        teacher_id: id,
                        reason: ''
                    })
                });

                if (response.ok) {
                    const result = await response.json();

                    if (result.code === 200) {
                        await fetchItemData();  // Refresh data to get updated isDeactivated status

                        const successMessage = accountActive.value
                            ? "Co-Learner account activated successfully"
                            : "Co-Learner account deactivated successfully";
                        success(successMessage);
                    } else {
                        // Revert UI if API call failed
                        accountActive.value = currentActiveStatus;
                        updateSwitchLabel();

                        showError(result.message || "Failed to update account status");
                        console.log('error', result.message);
                    }
                } else {
                    // Revert UI if API call failed
                    accountActive.value = currentActiveStatus;
                    updateSwitchLabel();

                    let errorMessage = "Failed to update account status";
                    try {
                        const result = await response.json();
                        errorMessage = result.message || errorMessage;
                    } catch (e) {
                        console.error("Error parsing error response:", e);
                    }

                    showError(errorMessage);
                    console.log("error", errorMessage);
                }
            } catch (error) {
                // Revert UI if API call failed
                accountActive.value = currentActiveStatus;
                updateSwitchLabel();

                showError("An error occurred while updating account status");
                console.error("An error occurred:", error);
            } finally {
                hideLoaders();
            }
        };

        const showLoaders = () => {
            loading.value = true;
        };

        const hideLoaders = () => {
            loading.value = false;
        };

        const showFlashMessage = (msg) => {
            if (msg.type === "success") {
                success(msg.apiResponse);
            } else if (msg.type === "error") {
                showError(msg.apiResponse);
            } else if (msg.type === "warning") {
                warning(msg.apiResponse);
            }
        };

        const isTrueValue = (value) => {
            return value === true || value === 'true' || value === 'Yes' || value === 'yes' || value === 'YES';
        };

        return {
            loading,
            itemData,
            userName,
            userRole,
            userCreatedAt,
            accountActive,
            switchTextLabel,
            getUserInitialsForName,
            handleSwitchToggle,
            messageText,
            messageType,
            displayMessage,
            currentUser,
            editAction,
            closeEditFormModal,
            isEditFormModalOpen,
            showFlashMessage,
            itemDataForEditing,
            fetchItemData,
            isTrueValue
        }
    }
};
</script>

<style scoped>
.data-text {
    color: #0D44B2;
}

.detail-content-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.detail-content-group>p {
    margin: 0;
}

.data-img {
    margin-top: 2px !important;
}

.border-bottom {
    border-bottom: 2px solid #F5F6FA;
}

.header-content {
    padding: 30px 0 20px 0;
    position: relative;
    border-bottom: 1px solid #e9eaf3;
}

p {
    color: #9a9a9a;
    font-size: 16px;
}

.details-content {
    width: 100%;
    display: flex;
    align-items: flex-start;
    padding: 30px 30px 0 30px;
}

.details-content:last-child,
.details-content:first-child {
    border-bottom: 1px solid #e9eaf3;
    padding-bottom: 30px;
}

.details-content>img {
    margin-right: 15px;
    margin-top: 5px;
}

.details-content .details>p {
    margin-bottom: 0;
}

.logo-text>img {
    margin-right: 15px;
    margin-top: 2px;
}

.logo-text>p {
    color: #333333;
}

.detail-group>p,
.result-text,
.data-title {
    color: #333;
}

.item>.count {
    color: #2f5bcf;
}

.buttons-content {
    padding: 0 15px 40px 15px;
}

.card-box {
    padding: 0 15px 0 15px
}

.page-title-gray {
    background-color: var(--black);
    width: 100%;
    border-radius: 12px 12px 0 0;
}

.page-title-gray h4 {
    color: white;
}
</style>