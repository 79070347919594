<template>
    <div class="custom-control custom-switch" :class="{ 'disabled': disabled }">
        <input type="checkbox" class="custom-control-input" :id="id" v-model="localChecked"
            :disabled="disabled || loading" @change="handleChange" />
        <label class="custom-control-label" :for="id">
            {{ switchText }}
            <span v-if="description" class="switch-description">{{ description }}</span>
        </label>
        <div v-if="loading" class="spinner-border text-secondary ml-2" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</template>

<script>
import { ref, watch, computed } from "vue";

export default {
    name: 'Switch',
    props: {
        /**
         * The text to display next to the switch
         */
        switchText: {
            type: String,
            default: "Toggle"
        },
        /**
         * Additional description text
         */
        description: {
            type: String,
            default: ""
        },
        /**
         * Unique identifier for the switch
         */
        id: {
            type: String,
            default: () => `switch-${Math.random().toString(36).substr(2, 9)}`
        },
        /**
         * Whether the switch is checked
         */
        modelValue: {
            type: Boolean,
            default: false
        },
        /**
         * Whether the switch is disabled
         */
        disabled: {
            type: Boolean,
            default: false
        },
        /**
         * Whether the switch is in loading state
         */
        loading: {
            type: Boolean,
            default: false
        }
    },
    emits: ['update:modelValue', 'change'],
    setup(props, { emit }) {
        const localChecked = computed({
            get: () => props.modelValue,
            set: (value) => emit('update:modelValue', value)
        });

        const handleChange = (event) => {
            if (props.disabled || props.loading) return;

            const isChecked = event.target.checked;
            emit('change', {
                checked: isChecked,
                event
            });
        };

        // Watch for external changes to modelValue
        watch(() => props.modelValue, (newValue) => {
            if (localChecked.value !== newValue) {
                localChecked.value = newValue;
            }
        });

        return {
            localChecked,
            handleChange
        };
    }
};
</script>

<style scoped>
.custom-control {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
}

.custom-control-label {
    font-size: 14px;
    font-weight: 400;
}

.custom-control.disabled {
    cursor: not-allowed;
    opacity: 0.6;
}

.custom-control-input:checked~.custom-control-label::before {
    border-color: #38CA89;
    background-color: #38CA89;
    transition: all 0.2s ease-in-out;
}

.custom-control-input:focus~.custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(56, 202, 137, 0.25);
}

.custom-control-input:disabled~.custom-control-label {
    cursor: not-allowed;
    opacity: 0.6;
}

.switch-description {
    display: block;
    font-size: 0.875rem;
    color: #6c757d;
    margin-top: 0.25rem;
}

.spinner-border {
    width: 1rem;
    height: 1rem;
    border-width: 0.15em;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}

/* Hover effect */
.custom-control:not(.disabled):hover .custom-control-label::before {
    border-color: #2ea06d;
}

/* Active state */
.custom-control-input:active:not(:disabled)~.custom-control-label::before {
    background-color: #2ea06d;
}
</style>
