<template>
    <div class="row" v-if="schoolData">
        <div class="col-lg-3 col-sm-12 school-details-card">
            <div class="card-box-small">
                <div class="card-content">
                    <div class="header-content text-center">
                        <div v-if="schoolData.logo">
                            <img
                                class="mb-20 d-block mx-auto rounded-circle img-thumbnail main-logo"
                                :src="schoolData.logo"
                                alt="school logo"
                                @error="handleSchoolImageError"
                            />
                        </div>

                        <AvatarForInitials :avatarText="getUserInitialsForName(schoolData.name)" iconColor="primary"
                                           class="avatar-md-150 mb-30 m-auto" v-else/>

                        <h4 class="mb-0">{{ schoolData.name }}</h4>
                        <p>{{ schoolData.email }}</p>
                    </div>

                    <div class="schoolDetailsSection">
                        <div class="details-content">
                            <img
                                src="../../../assets/images/icons/mobile_icon.svg"
                                alt="Mobile Icon"
                            />
                            <div class="details">
                                <p class="muted-text-sm">Contact Number</p>
                                <p>{{ schoolData.msisdn }}</p>
                            </div>
                        </div>

                        <div class="details-content">
                            <img
                                src="../../../assets/images/icons/map_icon.svg"
                                alt="Mobile Icon"
                            />
                            <div class="details">
                                <p class="muted-text-sm">Location</p>
                                <p>{{ schoolData.address }}</p>
                            </div>
                        </div>

                        <div class="details-content">
                            <img
                                src="../../../assets/images/icons/calendar_icon.svg"
                                alt="Mobile Icon"
                            />
                            <div class="details">
                                <p class="muted-text-sm">Added On</p>
                                <p>{{ createdAt }}</p>
                            </div>
                        </div>

                        <div class="details-content">
                            <img
                                src="../../../assets/images/icons/clock_icon.svg"
                                alt="Mobile Icon"
                            />
                            <div class="details">
                                <p class="muted-text-sm">Current Plan</p>
                                <p>{{ currentPlan }}</p>
                            </div>
                        </div>

                        <div class="details-content">
                            <img
                                src="../../../assets/images/icons/clock_icon.svg"
                                alt="Mobile Icon"
                            />
                            <div class="details">
                                <p class="muted-text-sm">Next Billing</p>
                                <p>{{ nextBilling }}</p>
                            </div>
                        </div>
                    </div>

                    <div class="studentsSection">
                        <div class="header">
                            <h4>Recently Added Students</h4>
                            <router-link :to="{ name: 'SchoolStudentsList' }">See All</router-link>
                        </div>

                        <div class="studentsList">
                            <template v-for="student in formattedStudentsWithoutSchoolData" :key="student">
                                <div class="name-container">
                                    <div class="avatar-md mr-15">
                                <span
                                    :class="`avatar-title bg-soft-info text-info font-20 rounded-circle`">
                                    {{ student.user.initials }}
                                </span>
                                    </div>

                                    <div>
                                        <p class="m-0 student-name">{{ student.user.name }}</p>
                                        <span class="muted-text-sm">
                                    {{ student.gender }}, {{ student.age }} Yrs
                                </span>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-lg-9 col-sm-12">
            <div class="row">
                <StatCards statCardText="Account Administrators" :statsCardNumber="numOfAdministrators"
                           columnClass="col-sm-12 col-lg-3"/>
                <StatCards statCardText="Total Teachers" :statsCardNumber="numOfTeachers"
                           columnClass="col-sm-12 col-lg-3"/>
                <StatCards statCardText="Total Parents" :statsCardNumber="numOfParents"
                           columnClass="col-sm-12 col-lg-3"/>
                <StatCards statCardText="Total Students" :statsCardNumber="numOfStudents"
                           columnClass="col-sm-12 col-lg-3"/>
            </div>

            <div class="row">
                <div class="col-lg-6 col-sm-12">
                    <div class="card-box">
                        <h4>Students By Gender</h4>

                        <div class="gender-box">
                            <div class="gender-box-wrapper">

                                <div class="avatar-md male-stat">
                                <span :class="`avatar-title rounded-circle`">
                                        {{ Math.round(schoolData.student_by_gender.male.percentage) }}%
                                </span>
                                </div>

                                <div class="avatar-md female-stat">
                                <span :class="`avatar-title rounded-circle`">
                                        {{ Math.round(schoolData.student_by_gender.female.percentage) }}%
                                </span>
                                </div>

                            </div>
                        </div>

                        <div class="gender-list mt-20 mb-20">
                            <div class="d-flex mr-4">
                                <div class="avatar-md gender-list-icon mr-2">
                                    <span class="avatar-title rounded-circle"></span>
                                </div>
                                Male:
                                <span class="semibold">{{ schoolData.student_by_gender.male.count }}</span>
                            </div>

                            <div class="d-flex">
                                <div class="avatar-md gender-list-icon-female mr-2">
                                    <span class="avatar-title rounded-circle"></span>
                                </div>
                                Female:
                                <span class="semibold">{{ schoolData.student_by_gender.female.count }}</span>
                            </div>
                        </div>

                    </div>
                </div>


                <div class="col-lg-6 col-sm-12">
                    <div class="card-box">
                        <h4>Teachers By Gender</h4>

                        <div class="gender-box">
                            <div class="gender-box-wrapper">

                                <div class="avatar-md male-stat">
                                <span :class="`avatar-title rounded-circle`">
                                        {{ Math.round(schoolData.teachers_by_gender.male.percentage) }}%
                                </span>
                                </div>

                                <div class="avatar-md female-stat">
                                <span :class="`avatar-title rounded-circle`">
                                        {{ Math.round(schoolData.teachers_by_gender.female.percentage) }}%
                                </span>
                                </div>

                            </div>
                        </div>

                        <div class="gender-list mt-20 mb-20">
                            <div class="d-flex mr-4">
                                <div class="avatar-md gender-list-icon mr-2">
                                    <span class="avatar-title rounded-circle"></span>
                                </div>
                                Male:
                                <span class="semibold">{{ schoolData.teachers_by_gender.male.count }}</span>
                            </div>

                            <div class="d-flex">
                                <div class="avatar-md gender-list-icon-female mr-2">
                                    <span class="avatar-title rounded-circle"></span>
                                </div>
                                Female:
                                <span class="semibold">{{ schoolData.teachers_by_gender.female.count }}</span>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <div class="card-box">
                        <div class="row">
                            <div class="col-12">
                                <h4 class="bold mb-30">Recent Projects</h4>

                                <TableView :data="formattedProjectsData" :headers="tableHeaders"
                                           :id="getIdForActions" :searchFields="searchFieldsData"
                                           :detailsData="getDataForDetailsView" :loading="projectsLoader"
                                           :viewAction="viewAction" :totalPages="1"
                                           :currentPage.sync="page" :showActionsColumn="true" :itemsPerPage="perPage"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>

import {computed, onMounted, ref, watch} from "vue";
import StatCards from "@/components/common/StatCards.vue";
import SchoolDetail from "@/components/layouts/GodAdmin/Schools/SchoolDetail.vue";
import RightSideMiniPage from "@/components/common/RightSideMiniPage.vue";
import {capitalizeFirstLetter, formatDateTime, getUserInitialsForName, truncateText} from "@/utils/helpers";
import getSchoolStudents from "@/composables/Schools/getSchoolStudents";
import getProjects from "@/composables/Schools/getProjects";
import TableView from "@/components/common/TableView.vue";
import {useRouter} from "vue-router";
import getSchoolDashboardData from "@/composables/Schools/getSchoolDashboardReports";
import AvatarForInitials from "@/components/common/AvatarForInitials.vue";

export default {
    methods: {capitalizeFirstLetter},
    props: {},
    components: {AvatarForInitials, TableView, RightSideMiniPage, SchoolDetail, StatCards},
    setup(props, {emit}) {
        document.title = 'Dashboard'
        const {schoolDashboardData, fetchSchoolDashboard} = getSchoolDashboardData()
        const {fetchStudentsData, formattedStudentsWithoutSchoolData} = getSchoolStudents()
        const {
            fetchProjectsData,
            loading: projectsLoader,
            formattedProjectsData,
            projectsData
        } = getProjects()
        const schoolData = ref(null)
        const numOfAdministrators = ref(0)
        const numOfTeachers = ref(0)
        const numOfParents = ref(0)
        const numOfStudents = ref(0)
        const page = 1
        const perPage = 5
        const router = useRouter()
        const createdAt = ref('')
        const nextBilling = ref('')
        const currentPlan = ref('')

        onMounted(() => {
            fetchSchoolDashboard()
            fetchStudentsData(page, perPage)
            fetchProjectsData(page, perPage)

            emit('show-loader')
        })

        watch(schoolDashboardData, (item) => {
            schoolData.value = item
            numOfAdministrators.value = item.num_of_school_administrators
            numOfTeachers.value = item.num_of_teachers
            numOfParents.value = item.num_of_parents
            numOfStudents.value = item.num_of_students

            const {date, time} = formatDateTime(item.created_at)
            createdAt.value = time

            nextBilling.value = item.next_subscription_plan ? truncateText(item.next_subscription_plan, 16, false) : '-'
            currentPlan.value = item.subcription_plan ? capitalizeFirstLetter(item.subcription_plan) : '-'
            // console.log(schoolData.value)
            emit('data-loaded')
        })


        const tableHeaders = computed(() => {
            return [
                {label: "Name", key: "name"},
                {label: "Description", key: "description"},
                {label: "Added On", key: "date"},
                {label: "Visibility", key: "visibility"},
                {label: "Status", key: "status"}
            ];
        });

        const getIdForActions = computed(() => {
            return projectsData.value.map((item) => item.id);
        });

        const getDataForDetailsView = computed(() => {
            return projectsData.value
        })

        const viewAction = (item, id) => {
            router.push(
                {
                    name: 'ShowProject',
                    params: {id: id}
                })
        }

        const searchFieldsData = ['user.name', 'description', 'status']

        const handleSchoolImageError = () => {
            schoolData.value.logo = null
        }

        return {
            handleSchoolImageError,
            schoolData,
            numOfAdministrators,
            numOfTeachers,
            numOfParents,
            currentPlan,
            numOfStudents,
            formattedStudentsWithoutSchoolData,
            formattedProjectsData,
            projectsLoader,
            projectsData,
            tableHeaders,
            getIdForActions,
            getDataForDetailsView,
            viewAction,
            page,
            perPage,
            searchFieldsData,
            createdAt,
            nextBilling,
            getUserInitialsForName
        }
    }
}
</script>

<style scoped>
.header-content {
    padding: 30px 0 10px 0;
    position: relative;
    border-bottom: 1px solid #e9eaf3;
}

.student-name {
    color: #9a9a9a;
    font-size: 16px;
}

.studentsSection {
    padding: 20px 30px;
}

.studentsSection .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.studentsSection .header h4 {
    font-family: 'Inter SemiBold', 'Arial', sans-serif;
    color: #0D44B2;
}

.studentsSection .header a {
    font-family: 'Inter Medium', sans-serif;
    color: #9A9A9A;
}

.name-container {
    display: flex;
    align-items: center;
    padding: 5px 0;
}

.student-name {
    font-family: "Inter Medium", sans-serif;
    font-size: 16px;
    color: #333333;
}

.card-box-small {
    padding: 0 30px 50px 30px;
    background-color: #fff;
    box-shadow: 0 0.75rem 6rem rgba(56, 65, 74, .03);
    margin-bottom: 24px;
    border-radius: 12px !important;
}

.details-content:first-child {
    padding-top: 20px;
}

.details-content {
    width: 100%;
    display: flex;
    align-items: flex-start;
    padding: 15px 40px 10px 40px;
}

.details-content:last-child {
    border-bottom: 1px solid #e9eaf3;
    padding-bottom: 20px;
}

.details-content img {
    vertical-align: top;
    margin-right: 15px;
    margin-top: 5px;
}

.details-content .details > p {
    margin-bottom: 0;
    font-family: "Inter Medium", sans-serif;
}

.gender-box {
    display: flex;
    padding: 50px;
    justify-content: center;
}

.female-stat {
    height: 180px;
    width: 180px;
    position: relative;
    bottom: 40px;
    right: 20px;
}

.female-stat .avatar-title {
    background-color: #D750B7;
    color: #fff;
    font-size: 30px;
    font-family: "Inter Medium", sans-serif;
}

.male-stat {
    height: 180px;
    width: 180px;
    position: relative;
    top: 20px;
    left: 20px;
}

.male-stat .avatar-title {
    background-color: #0D44B2;
    color: #fff;
    font-size: 30px;
    font-family: "Inter Medium", sans-serif;
}

.gender-box-wrapper {
    display: flex;
}

.gender-list {
    display: flex;
    justify-content: center;
}

.gender-list-icon, .gender-list-icon-female {
    height: 20px;
    width: 20px;
    display: flex;
}

.gender-list-icon .avatar-title {
    background-color: #0D44B2;
    color: #333;
}

.gender-list-icon-female .avatar-title {
    background-color: #D750B7;
    color: #333;
}

@media (max-width: 767px) {
    .school-details-card {
        display: none;
    }
}

.main-logo {
    height: 150px !important;
    width: 150px !important;
    object-fit: contain;
}


</style>