<template>
    <div id="wrapper">
        <MessageSliderCopy :isVisible="displayMessage" :messageText="messageText" :messageType="messageType" />

        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 mx-auto">
                <div class="login-container d-flex align-items-center justify-content-center">
                    <div class="row auth-card-box">
                        <InfiniteLinearLoader :loading="loading" />

                        <div class="image col-lg-6 col-md-6 col-sm-12 p-0">
                            <img src="../../assets/images/login_logo_new.jpeg" alt="login image" />
                        </div>

                        <div class="form-content col-lg-6 col-md-6 col-sm-12">
                            <img src="../../assets/key-logo.png" alt="logo" />
                            <p>Set your password</p>

                            <div class="row">
                                <div class="col-12 mb-20">
                                    <TextInputField :config="{
                                        class: 'form-control-gray',
                                        placeholder: 'Enter OTP',
                                        inputFieldType: 'text'
                                    }" labelText="Enter OTP" :errorMessage="otpError" v-model="otp" />
                                </div>

                                <div class="col-12 mb-20">
                                    <TextInputField :config="{
                                        class: 'form-control-gray',
                                        placeholder: '********',
                                        inputFieldType: 'password'
                                    }" labelText="Enter Password" :errorMessage="passwordError" v-model="password" />
                                </div>

                                <div class="col-12 mb-20">
                                    <TextInputField :config="{
                                        class: 'form-control-gray ',
                                        placeholder: '********',
                                        inputFieldType: 'password'
                                    }" labelText="Confirm Password" :errorMessage="passwordConfirmError"
                                        v-model="passwordConfirm" />
                                </div>
                            </div>

                            <RegularButtonVue buttonText="Set New Password" class="custom-button mb-3"
                                :loading="loading" @click="handleFormSubmit" />

                            <p class="forgot-text">
                                <a href="#" @click.prevent="navigateToLogin">Remember Password? Go to Login</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TextInputField from "@/components/common/TextInputFieldNew.vue";
import PasswordInputFieldVue from "@/components/common/PasswordInputField.vue";
import CheckBoxVue from "@/components/common/CheckBox.vue";
import RegularButtonVue from "@/components/common/RegularButton.vue";
import InfiniteLinearLoader from "@/components/common/InfiniteLinearLoader.vue";
import MessageSliderCopy from "@/components/common/MessageSliderCopy.vue";
import { ref } from "vue";
import { API_BASE_URL } from "@/config.js";
import { useRoute, useRouter } from "vue-router";
import { useToast } from '@/composables/useToast'

export default {
    components: {
        TextInputField,
        PasswordInputFieldVue,
        CheckBoxVue,
        RegularButtonVue,
        InfiniteLinearLoader,
        MessageSliderCopy,
    },
    setup() {
        const password = ref("");
        const passwordConfirm = ref("");
        const passwordError = ref("");
        const otpError = ref("");
        const otp = ref("");
        const passwordConfirmError = ref("");
        const loading = ref(false);
        const loadingSpinner = ref(false);
        const router = useRoute();
        const navigationRouter = useRouter();
        const email = router.query.email;
        const toast = useToast();
        const displayMessage = ref(false);
        const messageText = ref("");
        const messageType = ref("");

        const showMessage = (text, type) => {
            toast.success(text);
        };

        const showLoaders = () => {
            loading.value = true;
            loadingSpinner.value = true;
        };

        const hideLoaders = () => {
            loading.value = false;
            loadingSpinner.value = false;
        };

        const validateform = () => {
            let isValid = true

            otpError.value = ''
            passwordError.value = ''
            passwordConfirmError.value = ''

            if (otp.value.length === 0) {
                otpError.value = 'Enter the OTP from your email inbox'
                isValid = false
            }

            if (password.value.length === 0) {
                passwordError.value = 'Enter a password'
                isValid = false
            }

            if (password.value !== passwordConfirm.value) {
                passwordConfirmError.value = 'Passwords do not match'
                isValid = false
            }

            return isValid
        }

        const handleFormSubmit = async () => {
            if (validateform()) {
                try {
                    showLoaders();
                    const data = {
                        otp: otp.value,
                        email: email,
                        password: password.value,
                    }

                    const response = await fetch(`${API_BASE_URL}/auth/update-password`, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(data),
                    });

                    if (response.ok) {
                        const result = await response.json();
                        if (result.code === 200) {
                            toast.success("Password reset successful, you can now login");
                            setTimeout(() => {
                                navigationRouter.push({ name: 'Login' });
                            }, 1500);
                        } else {
                            console.log('code error => ', result)
                            toast.error(result.message || 'An error occurred');
                        }
                    } else {
                        const result = await response.json();
                        console.log('ok error => ', result.message)
                        toast.error('An error occurred');
                    }
                } catch (error) {
                    console.error("An error occurred:", error);
                    toast.error("An error occurred while processing your request.");
                } finally {
                    hideLoaders();
                }
            }
        };

        const navigateToLogin = () => {
            navigationRouter.push({ name: 'Login' })
        }

        return {
            password,
            handleFormSubmit,
            loading,
            showMessage,
            displayMessage,
            messageText,
            messageType,
            passwordConfirm,
            passwordError,
            passwordConfirmError,
            otpError,
            otp,
            navigateToLogin,
        };
    },
};
</script>

<style scoped>
@media (min-width: 1200px) {
    .auth-card-box {
        height: 550px;
        box-shadow: 0 0 15px 0 rgba(184, 188, 202, 0.5);
        position: relative;
    }

    .login-container {
        height: 100vh
    }
}

@media (min-width: 768px) and (max-width: 1199px) {
    .auth-card-box {
        height: 550px;
        box-shadow: 0 0 15px 0 rgba(184, 188, 202, 0.5);
        position: relative;
    }

    .login-container {
        height: 100vh
    }
}

.image {
    height: 100%;
}

.image>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.form-content {
    height: 100%;
    width: 60%;
    display: inline-block;
    position: inherit;
    top: 0;
    padding: 50px 45px;
}

.form-content>img {
    height: 50px;
    width: 80px;
    margin-bottom: 10px;
}

.form-content>p {
    margin-bottom: 30px;
}

.form-content>.forgot-text {
    font-size: 14px;
    color: #333;
}

.form-content>.forgot-text span {
    color: #f75549;
}

.checkboxVue {
    margin-bottom: 20px;
}
</style>