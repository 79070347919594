<template>
    <form>
        <div v-if="currentPage === 1">
            <SingleSelectFieldNew labelText="Select School" :options="schoolSelectOptions" v-model="selectedSchool"
                :loading="schoolDropdownLoader" selectId="schoolOptions" :isRequired="true" />
        </div>

        <div v-if="currentPage === 2">
            <div class="row mb-30">
                <div class="col-lg-6">
                    <TextInputField :config="{
                        class: 'form-control-gray',
                        placeholder: 'Enter First Name',
                        inputFieldType: 'text'
                    }" labelText="First Name" isRequired v-model="firstName" :errorMessage="firstNameError" />
                </div>

                <div class="col-lg-6">
                    <TextInputField :config="{
                        class: 'form-control-gray',
                        placeholder: 'Enter Last Name',
                        inputFieldType: 'text'
                    }" labelText="Last Name" isRequired v-model="lastName" :errorMessage="lastNameError" />
                </div>
            </div>

            <div class="row mb-30">
                <div class="col-lg-6">
                    <SingleSelectFieldNew labelText="Gender" :options="genderSelectOptions" v-model="selectedGender"
                        :isRequired="true" :errorMessage="genderError" selectId="genderOptions"
                        :loading="genderLoader" />
                </div>

                <div class="col-lg-6">
                    <TextInputField :config="{
                        class: 'form-control-gray',
                        placeholder: 'Enter Contact Number',
                        inputFieldType: 'tel'
                    }" labelText="Contact Number" isRequired v-model="msisdn" :errorMessage="msisdnError" />
                </div>
            </div>

            <div class="row mb-30">
                <div class="col-lg-6 col-sm-12">
                    <TextInputField :config="{
                        class: 'form-control-gray',
                        placeholder: 'Enter Email',
                        inputFieldType: 'email'
                    }" labelText="Email" isRequired v-model="email" :errorMessage="emailError" />
                </div>

                <div class="col-lg-6 col-sm-12">
                    <SingleSelectFieldNew labelText="Bachelor's degree in an education related field?"
                        :options="bachelorDegreeOptions" v-model="selectedBachelorDegreeOption"
                        selectId="bachelorDegreeOptions" />
                </div>
            </div>

            <div class="row mb-30">
                <div class="col-lg-6">
                    <SingleSelectFieldNew labelText="Country of Residence" :options="countrySelectOptions"
                        v-model="selectedCountry" :loading="countryDropdownLoader" selectId="countryOptions"
                        :isRequired="true" @change="handleCountrySelection" />
                </div>

                <div class="col-lg-6">
                    <SingleSelectFieldNew labelText="State of Residence" :options="stateSelectOptions"
                        v-model="selectedState" :loading="statesDropdownLoader" :isRequired="true"
                        selectId="stateOptions" />
                </div>
            </div>

            <div class="row mb-50">
                <div class="col-12">
                    <TextAreaInputField :config="{
                        class: 'form-control-gray',
                        placeholder: 'Enter Address',
                        inputFieldType: 'address'
                    }" labelText="Residential Address" v-model="address" />
                </div>
            </div>

        </div>

        <div v-if="currentPage === 3">
            <div class="row mb-30">
                <div class="col-lg-6 col-sm-12">
                    <TextInputField :config="{
                        class: 'form-control-gray',
                        placeholder: '',
                        inputFieldType: 'years_of_experience',
                    }" labelText="How many years experience do you have teaching early years / preschool aged children?"
                        v-model="years_of_experience" />
                </div>

                <div class="col-lg-6 col-sm-12">
                    <SingleSelectFieldNew
                        labelText="Are you familiar with Early Years Education / EYFS / Pre-school education?"
                        :options="earlyYearsEducationOptions" v-model="selectedEarlyYearEducation" :isRequired="true"
                        :errorMessage="earlyYearError" selectId="earlyYearsEducationOptions" />

                </div>
            </div>

            <div class="row mb-30">
                <div class="col-lg-6 col-sm-12">
                    <TextInputField :config="{
                        class: 'form-control-gray',
                        placeholder: 'Enter LinkedIn Link',
                        inputFieldType: 'text'
                    }" labelText="LinkedIn URL" v-model="linkedin" />
                </div>

                <div class="col-lg-6 col-sm-12">
                    <SingleSelectFieldNew labelText="How did you hear about the KEY app?" :isRequired="true"
                        :errorMessage="hearAboutUsError" :options="heardAboutUsOptions"
                        v-model="selectedHeardAboutUsEducation" selectId="heardAboutUsOptions" />

                </div>
            </div>

            <div class="row mb-50">
                <div class="col-12">
                    <TextAreaInputField :config="{
                        class: 'form-control-gray',
                        placeholder: 'Enter purpose',
                        inputFieldType: 'text'
                    }" labelText="What is your purpose / objective for using the KEY app?" isRequired
                        :errorMessage="purposeError" v-model="purpose_using_the_app" />
                </div>
            </div>
        </div>


        <div class="mt-20 table-pagination">
            <ul class="pagination pagination-rounded">
                <li v-for="page in pages" :key="page" :class="{ active: currentPage === page }" class="page-item">
                    <a href="#" class="page-link" @click="changePage(page)">{{ page }}</a>
                </li>
            </ul>

            <RoundedButton :buttonText="currentPage < pages ? 'Next' : 'Submit'" class="custom-button" @click="nextPage"
                :loading="buttonLoading" />

        </div>

    </form>
</template>
<script>
import TextInputField from "@/components/common/TextInputField.vue";
import TextAreaInputField from "@/components/common/TextAreaInputField.vue";
import RoundedButton from "@/components/common/RoundedButton.vue";
import getSchools from "@/composables/getSchools";
import { onMounted, ref, watch } from "vue";
import getCountries from "@/composables/getCountries";
import getStates from "@/composables/getStates";
import { capitalizeFirstLetter, getValidToken } from '@/utils/helpers'
import getGender from "@/composables/getGender";
import { checkAndRefreshToken } from "@/utils/auth";
import { API_BASE_URL } from "@/config";
import SingleSelectFieldNew from "@/components/common/SingleSelectFieldNew.vue";
import { useToast } from "@/composables/useToast";


export default {
    components: {
        SingleSelectFieldNew,
        TextInputField,
        TextAreaInputField,
        RoundedButton,
    },
    setup(props, { emit }) {
        const { success, error: showError } = useToast();
        const buttonLoading = ref(false)
        const {
            fetchSchoolsData, loading: schoolDropdownLoader, schoolsData
        } = getSchools()
        const { fetchCountriesData, countriesList, loading: countryDropdownLoader } = getCountries()
        const { fetchStatesData, statesList, loading: statesDropdownLoader } = getStates()
        const { fetchGender, genderList, loading: genderLoader } = getGender()
        const currentPage = ref(1);
        const schoolSelectOptions = ref([])
        const genderSelectOptions = ref([])
        const countrySelectOptions = ref([])
        const stateSelectOptions = ref([])
        const selectedSchool = ref('')
        const selectedGender = ref('')
        const selectedCountry = ref('')
        const selectedState = ref('')
        const pages = 3
        const firstName = ref('')
        const firstNameError = ref('')
        const lastNameError = ref('')
        const genderError = ref('')
        const msisdnError = ref('')
        const emailError = ref('')
        const bachelorFieldError = ref('')
        const countryError = ref('')
        const statesError = ref('')
        const lastName = ref('')
        const msisdn = ref('')
        const email = ref('')
        const address = ref('')
        const purpose_using_the_app = ref('')
        const countryIdMap = ref({});
        const selectedEarlyYearEducation = ref('')
        const selectedHeardAboutUsEducation = ref('')
        const earlyYearsEducationOptions = ref([])
        const heardAboutUsOptions = ref([])
        const linkedin = ref('')
        const bachelorDegreeOptions = ref([])
        const years_of_experience = ref('')
        const selectedBachelorDegreeOption = ref('')
        const earlyYearError = ref('')
        const hearAboutUsError = ref('')
        const purposeError = ref('')

        // Flag to prevent recursive updates
        const earlyYearsEducationUpdated = ref(false);
        const heardAboutUsUpdated = ref(false);

        onMounted(() => {
            fetchSchoolsData(1, 200)
        })

        // Helper function to normalize yes/no values
        const normalizeYesNoValue = (value) => {
            if (value === true || value === 'true' || value === 'Yes' || value === 'yes' || value === 'YES') {
                return 'yes';
            }
            return 'no';
        };

        // Early Years Education
        const fetchEarlyYearsEducationOptions = () => {
            setTimeout(() => {
                earlyYearsEducationOptions.value = [
                    { label: "Yes", value: 'yes' },
                    { label: "No", value: 'no' },
                ]
            }, 200)
        }

        watch(earlyYearsEducationOptions, (newData) => {
            if (!earlyYearsEducationUpdated.value) {
                selectedEarlyYearEducation.value = newData.length > 0 ? newData[0].value : '';
            }
            earlyYearsEducationUpdated.value = false; // Reset flag after watch
        });


        // Bachelors Degree
        const fetchBachelorDegreeOptions = () => {
            setTimeout(() => {
                bachelorDegreeOptions.value = [
                    { label: "Yes", value: 'yes' },
                    { label: "No", value: 'no' },
                ]
            }, 200)
        }


        // Heard about us
        const fetchHeardAboutUsOptions = () => {
            setTimeout(() => {
                heardAboutUsOptions.value = [
                    { label: "Google Search", value: 'Google Search' },
                    { label: "Website/Blog", value: 'Website/Blog' },
                    { label: "Email Newsletter", value: 'Email Newsletter' },
                    { label: "Advertisement", value: 'Advertisement' },
                    { label: "Instagram", value: 'Instagram' },
                    { label: "X", value: 'X' },
                    { label: "Tiktok", value: 'Tiktok' },
                    { label: "Referral", value: 'Referral' },
                    { label: "Facebook", value: 'Facebook' },
                    { label: "Event", value: 'Event' },
                ]
            })
        }

        watch(heardAboutUsOptions, (newData) => {
            if (!heardAboutUsUpdated.value) {
                selectedHeardAboutUsEducation.value = newData.length > 0 ? newData[0].value : '';
            }
            heardAboutUsUpdated.value = false; // Reset flag after watch
        });


        // Gender
        const updateGenderOptions = (newData) => {
            genderSelectOptions.value = newData.map(item => ({
                label: capitalizeFirstLetter(item.label), value: item.value
            }))

            if (genderSelectOptions.value.length > 0) {
                selectedGender.value = genderSelectOptions.value[0].value;
            }
        }

        watch(genderList, (newData) => {
            updateGenderOptions(newData)
        })

        const updateSchoolOptions = (newData) => {
            schoolSelectOptions.value = newData.map(item => ({
                label: capitalizeFirstLetter(item.name), value: item.id
            }))

            if (schoolSelectOptions.value.length > 0) {
                selectedSchool.value = schoolSelectOptions.value[0].value;
            }
        }

        watch(schoolsData, (newData) => {
            updateSchoolOptions(newData)
        })

        const changePage = (page) => {
            if (page === 1) {
                currentPage.value = page;
                fetchSchoolsData(1, 200);
            } else if (page === 2) {
                currentPage.value = page;
                fetchGender();
                fetchCountriesData();
                fetchBachelorDegreeOptions();
                if (currentPage.value === 1) {
                    validatePageTwo();
                }
            } else if (page === 3 && currentPage.value === 2 && validatePageTwo()) {
                currentPage.value = page;
            }
        }


        // COUNTRIES
        const handleCountrySelection = () => {
            const selectedCountryId = countryIdMap.value[selectedCountry.value]
            fetchStatesData(selectedCountryId)
        }

        // Update the country dropdown when the modal is opened
        const updateCountryOptions = (newData) => {
            countrySelectOptions.value = newData.map(country => ({
                label: country.country_name, value: country.country_name
            }))

            countryIdMap.value = newData.reduce((map, country) => {
                map[country.country_name] = country.id;
                return map;
            }, {});

            if (countrySelectOptions.value.length > 0) {
                selectedCountry.value = 'Nigeria'
                handleCountrySelection()
            }
        }

        watch(countriesList, (newData) => {
            updateCountryOptions(newData)
        })

        // STATES
        // Update the states options
        const updateStatesOptions = (newData) => {
            stateSelectOptions.value = newData.map(state => ({
                label: state.state_name, value: state.state_name
            }))

            if (stateSelectOptions.value.length > 0) {
                selectedState.value = stateSelectOptions.value[0].value;
            }
        }

        watch(statesList, (newData) => {
            updateStatesOptions(newData)
        })

        // OTHERS
        const showLoaders = () => {
            buttonLoading.value = true;
        };

        const hideLoaders = () => {
            buttonLoading.value = false;
        };

        const validatePageTwo = () => {
            let isValid = true

            firstNameError.value = ''
            lastNameError.value = ''
            emailError.value = ''
            genderError.value = ''
            msisdnError.value = ''
            bachelorFieldError.value = ''
            countryError.value = ''
            statesError.value = ''

            if (firstName.value.length === 0) {
                firstNameError.value = 'Enter a first name'
                isValid = false
            }

            if (lastName.value.length === 0) {
                lastNameError.value = 'Enter a last name'
                isValid = false
            }

            if (email.value.length === 0) {
                emailError.value = 'Enter an email address'
                isValid = false
            }

            if (msisdn.value.length === 0) {
                msisdnError.value = 'Enter a phone number'
                isValid = false
            }

            if (selectedGender.value === null) {
                genderError.value = 'Select at least one option'
                isValid = false
            }

            if (selectedBachelorDegreeOption.value === null) {
                console.log('got here')
                bachelorFieldError.value = 'Select at least one option'
                isValid = false
            }

            if (selectedCountry.value === null) {
                countryError.value = 'Select at least one option'
                isValid = false
            }

            if (selectedState.value === null) {
                statesError.value = 'Select at least one option'
                isValid = false
            }

            return isValid
        }

        const validatePageThree = () => {
            let isValid = true

            earlyYearError.value = ''
            hearAboutUsError.value = ''
            purposeError.value = ''

            if (selectedEarlyYearEducation.value === null) {
                earlyYearError.value = 'Select at least one option'
                isValid = false
            }

            if (selectedHeardAboutUsEducation.value === null) {
                hearAboutUsError.value = 'Select at least one option'
                isValid = false
            }

            if (purpose_using_the_app.value.length === 0) {
                purposeError.value = 'Enter a purpose'
                isValid = false
            }

            return isValid
        }

        const nextPage = () => {

            if (currentPage.value === 1) {
                currentPage.value++;
                fetchGender()
                fetchCountriesData()
                fetchBachelorDegreeOptions()
            } else if (currentPage.value === 2 && validatePageTwo()) {
                currentPage.value++;
                fetchEarlyYearsEducationOptions()
                fetchHeardAboutUsOptions()
            } else if (currentPage.value === 3 && validatePageThree()) {
                saveNewItem()
            }
        };

        const apiUrl = `${API_BASE_URL}/teacher/add-teacher`
        const saveNewItem = async () => {
            const newItem = {
                school_id: selectedSchool.value,
                first_name: firstName.value,
                last_name: lastName.value,
                gender: selectedGender.value,
                email: email.value,
                msisdn: msisdn.value,
                country: selectedCountry.value,
                state: selectedState.value,
                address: address.value,
                purpose_using_the_app: purpose_using_the_app.value,
                linkedin: linkedin.value,
                years_of_experience: years_of_experience.value,
                early_years_education: selectedEarlyYearEducation.value === 'yes' ? 'true' : 'false',
                how_you_heard_about_us: selectedHeardAboutUsEducation.value,
                has_bachelors_degree: selectedBachelorDegreeOption.value === 'yes' ? 'true' : 'false',
                token: 'keyhub_invite'
            }

            // console.log('newItem', newItem)

            try {
                showLoaders()
                await checkAndRefreshToken()
                const accessToken = getValidToken()

                const response = await fetch(apiUrl, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`,
                    },
                    body: JSON.stringify(newItem)
                })


                if (response.ok) {
                    const result = await response.json();
                    console.log("API Response:", result);

                    if (result.code === 200) {
                        const itemFullName = capitalizeFirstLetter(`${newItem.first_name} ${newItem.last_name}`)
                        emit("closeFormModal", itemFullName);
                        success(`Co-Learner ${itemFullName} created successfully`);
                    } else {
                        console.log("error", result.message);
                        showError(result.message || "Error creating co-learner");
                    }
                } else {
                    const result = await response.json();
                    console.log("error", result.message);
                    showError(result.message || "Error creating co-learner");
                }
            } catch (error) {
                console.error("An error occured:", error);
                showError("An unexpected error occurred");
            } finally {
                hideLoaders();
            }
        }

        return {
            buttonLoading,
            saveNewItem,
            currentPage,
            schoolSelectOptions,
            selectedSchool,
            schoolDropdownLoader,
            nextPage,
            pages,
            changePage,
            firstName,
            lastName,
            genderSelectOptions,
            selectedGender,
            msisdn,
            email,
            countrySelectOptions,
            selectedCountry,
            countryDropdownLoader,
            statesDropdownLoader,
            handleCountrySelection,
            selectedState,
            stateSelectOptions,
            address,
            genderLoader,
            selectedEarlyYearEducation,
            selectedHeardAboutUsEducation,
            earlyYearsEducationOptions,
            heardAboutUsOptions,
            purpose_using_the_app,
            linkedin,
            bachelorDegreeOptions,
            selectedBachelorDegreeOption,
            years_of_experience,
            firstNameError,
            lastNameError,
            genderError,
            msisdnError,
            emailError,
            bachelorFieldError,
            countryError,
            statesError,
            purposeError,
            hearAboutUsError,
            earlyYearError,
            normalizeYesNoValue
        }
    }
}
</script>
<style scoped>
.table-pagination {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.page-item.active .page-link {
    border-color: #2f5bcf;
    z-index: 3;
    color: #fff;
}

.page-item:first-child .page-link {
    margin-left: 0;
}

.pagination-rounded .page-link {
    border-radius: 30px !important;
    margin: 0 3px;
    border: none;
}

.page-link {
    position: relative;
    display: block;
    padding: 0.5rem 0.9rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #323a46;
    background-color: #fff;
    border: 1px solid #dee2e6;
}
</style>