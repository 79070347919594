<template>
    <Transition name="slide-fade">
        <div v-if="show && isValidMessage" :class="['toast-notification', type]">
            <div class="toast-content">
                <span class="toast-icon">
                    <i v-if="type === 'success'" class="fa fa-check-circle"></i>
                    <i v-else-if="type === 'error'" class="fa fa-exclamation-circle"></i>
                    <i v-else-if="type === 'warning'" class="fa fa-exclamation-triangle"></i>
                    <i v-else class="fa fa-info-circle"></i>
                </span>
                <span class="toast-message">{{ message }}</span>
            </div>
        </div>
    </Transition>
</template>

<script>
export default {
    name: 'ToastNotification',
    props: {
        message: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: 'info',
            validator: function (value) {
                return ['success', 'error', 'warning', 'info'].indexOf(value) !== -1
            }
        },
        duration: {
            type: Number,
            default: 3000
        },
        show: {
            type: Boolean,
            default: false
        }
    },
    emits: ['close'],
    computed: {
        isValidMessage() {
            return this.message && typeof this.message === 'string' && this.message.length > 0;
        }
    },
    mounted() {
        // Ensure toast is not shown on initial mount if message is invalid
        if (this.show && !this.isValidMessage) {
            this.$emit('close');
        }
    },
    watch: {
        show(newVal) {
            if (newVal && this.duration > 0 && this.isValidMessage) {
                setTimeout(() => {
                    this.$emit('close');
                }, this.duration);
            }
        }
    }
}
</script>

<style scoped>
.toast-notification {
    position: fixed;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    max-width: 500px;
    background: white;
    border-radius: 50px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    z-index: 9999;
    padding: 2px;
}

.toast-content {
    display: flex;
    align-items: center;
    padding: 8px 4px 8px 12px;
    gap: 15px;
}

.toast-icon {
    flex-shrink: 0;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.toast-message {
    flex-grow: 1;
    font-size: 14px;
    color: #333333;
    padding-right: 8px;
    font-weight: 500;
}

/* Toast types */
.success {
    background-color: white;
    border: 1px solid #4CAF50;
}

.success .toast-icon {
    color: #4CAF50;
}

.error {
    background-color: white;
    border: 1px solid #f75549;
}

.error .toast-icon {
    color: #f75549;
}

.warning {
    background-color: white;
    border: 1px solid #FFC107;
}

.warning .toast-icon {
    color: #FFC107;
}

.info {
    background-color: white;
    border: 1px solid #2196F3;
}

.info .toast-icon {
    color: #2196F3;
}

/* Animation */
.slide-fade-enter-active {
    transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
    transition: all 0.3s ease-in;
}

.slide-fade-enter-from {
    transform: translate(-50%, 100%);
    opacity: 0;
}

.slide-fade-leave-to {
    transform: translate(-50%, 100%);
    opacity: 0;
}
</style>