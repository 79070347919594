<template>
    <div class="form-group" :class="[fieldClass, errorMessage ? 'error' : '']">
        <div class="custom-select-wrapper">
            <label :for="selectId" class="label" v-if="labelText">
                <div>
                    {{ labelText }}
                    <span class="required" v-if="isRequired">*</span>
                </div>
                <span class="label-helper" v-if="labelHelper" @click="labelHelperFunction">{{ labelHelper }}</span>
            </label>
            <div :class="{ 'multiple-select-input-wrapper': isMultiple }">
                <select :id="selectId" ref="select" :class="[selectClass, 'js-example-basic-single']"
                    :multiple="isMultiple" :disabled="isDisabled" :required="isRequired" :aria-label="labelText"
                    :aria-invalid="!!errorMessage" :aria-describedby="errorMessage ? `${selectId}-error` : undefined">
                    <option value="">Select an option</option>
                    <option v-for="(option, index) in options" :key="index" :value="option.value">
                        {{ option.label }}
                    </option>
                </select>
            </div>
            <div v-if="loading" class="linear-loader" role="progressbar">
                <div class="train"></div>
            </div>
            <div v-if="errorMessage" class="invalid-feedback" :id="`${selectId}-error`" role="alert">
                {{ errorMessage }}
            </div>
        </div>
    </div>
</template>

<script>
// Try to import jQuery and Select2
import $ from 'jquery';
import 'select2';
import 'select2/dist/css/select2.min.css';

// Make jQuery available globally
window.$ = window.jQuery = $;

export default {
    name: 'BaseSelect',
    props: {
        labelText: String,
        options: {
            type: Array,
            required: true,
            default: () => []
        },
        selectId: {
            type: String,
            default() {
                return `select-${Math.random().toString(36).substr(2, 9)}`;
            }
        },
        modelValue: {
            type: [Array, String, Number, null],
            default: null
        },
        class: String,
        loading: Boolean,
        isDisabled: Boolean,
        isRequired: Boolean,
        isMultiple: Boolean,
        maximumSelectionLength: {
            type: Number,
            default: 100
        },
        errorMessage: String,
        labelHelper: String,
        labelHelperFunction: Function
    },
    emits: ['update:modelValue', 'change'],
    data() {
        return {
            select2Instance: null
        };
    },
    computed: {
        fieldClass() {
            return this.class || '';
        },
        selectClass() {
            return this.selectId;
        }
    },
    methods: {
        initSelect2() {
            // Make sure jQuery and Select2 are available
            if (typeof $ === 'undefined' || typeof $.fn.select2 === 'undefined') {
                console.warn('jQuery or Select2 is not loaded');
                return;
            }

            const select = $(`#${this.selectId}`);
            if (!select.length) return;

            try {
                // Configure Select2
                const select2Config = {
                    placeholder: 'Select an option',
                    allowClear: true,
                    width: '100%',
                    dropdownCssClass: 'select2-dropdown-custom',
                    selectionCssClass: 'select2-selection-custom'
                };

                // Add maximum selection length for multiple select
                if (this.isMultiple) {
                    select2Config.maximumSelectionLength = this.maximumSelectionLength;
                }

                // Initialize Select2
                select.select2(select2Config);
                this.select2Instance = select;

                // Set initial value
                if (this.modelValue !== null) {
                    select.val(this.modelValue).trigger('change');
                }

                // Handle value changes from Select2
                const vm = this;
                select.on('change', function () {
                    const val = $(this).val();
                    vm.$emit('update:modelValue', val);
                    vm.$emit('change', val);
                });

                // Fix clear button behavior without affecting dropdown
                select.on('select2:unselecting', function (e) {
                    $(this).data('unselecting', true);
                }).on('select2:opening', function (e) {
                    if ($(this).data('unselecting')) {
                        $(this).removeData('unselecting');
                        e.preventDefault();
                    }
                });

                // Apply consistent styling and add global CSS fix
                this.applyConsistentStyling();

                // Listen for select2:open event to reapply styles
                select.on('select2:open', () => {
                    this.applyConsistentStyling();
                });
            } catch (error) {
                console.error('Error initializing Select2:', error);
            }
        },
        applyConsistentStyling() {
            // Add global styling fixes to document if not already added
            if (!document.getElementById('select2-global-fixes')) {
                const styleEl = document.createElement('style');
                styleEl.id = 'select2-global-fixes';
                styleEl.innerHTML = `
                    .select2-container--default .select2-selection--single {
                        padding-right: 35px !important;
                    }
                    .select2-container--default .select2-selection--single .select2-selection__arrow {
                        position: absolute !important;
                        right: 1px !important;
                        top: 0 !important;
                        height: 100% !important;
                        width: 25px !important;
                    }
                    .select2-container--default .select2-selection--single .select2-selection__clear {
                        position: absolute !important;
                        right: 25px !important;
                        top: 50% !important;
                        transform: translateY(-50%) !important;
                        margin: 0 !important;
                        height: auto !important;
                        z-index: 10 !important;
                    }
                    .select2-container--default .select2-selection--single .select2-selection__rendered {
                        padding-right: 45px !important;
                        text-overflow: ellipsis !important;
                        white-space: nowrap !important;
                        overflow: hidden !important;
                    }
                `;
                document.head.appendChild(styleEl);
            }

            // We still apply some specific styles to each instance
            setTimeout(() => {
                try {
                    // Basic alignment fix for all elements
                    $('.select2-selection--single').css({
                        'height': '38px',
                        'display': 'flex',
                        'align-items': 'center'
                    });

                    if (this.isMultiple) {
                        $('.select2-selection--multiple').css({
                            'min-height': '38px',
                            'display': 'flex',
                            'align-items': 'center',
                            'padding': '4px 8px'
                        });
                    }
                } catch (error) {
                    console.error('Error applying consistent styling:', error);
                }
            }, 50); // Increased timeout for more reliable application
        },
        updateOptions() {
            if (!this.select2Instance) return;

            // Reset Select2
            this.select2Instance.empty();

            // Add placeholder option
            const placeholderOption = new Option('Select an option', '', true, false);
            this.select2Instance.append(placeholderOption);

            // Add all options
            this.options.forEach(option => {
                const isSelected = this.isMultiple
                    ? Array.isArray(this.modelValue) && this.modelValue.includes(option.value)
                    : this.modelValue === option.value;

                const optionElement = new Option(option.label, option.value, false, isSelected);
                this.select2Instance.append(optionElement);
            });

            this.select2Instance.trigger('change');
        },
        destroySelect2() {
            if (this.select2Instance) {
                try {
                    this.select2Instance.select2('destroy');
                    this.select2Instance = null;
                } catch (error) {
                    console.error('Error destroying Select2:', error);
                }
            }
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.initSelect2();
        });
    },
    beforeUnmount() {
        this.destroySelect2();
    },
    watch: {
        options: {
            handler() {
                this.$nextTick(() => {
                    if (this.select2Instance) {
                        this.updateOptions();
                    } else {
                        this.initSelect2();
                    }
                });
            },
            deep: true
        },
        modelValue: {
            handler(newVal) {
                if (this.select2Instance && newVal !== this.select2Instance.val()) {
                    this.select2Instance.val(newVal).trigger('change');
                }
            }
        }
    }
}
</script>

<style scoped>
.label {
    display: flex;
    justify-content: space-between;
    font-size: 12px !important;
    font-family: "Graphik Medium", sans-serif !important;
    margin-bottom: 8px !important;
}

.custom-select-wrapper {
    position: relative;
    display: block;
    width: 100%;
}

.multiple-select-input-wrapper {
    position: relative;
    display: block;
    width: 100%;
}

.invalid-feedback {
    display: block;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 0.75rem;
    color: #f1556c;
}

.linear-loader {
    height: 2px;
    background-color: #b8bcca;
    width: 100%;
    position: relative;
    top: -2.5px;
    left: 0;
    right: 0;
    overflow: hidden;
}

.train {
    width: 900px;
    height: 2px;
    background-color: #2f5bcf;
    position: absolute;
    animation: moveBackAndForth 1s linear infinite;
    top: 0;
    left: 0;
}

@keyframes moveBackAndForth {
    0% {
        left: 0;
    }

    50% {
        left: 100%;
    }

    100% {
        left: 0;
    }
}

.required {
    color: red;
    margin-left: 2px;
    font-weight: bolder;
}

.label-helper {
    font-size: var(--font-size-body, 12px);
    color: var(--v-primary-accent, blue);
    cursor: pointer;
}

/* Ensure the Select2 container inherits width properly */
:deep(.select2-container) {
    width: 100% !important;
}

/* Adjust Select2 height and alignment */
:deep(.select2-container--default .select2-selection--single) {
    border: 1px solid var(--v-primary-text-light-neutral, #ccc);
    border-radius: 4px;
    height: 38px !important;
    /* Match standard Bootstrap form-control height */
    display: flex;
    align-items: center;
    padding: 0 8px;
    padding-right: 35px !important;
    /* Make room for both icons */
}

:deep(.select2-container--default .select2-selection--multiple) {
    border: 1px solid var(--v-primary-text-light-neutral, #ccc);
    border-radius: 4px;
    min-height: 38px !important;
    /* Match standard Bootstrap form-control height */
    padding: 4px 8px;
    display: flex;
    align-items: center;
}

:deep(.select2-container--default .select2-selection--single .select2-selection__rendered) {
    padding-left: 0;
    padding-right: 45px !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    color: #333;
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    justify-content: flex-start;
}

:deep(.select2-container--default .select2-selection--single .select2-selection__arrow) {
    position: absolute !important;
    right: 1px !important;
    top: 0 !important;
    height: 100% !important;
    width: 25px !important;
}

/* Make dropdown arrow centered */
:deep(.select2-container--default .select2-selection--single .select2-selection__arrow b) {
    margin-top: 1;
    margin-left: -4px;
}

/* Focus styles to match text inputs */
:deep(.select2-container--default.select2-container--focus .select2-selection--single),
:deep(.select2-container--default.select2-container--focus .select2-selection--multiple) {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

/* Error state */
:deep(.error .select2-container--default .select2-selection--single),
:deep(.error .select2-container--default .select2-selection--multiple) {
    border-color: #f1556c !important;
}

/* Placeholder styles */
:deep(.select2-container--default .select2-selection--single .select2-selection__placeholder) {
    color: #757575;
}

/* Multiple select choices alignment */
:deep(.select2-container--default .select2-selection--multiple .select2-selection__rendered) {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 0;
    width: 100%;
}

:deep(.select2-container--default .select2-selection--multiple .select2-selection__choice) {
    margin-top: 2px;
    margin-bottom: 2px;
}

/* Fix clear button position */
:deep(.select2-container--default .select2-selection--single .select2-selection__clear) {
    position: absolute !important;
    right: 25px !important;
    top: 50% !important;
    transform: translateY(-50%) !important;
    margin: 0 !important;
    height: auto !important;
    font-size: 16px !important;
    z-index: 10 !important;
    color: #999 !important;
    font-weight: bold !important;
}
</style>