<template>
    <div id="wrapper">
        <MessageSliderCopy :isVisible="displayMessage" :messageText="messageText" :messageType="messageType" />

        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 mx-auto">
                <div class="login-container d-flex align-items-center justify-content-center">
                    <div class="row auth-card-box">
                        <InfiniteLinearLoader :loading="loading" />

                        <div class="image col-lg-6 col-md-6 col-sm-12 p-0">
                            <img src="../../assets/images/login_logo_new.jpeg" alt="login image" />
                        </div>

                        <div class="form-content col-lg-6 col-md-6 col-sm-12">
                            <img src="../../assets/key-logo.png" alt="logo" />
                            <p>Reset your password by entering your email address</p>

                            <div class="row mb-30">
                                <div class="col-12">
                                    <TextInputFieldVue :config="{
                                        class: 'form-control-gray',
                                        placeholder: 'Enter Email',
                                        inputFieldType: 'email'
                                    }" labelText="Enter Email" :errorMessage="emailError" v-model="email" />
                                </div>
                            </div>

                            <RegularButtonVue buttonText="Request OTP" class="custom-button mb-3" :loading="loading"
                                @click="handleFormSubmit" />

                            <p class="forgot-text">
                                Remember Password? <span><a href="#" @click="navigateToLogin">Click to login</a></span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TextInputFieldVue from "@/components/common/TextInputField.vue";
import PasswordInputFieldVue from "@/components/common/PasswordInputField.vue";
import CheckBoxVue from "@/components/common/CheckBox.vue";
import RegularButtonVue from "@/components/common/RegularButton.vue";
import InfiniteLinearLoader from "@/components/common/InfiniteLinearLoader.vue";
import MessageSliderCopy from "@/components/common/MessageSliderCopy.vue";
import { ref } from "vue";
import { API_BASE_URL } from "@/config.js";
import { useRouter } from "vue-router";
import { flashMessage } from "@/utils/flashMessage.js";

export default {
    components: {
        TextInputFieldVue,
        PasswordInputFieldVue,
        CheckBoxVue,
        RegularButtonVue,
        InfiniteLinearLoader,
        MessageSliderCopy,
    },
    setup() {
        const email = ref("");

        const loading = ref(false);
        const loadingSpinner = ref(false);
        const router = useRouter();

        const displayMessage = ref(false);
        const messageText = ref("");
        const messageType = ref("");
        const emailError = ref("");

        const showMessage = (text, type) => {
            flashMessage(text, type, messageText, messageType, displayMessage);
        };

        const showLoaders = () => {
            loading.value = true;
            loadingSpinner.value = true;
        };

        const hideLoaders = () => {
            loading.value = false;
            loadingSpinner.value = false;
        };

        const handleFormSubmit = async () => {
            if (email.value === '') {
                emailError.value = 'Enter an email address'
                return
            }

            const emailBody = {
                email: email.value
            }

            try {
                emailError.value = ''
                showLoaders();
                const apiURL = ref(`${API_BASE_URL}/auth/reset-password`);

                const response = await fetch(apiURL.value, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(emailBody),
                });

                if (response.ok) {
                    const result = await response.json();

                    if (result.code === 200) {
                        flashMessage(
                            "Password reset successful, an OTP has been sent to your mail",
                            "success",
                            messageText,
                            messageType,
                            displayMessage
                        );

                    } else {
                        console.log('code error => ', result.message)
                        flashMessage(
                            result.message,
                            "error",
                            messageText,
                            messageType,
                            displayMessage
                        );
                    }
                } else {
                    const result = await response.json();
                    console.log('ok error => ', result.message)
                    flashMessage(
                        result.message,
                        "error",
                        messageText,
                        messageType,
                        displayMessage
                    );
                }
            } catch (error) {
                console.error("An error occurred:", error);
                flashMessage(
                    "An error occurred while processing your request.",
                    "error",
                    messageText,
                    messageType,
                    displayMessage
                );
            } finally {
                hideLoaders();
            }
        };

        const navigateToLogin = () => {
            router.push({ name: 'Login' })
        }

        return {
            navigateToLogin,
            email,
            handleFormSubmit,
            loading,
            showMessage,
            displayMessage,
            messageText,
            messageType,
            emailError
        };
    },
};
</script>

<style scoped>
@media (min-width: 1200px) {
    .auth-card-box {
        height: 550px;
        box-shadow: 0 0 15px 0 rgba(184, 188, 202, 0.5);
        position: relative;
    }

    .login-container {
        height: 100vh
    }
}

@media (min-width: 768px) and (max-width: 1199px) {
    .auth-card-box {
        height: 550px;
        box-shadow: 0 0 15px 0 rgba(184, 188, 202, 0.5);
        position: relative;
    }

    .login-container {
        height: 100vh
    }
}

.image {
    height: 100%;
}

.image>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.form-content {
    height: 100%;
    width: 60%;
    display: inline-block;
    position: inherit;
    top: 0;
    padding: 50px 45px;
}

.form-content>img {
    height: 50px;
    width: 80px;
    margin-bottom: 10px;
}

.form-content>p {
    margin-bottom: 40px;
}

.form-content>.forgot-text {
    font-size: 14px;
    color: #333;
}

.form-content>.forgot-text span {
    color: #f75549;
}

.checkboxVue {
    margin-bottom: 20px;
}
</style>