<template>
    <div class="row">
        <div class="col-12">
            <div class="row">
                <div class="col-12">
                    <SingleSelectFieldNew labelText="Select Goal Category" :options="goalCategorySelectOptions"
                        v-model="selectedGoalCategory" class="mb-3" selectId="goalCategorySelectOptionsId"
                        :isRequired="true" :selectedItem="selectedGoalCategory" :loading="pageLoader"
                        @change="fetchLearningGoalBasedOnGoalCategory" />
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <MultipleSelectField labelText="Learning Goal(s)" :options="filteredLearningGoals"
                        v-model="selectedLearningGoals" selectId="learningGoal" :isRequired="true"
                        :errorMessage="learningGoalError" :selectedItem="selectedLearningGoals"
                        :loading="goalSelectLoader" :maximum-selection-length="5" />
                </div>
            </div>

            <div class="row mb-30">
                <div class="col-12">
                    <table class="table table-borderless mt-20" v-if="learningGoals.length !== 0">
                        <thead>
                            <tr>
                                <th class="text-center">S/N</th>
                                <th>Goal Category</th>
                                <th>Learning Goals</th>
                                <th class="text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in learningGoals" :key="index">
                                <td class="text-center">{{ index + 1 }}</td>
                                <td>{{ Object.keys(item)[0] }}</td>

                                <td>
                                    <span v-for="(goal) in item[Object.keys(item)[0]]" :key="goal"
                                        class="badge badge-blue mr-1">{{ goal }}</span>
                                </td>

                                <td class="text-center">
                                    <a href="#" @click.prevent="deleteLearningGoal(index)">
                                        <img class="mr-2" src="@/assets/images/icons/trash_icon.svg"
                                            alt="Delete Icon" />
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <div class="text-right">
                        <RegularButton buttonText="Add Learning Goal" class="btn btn-sm btn-outline-dark mb-50"
                            color="yellow" @click="addLearningGoal" />
                    </div>

                    <RoundedButton buttonText="Save Changes" class="custom-button" :loading="loading"
                        @click="updateItems" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import SingleSelectFieldNew from "@/components/common/SingleSelectFieldNew.vue";
import MultipleSelectField from "@/components/common/MultipleSelectField.vue";
import RegularButton from "@/components/common/RegularButton.vue";
import { ref, watch } from "vue";
import RoundedButton from "@/components/common/RoundedButton.vue";
import { API_BASE_URL } from "@/config";
import { getSchoolId, getValidToken } from "@/utils/helpers";
import { checkAndRefreshToken } from "@/utils/auth";

export default {
    props: {
        itemData: {
            type: Object,
            required: true
        }
    },
    components: { RoundedButton, RegularButton, MultipleSelectField, SingleSelectFieldNew },
    setup(props, { emit }) {
        const learningGoals = ref(props.itemData.learning_goals)
        const learningGoalError = ref(null);
        const selectedGoalCategory = ref(null)
        const selectedLearningGoals = ref([])
        const goalCategorySelectOptions = ref([])
        const loading = ref(false)
        const pageLoader = ref(false)
        const goalSelectLoader = ref(false)

        setTimeout(() => {
            goalCategorySelectOptions.value = [
                { label: "Content", value: 'Content' },
                { label: "Wellbeing", value: 'Wellbeing' },
                { label: "Skills", value: 'Skills' },
                { label: "Mindset", value: 'Mindset' },
            ]
        }, 500)

        const learningGoalSelectOptions = ref([
            { label: "Literacy", value: 'Literacy', category: "Content" },
            { label: "Numeracy", value: 'Numeracy', category: "Content" },
            { label: "Science", value: 'Science', category: "Content" },
            { label: "Arts & culture", value: 'Arts & culture', category: "Content" },
            { label: "Technology", value: 'Technology', category: "Content" },
            { label: "Makerspace", value: 'Makerspace', category: "Content" },
            { label: "Body & movement", value: 'Body & movement', category: "Wellbeing" },
            { label: "Relationships", value: 'Relationships', category: "Wellbeing" },
            { label: "Self Expression", value: 'Self Expression', category: "Wellbeing" },
            { label: "Self Awareness", value: 'Self Awareness', category: "Wellbeing" },
            { label: "Self Care", value: 'Self Care', category: "Wellbeing" },
            { label: "Fulfilment", value: 'Fulfilment', category: "Wellbeing" },
            { label: "Critical thinking", value: 'Critical thinking', category: "Skills" },
            { label: "Problem Solving", value: 'Problem Solving', category: "Skills" },
            { label: "Collaboration", value: 'Collaboration', category: "Skills" },
            { label: "Self Direction", value: 'Self Direction', category: "Skills" },
            { label: "Risk Taking", value: 'Risk Taking', category: "Skills" },
            { label: "Financial Literacy", value: 'Financial Literacy', category: "Skills" },
            { label: "Curiosity", value: 'Curiosity', category: "Mindset" },
            { label: "Imagination", value: 'Imagination', category: "Mindset" },
            { label: "Adaptation", value: 'Adaptation', category: "Mindset" },
            { label: "Empathy and Care", value: 'Empathy and Care', category: "Mindset" },
            { label: "Persistence/Resilience", value: 'Persistence/Resilience', category: "Mindset" },
            { label: "Growth Mindset", value: 'Growth Mindset', category: "Mindset" },
        ])

        const filteredLearningGoals = ref([])

        // Watch for changes in selectedGoalCategory and update filteredLearningGoals accordingly
        watch(selectedGoalCategory, (newCategory) => {
            if (newCategory) {
                filteredLearningGoals.value = learningGoalSelectOptions.value.filter(
                    (goal) => goal.category === newCategory
                );

                selectedLearningGoals.value = null
            } else {
                filteredLearningGoals.value = [];
            }
        });
        const addLearningGoal = () => {
            learningGoals.value.push({
                [selectedGoalCategory.value]: selectedLearningGoals.value
            })
        }

        const deleteLearningGoal = (index) => {
            learningGoals.value.splice(index, 1)
        }

        const validateLearningGoalsPage = () => {
            let isValid = true

            if (learningGoals.value.length === 0) {
                learningGoalError.value = 'You need to select at least one learning goal'
                isValid = false
            }

            return isValid
        }

        const updateItems = async () => {
            const learningGoalsData = {
                learning_goals: learningGoals.value,
            }

            if (validateLearningGoalsPage()) {
                try {
                    const schoolId = getSchoolId()
                    const apiUrl = `${API_BASE_URL}/school/${schoolId}/projects/${props.itemData.id}`
                    loading.value = true
                    const accessToken = getValidToken()

                    await checkAndRefreshToken()

                    const response = await fetch(apiUrl, {
                        method: "PUT",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${accessToken}`,
                        },
                        body: JSON.stringify(learningGoalsData)
                    }
                    );

                    if (response.ok) {
                        const result = await response.json();
                        if (result.code === 200) {
                            const msg = {
                                type: 'success',
                                message: 'Success! You have updated the learning goals details'
                            }
                            emit('closeFormModal', msg)
                        } else {
                            console.log("error", result.message);
                        }
                    } else {
                        const result = await response.json();
                        console.log("error", result.message);
                    }
                } catch (error) {
                    console.error("An error occured:", error);
                } finally {
                    loading.value = false
                }
            }
        }

        const fetchLearningGoalBasedOnGoalCategory = async (category) => {
            try {
                goalSelectLoader.value = true
                filteredLearningGoals.value = learningGoalSelectOptions.value.filter(
                    (goal) => goal.category === category
                );
            } catch (error) {
                console.error("An error occured:", error);
            } finally {
                goalSelectLoader.value = false
            }
        }

        return {
            learningGoals,
            learningGoalError,
            learningGoalSelectOptions,
            selectedGoalCategory,
            filteredLearningGoals,
            addLearningGoal,
            deleteLearningGoal,
            selectedLearningGoals,
            goalCategorySelectOptions,
            updateItems,
            loading,
            pageLoader,
            goalSelectLoader,
            fetchLearningGoalBasedOnGoalCategory,
        }
    }
}
</script>
<style scoped>
.table td {
    padding: 10px
}

.table-borderless thead,
.table-borderless td {
    border-bottom: 1px solid #f1f1f1;
}

.table-borderless thead {
    color: #9a9a9a;
}

.table-borderless thead {
    background-color: #f5f6fa;
}

.table-borderless th:first-child {
    border-top-left-radius: 10px;
}

.table-borderless th:last-child {
    border-top-right-radius: 10px;
}

.tableEmail {
    font-size: 11px;
    color: #9a9a9a;
}

td {
    vertical-align: middle !important;
}
</style>