<template>
    <div class="row">
        <div class="col-lg-12 col-sm-12 col-md-12">
            <!-- start page title -->
            <div class="row greetings-block">
                <div class="col-12">
                    <h4 class="greetings">Greetings, {{ currentUserFullName }}! 👋</h4>
                    <p class="todaysDate">{{ todaysDate }}</p>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-9 col-md-12 col-sm-12 col-xs-12">
                    <div class="row stat-cards">
                        <StatCards statCardText="Total Schools" :statsCardNumber="totalSchools"
                                   columnClass="col-sm-12 col-lg-4" :loading="statsCardLoader"/>

                        <StatCards statCardText="Total Co-Learners" :statsCardNumber="totalTeachers"
                                   columnClass="col-sm-12 col-lg-4" :loading="statsCardLoader"/>

                        <StatCards statCardText="Total Students" :statsCardNumber="totalStudents"
                                   columnClass="col-sm-12 col-lg-4" :loading="statsCardLoader"/>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <div class="card-box">
                                <div class="card-box-header">
                                    <h4>Monthly Subscriptions</h4>

                                    <select v-model="selectedMonthlyYear" @change="changeYear"
                                            class=" pagination-select mr-2">
                                        <option v-for="year in years" :key="year" :value="year">{{ year }}</option>
                                    </select>
                                </div>

                                <div class="row" v-if="sortedMonths">
                                    <div class="col-12">
                                        <div class="schoolsLoader" v-if="monthlyStatsLoader">
                                            <div class="spinner-border avatar-lg text-primary mt-50 mb-100 mx-auto"
                                                 role="status"></div>
                                        </div>

                                        <Bar
                                            id="my-chart-id"
                                            :options="chartOptions"
                                            :data="chartData"
                                            v-else
                                        />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-6 col-sm-12">
                            <div class="card-box">
                                <div class="card-box-header mb-0">
                                    <h4>Total Revenue</h4>

                                    <select v-model="selectedMonth" @change="changeMonth"
                                            class=" pagination-select mr-2">
                                        <option v-for="(month, index) in monthsInaYear" :key="index" :value="index + 1">
                                            {{ month }}
                                        </option>
                                    </select>
                                </div>

                                <div class="row">
                                    <div class="col-12">
                                        <h4 class="bold">{{ formatCurrency(totalRevenueSum) }}</h4>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-12">
                                        <div class="schoolsLoader" v-if="monthlyRevenueDataLoader">
                                            <div class="spinner-border avatar-lg text-primary mt-50 mb-100 mx-auto"
                                                 role="status"></div>
                                        </div>

                                        <div v-else>
                                            <div v-if="totalRevenueSum === 0" class="pt-20">
                                                <h4>No subscriptions have been recorded this month</h4>
                                                <p class="text-muted">When there are subscriptions for the curernt month, the data will be
                                                    displayed here, you can also change the month to see data for previous months.</p>
                                            </div>

                                            <Doughnut
                                                :options="doughnutChartOptions"
                                                :data="doughnutChartData"
                                                v-else
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="col-lg-6 col-sm-12">
                            <div class="card-box">
                                <div class="card-box-header">
                                    <h4>Recent Activity Feed</h4>
                                </div>

                                <div class="row">
                                    <div class="col-12">
                                        <div class="recentActivitiesLoader" v-if="recentActivitiesLoader">
                                            <div class="spinner-border avatar-lg text-primary mt-50 mb-100 mx-auto"
                                                 role="status"></div>
                                        </div>

                                        <div class="name-container" v-for="activity in recentActivities" :key="activity" v-else>
                                            <div class="avatar-md school-avatar mr-15">
                                                <span
                                                    :class="`avatar-title bg-soft-success text-success rounded-circle`">
                                                    {{ getInitialsForWord(activity.action_performed_by) }}
                                                </span>
                                            </div>

                                            <div>
                                                <p class="m-0 student-name">
                                                    {{ `${capitalizeFirstLetter(activity.action_performed_by)} ${setLowerCaseForAllWordsInSentence(activity.action)}.` }}
                                                </p>
                                                <span class="muted-text-sm">
                                                    {{ timeAgo(activity.created_at) }}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-12 col-md-12 p-0 m-0 right-box">
                    <div class="card-box-small right-side">
                        <div class="card-content">
                            <div class="header-content text-center">
                                <div v-if="currentUser.profile_image">
                                    <img
                                        class="mb-20 d-block mx-auto"
                                        :src="itemData.profile_image"
                                        alt="Profile Image"
                                    />

                                    <input ref="fileInput" type="file" accept=".png, .jpg" @change="handleFileChange"
                                           style="display: none"/>
                                </div>

                                <AvatarForInitials :avatarText="getUserInitialsForName(currentUserFullName)" iconColor="primary"
                                                   class="avatar-md-150 mb-30 m-auto" v-else/>

                                <h4 class="user-name">{{ currentUserFullName }}</h4>
                                <p class="user-role-name">{{ currentUserRoleName }}</p>
                            </div>

                            <div class="studentsSection">
                                <div class="header">
                                    <h4>Recently Added Schools</h4>
                                    <router-link :to="{ name: 'SchoolsList' }">See All</router-link>
                                </div>

                                <div class="studentsList">
                                    <div class="schoolsLoader" v-if="schoolsLoader">
                                        <div class="spinner-border avatar-lg text-primary mt-30 mx-auto"
                                             role="status"></div>
                                    </div>

                                    <template v-for="school in schoolsList" :key="school" v-else>
                                        <div class="name-container">
                                            <div class="avatar-md school-avatar mr-15">
                                                <span
                                                    :class="`avatar-title bg-soft-success text-success font-20 rounded-circle`">
                                                    {{ school.initials }}
                                                </span>
                                            </div>

                                            <div>
                                                <p class="m-0 student-name">{{ capitalizeFirstLetter(school.name) }}</p>
                                                <span class="muted-text-sm">
                                                    {{ capitalizeFirstLetter(school.state) }},
                                                    {{ capitalizeFirstLetter(school.country) }}
                                                </span>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import getDashboardStats from "@/composables/getDashboardReports";
import {onMounted, ref, watch} from "vue";
import getSchools from "@/composables/getSchools";
import {
    capitalizeFirstLetter,
    formatCurrency,
    getCurrentDateFormatted,
    getCurrentMonthNumber,
    getCurrentUser,
    getCurrentYear,
    getInitialsForWord,
    getUserInitialsForName,
    setLowerCaseForAllWordsInSentence,
    timeAgo,
} from "@/utils/helpers";
import StatCards from "@/components/common/StatCards.vue";
import InfiniteLinearLoader from "@/components/common/InfiniteLinearLoader.vue";
import {Bar, Doughnut} from "vue-chartjs";
import {ArcElement, BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip} from 'chart.js'
import AvatarForInitials from "@/components/common/AvatarForInitials.vue";

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement)

export default {
    components: {AvatarForInitials, StatCards, Bar, Doughnut, InfiniteLinearLoader},
    setup(props, {emit}) {
        document.title = 'Home'
        const {
            dashboardStats, fetchDashboardStatistics, loading: statsCardLoader,
            sortedMonths, fetchMonthlyStatistics, monthlyStatsLoader,
            fetchTotalMonthlyRevenueStatistics, monthlyRevenueData, monthlyRevenueDataLoader,
            fetchRecentActivities, recentActivities, recentActivitiesLoader
        } = getDashboardStats()

        const {fetchSchoolsData, loading: schoolsLoader, schoolsData, formattedSchoolReportData} = getSchools()
        const totalStudents = ref(0)
        const totalSchools = ref(0)
        const totalTeachers = ref(0)
        const schoolsList = ref([])
        const selectedYear = ref(getCurrentYear())
        const currentMonthNumber = ref(getCurrentMonthNumber())
        const currentUser = ref(getCurrentUser())
        const currentUserFullName = ref('')
        const currentUserRoleName = ref('')
        const todaysDate = ref(getCurrentDateFormatted())
        const years = ref([])
        const selectedMonthlyYear = ref(new Date().getFullYear())
        const monthsInaYear = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December'
        ]
        const selectedMonth = ref(getCurrentMonthNumber())


        const chartData = ref({
            labels: [],
            datasets: []
        })

        const chartOptions = ref({
            responsive: true,
            scales: {
                x: {
                    stacked: true,
                    grid: {
                        display: false
                    },
                    ticks: {
                        font: {
                            family: 'Inter'
                        },
                    }
                },
                y: {
                    stacked: true,
                    grid: {
                        display: false
                    },
                    ticks: {
                        font: {
                            family: 'Inter'
                        },
                    }
                }
            },
            plugins: {
                legend: {
                    labels: {
                        font: {
                            family: 'Inter'
                        },
                        borderRadius: 6,
                        useBorderRadius: true,
                        boxHeight: 15
                    },
                    title: {
                        padding: 5,
                        display: true
                    },
                    position: 'bottom',
                }
            }
        })

        const doughnutChartData = ref({
            labels: [],
            datasets: []
        })

        const doughnutChartOptions = ref({
            responsive: true,
            plugins: {
                legend: {
                    position: 'left',
                    labels: {
                        font: {
                            family: 'Inter'
                        }
                    },
                }
            }
        })

        onMounted(() => {
            // Populate the years array with a range from startYear to currentYear
            for (let year = 2024; year <= selectedYear.value; year++) {
                years.value.push(year);
            }

            fetchDashboardStatistics()
            fetchSchoolsData(1, 5)
            fetchMonthlyStatistics(selectedYear.value)
            fetchTotalMonthlyRevenueStatistics(currentMonthNumber.value, getCurrentYear())
            fetchRecentActivities()

            currentUserFullName.value = capitalizeFirstLetter(currentUser.value.first_name, currentUser.value.last_name)
            currentUserRoleName.value = capitalizeFirstLetter(currentUser.value.role_name)

            emit('data-loaded')
        })

        watch(dashboardStats, (item) => {
            totalSchools.value = item.total_schools
            totalStudents.value = item.total_students
            totalTeachers.value = item.total_teachers
        })

        watch(schoolsData, (item) => {
            schoolsList.value = formattedSchoolReportData.value
        })

        watch(sortedMonths, (item) => {
            chartData.value = {
                labels: Object.keys(item),
                datasets: [
                    {
                        label: 'Active',
                        data: Object.values(item).map((item) => item.active),
                        backgroundColor: '#38CA89',
                        borderRadius: Number.MAX_VALUE,
                        barThickness: 40,
                        borderWidth: {
                            top: 10
                        },
                        borderColor: '#fff',
                    },
                    {
                        label: 'Processing',
                        data: Object.values(item).map((item) => item.processing),
                        backgroundColor: '#FFA600',
                        borderRadius: Number.MAX_VALUE,
                        barThickness: 40,
                        borderWidth: {
                            top: 10
                        },
                        borderColor: '#fff'
                    },
                    {
                        label: 'Cancelled',
                        data: Object.values(item).map((item) => item.cancelled),
                        backgroundColor: '#F24E1E',
                        borderRadius: Number.MAX_VALUE,
                        barThickness: 40
                    },
                ],
            }
        })

        const totalRevenueSum = ref(0)
        watch(monthlyRevenueData, (item) => {
            totalRevenueSum.value = item.total_month_revenue
            doughnutChartData.value = {
                labels: Object.keys(item.weeks),
                datasets: [{
                    backgroundColor: ['#F73F15', '#FFBD48', '#0DB2DE', '#0D44B2'],
                    data: Object.values(item.weeks),
                    hoverOffset: 20,
                    radius: '80%',
                }]
            }
        })

        const changeYear = () => {
            fetchMonthlyStatistics(selectedMonthlyYear.value)
        }

        const changeMonth = () => {
            fetchTotalMonthlyRevenueStatistics(selectedMonth.value, getCurrentYear())
        }

        return {
            totalRevenueSum,
            statsCardLoader,
            dashboardStats,
            totalStudents,
            totalSchools,
            totalTeachers,
            schoolsLoader,
            schoolsData,
            selectedYear,
            sortedMonths,
            monthlyStatsLoader,
            currentUser,
            currentUserFullName,
            monthlyRevenueDataLoader,
            todaysDate,
            currentUserRoleName,
            schoolsList,
            capitalizeFirstLetter,
            years,
            selectedMonthlyYear,
            changeYear,
            chartData,
            chartOptions,
            monthsInaYear,
            selectedMonth,
            changeMonth,
            doughnutChartData,
            doughnutChartOptions,
            recentActivities,
            getUserInitialsForName,
            setLowerCaseForAllWordsInSentence,
            getInitialsForWord,
            timeAgo,
            formatCurrency,
            recentActivitiesLoader
        }
    }
};
</script>

<style scoped>
.greetings-block {
    margin-top: 30px;
    margin-bottom: 30px;
}

.greetings {
    font-size: 20px;
    margin-bottom: 0;
}

.todaysDate {
    color: #9A9A9A;
    font-size: 16px;
    margin-bottom: 0;
}

.card-box-small {
    padding: 0 30px 50px 30px;
    background-color: #fff;
    box-shadow: 0 0.75rem 6rem rgba(56, 65, 74, .03);
    margin-bottom: 24px;
    border-radius: 12px !important;
}

.header-content {
    padding: 30px 0 10px 0;
    position: relative;
    border-bottom: 1px solid #e9eaf3;
}

.user-name {
    margin-bottom: 0;
    font-family: "Inter SemiBold", sans-serif;
    font-size: 20px;
}

.user-role-name {
    margin-bottom: 30px;
    color: #9A9A9A;
    font-size: 12px;
}


.studentsSection {
    padding-top: 30px;
    padding-bottom: 10px;
}

.studentsSection .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.studentsSection .header h4 {
    font-family: 'Inter SemiBold', 'Arial', sans-serif;
    color: #0D44B2;
    font-size: 14px;
}

.studentsSection .header a {
    font-family: 'Inter Medium', sans-serif;
    color: #9A9A9A;
}

.school-avatar {
    height: 40px;
    width: 40px;
}

.name-container {
    display: flex;
    align-items: center;
    padding: 5px 0;
    margin-bottom: 10px;
}

.pagination-select {
    padding: 10px;
    border: 1px solid #F1F1F1;
    border-radius: 10px
}

.card-box-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

canvas {
    width: 100%;
    height: auto;
}

.right-side {
    height: 100%;
}

.schoolsLoader, .recentActivitiesLoader {
    display: flex;
    align-items: center;
}

.name-container {
    display: flex;
    align-items: center;
    padding: 5px 0;
}


</style>