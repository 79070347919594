<template>
    <form @submit.prevent="saveNewItem">
        <div>
            <div class="row mb-30">
                <div class="col-lg-6 col-sm-12">
                    <TextInputField :config="{
                    class: 'form-control-gray',
                    placeholder: 'Enter Name',
                    inputFieldType: 'text',
                }" labelText="Name" errorType="nameError" v-model="name" :isRequired="true"/>
                </div>

                <div class="col-lg-6 col-sm-12">
                    <TextInputField :config="{
                        class: 'form-control-gray',
                        placeholder: 'Enter Email',
                        inputFieldType: 'email',
                    }" labelText="Email" errorType="nameError" v-model="email" :isRequired="true"/>
                </div>
            </div>

            <div class="row mb-30">
                <div class="col-lg-6 col-sm-12">
                    <SingleSelectField labelText="Gender" :options="genderSelectOptions" v-model="selectedGender" :isRequired="true"
                                    selectId="genderOptions" :selectedItem="selectedGender" :loading="genderLoader"/>
                </div>

                <div class="col-lg-6 col-sm-12">

                    <TextInputField :config="{
                    class: 'form-control-gray',
                    placeholder: 'Enter Contact Number',
                    inputFieldType: 'tel',
                }" labelText="Contact Number" v-model="msisdn" :isRequired="true"/>
                </div>
            </div>

            <div class="row mb-30">
                <div class="col-12">
                    <TextAreaInputField :config="{
                        class: 'form-control-gray',
                        placeholder: 'Enter Address',
                        inputFieldType: 'address',
                    }" labelText="Residential Address" errorType="addressError" v-model="address"/>
                </div>
            </div>

            <div class="row mb-50">
                <div class="col-12">
                    <SingleSelectField labelText="Role on Portal" :options="rolesSelectOptions" v-model="selectedRole" class="mb-30"
                                    :loading="dropdownLoader" :isRequired="true"
                                    selectId="roleOptions" :selectedItem="selectedRole"/>
                </div>
            </div>

            <RoundedButton buttonText="Submit" class="custom-button" buttonType="submit" :loading="buttonLoading"/>

        </div>

    </form>
</template>
<script>
import TextInputField from "@/components/common/TextInputField.vue";
import SimpleDropdown from "@/components/common/SimpleDropdown.vue";
import TextAreaInputField from "@/components/common/TextAreaInputField.vue";
import RoundedButton from "@/components/common/RoundedButton.vue";
import {onMounted, ref, watch} from "vue";
import {capitalizeFirstLetter, getSchoolId, getValidToken} from '@/utils/helpers'
import getGender from "@/composables/getGender";
import {API_BASE_URL} from "@/config";
import {checkAndRefreshToken} from "@/utils/auth";
import getSchoolRoles from "@/composables/Schools/getSchoolRoles";
import SingleSelectField from "@/components/common/SingleSelectField.vue";


export default {
    components: {
        SingleSelectField,
        TextInputField,
        SimpleDropdown,
        TextAreaInputField,
        RoundedButton,
    },
    setup(props, {emit}) {
        const buttonLoading = ref(false)
        const {fetchGender, genderList, loading: genderLoader} = getGender()
        const {fetchRolesData, rolesData, loading: dropdownLoader} = getSchoolRoles()
        const genderSelectOptions = ref([])
        const rolesSelectOptions = ref([])
        const selectedGender = ref('')
        const selectedRole = ref(0)
        const name = ref('')
        const msisdn = ref('')
        const email = ref('')
        const address = ref('')

        onMounted(() => {
            fetchGender()
            fetchRolesData()
        })

        const updateRoleOptions = (newData) => {
            rolesSelectOptions.value = newData.map(role => ({label: capitalizeFirstLetter(role.name), value: role.id}));

            if (rolesSelectOptions.value.length > 0) {
                selectedRole.value = rolesSelectOptions.value[0].value;
            }
        }

        watch(rolesData, (newData) => {
            updateRoleOptions(newData)
        })


        const updateGenderOptions = (newData) => {
            genderSelectOptions.value = newData.map(item => ({
                label: capitalizeFirstLetter(item.label), value: item.value
            }))

            if (genderSelectOptions.value.length > 0) {
                selectedGender.value = genderSelectOptions.value[0].value;
            }
        }

        watch(genderList, (newData) => {
            updateGenderOptions(newData)
        })

        // OTHERS
        const showLoaders = () => {
            buttonLoading.value = true;
        };

        const hideLoaders = () => {
            buttonLoading.value = false;
        };

        const schoolId = getSchoolId()
        const apiUrl = `${API_BASE_URL}/school/${schoolId}/add-school-admin`

        const saveNewItem = async () => {
            const newItem = {
                name: name.value,
                gender: selectedGender.value,
                email: email.value,
                msisdn: msisdn.value,
                address: address.value,
                role: selectedRole.value,
            }

            // console.log('newItem', newItem)

            try {
                showLoaders()
                await checkAndRefreshToken()
                const accessToken = getValidToken()

                const response = await fetch(apiUrl, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`,
                    },
                    body: JSON.stringify(newItem)
                })


                if (response.ok) {
                    const result = await response.json();

                    if (result.code === 200) {
                        const itemFullName = capitalizeFirstLetter(newItem.name)
                        emit("closeFormModal", itemFullName);
                    } else {
                        console.log("error", result.message);
                        emit('hasError', result.message)
                    }
                } else {
                    const result = await response.json();
                    console.log("error", result.message);
                    emit('hasError', result.message)
                }
            } catch (error) {
                console.error("An error occured:", error);
                emit('hasError', error.message)
            } finally {
                hideLoaders();
            }
        }

        return {
            buttonLoading,
            saveNewItem,
            name,
            genderSelectOptions,
            selectedGender,
            msisdn,
            email,
            address,
            genderLoader,
            dropdownLoader,
            rolesSelectOptions,
            selectedRole
        }
    }
}
</script>
<style scoped>

</style>