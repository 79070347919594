<template>
    <!-- start page title -->
    <div class="row">
        <div class="col-12">
            <div class="page-title-box">
                <h4 class="page-title">Manage Schools</h4>
                <div class="page-title-right">
                    <RoundedButton buttonText="Add School" iconText="mdi-plus" @clicked="openFormModal"
                        v-if="hasPermission('add_school')" />
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <StatCards statCardText="Total Schools" :statsCardNumber="numOfSchools" />
        <StatCards statCardText="Active Schools" :statsCardNumber="activeSchools" />
        <StatCards statCardText="Deactivated Schools" :statsCardNumber="inActiveSchools" />
    </div>

    <div class="row mainContent" v-if="hasPermission('view_school')">
        <div class="col-sm-12">
            <div class="card-box">
                <TableView :data="formattedSchoolsData" :headers="tableHeaders" :id="getIdForActions"
                    :showSearchField="false" :detailsData="getDataForDetailsView" :loading="loading"
                    :searchStringFromParent.sync="searchStringFromChild" :showActionsColumn="true"
                    :searchFields="searchFieldsData" :totalPages="totalPages" :currentPage.sync="currentPage"
                    :changePage="changePage" @searchWord="searchForItem" :changeItemsPerPage="changeItemsPerPage"
                    :itemsPerPage="itemsPerPage" :viewAction="viewAction" :editAction="editAction"
                    :deactivateAction="openConfirmationDialog" viewPermission="view_school"
                    editPermission="modify_school" deactivatePermission="deactivate_school" />
            </div>
        </div>
    </div>

    <InfoModal :isModalOpen="isConfirmationDialogOpen" @closeModal="closeConfirmationDialog"
        :successImageURL="require('../../../assets/images/icons/success_icon.svg')"
        :errorImageURL="require('../../../assets/images/icons/error_icon.svg')" header="Hold on!"
        :paragraph="deactivateConfirmationParagraph" :buttonTxt="deactivateButtonText" :buttonAction="performAction"
        buttonColor="error" routerLinkText="No, Cancel Action" buttonLink="/" :linkAction="cancelAction"
        :loading="buttonLoader">
    </InfoModal>

    <FormModal :isFormModalOpen="isFormModalOpen" @closeFormModal="closeFormModal1" modalHeader="Add School">
        <CreateSchool @closeFormModal="closeFormModal" />
    </FormModal>

    <FormModal :isFormModalOpen="isEditFormModalOpen" @closeFormModal="closeEditFormModal" modalHeader="Edit School">
        <EditSchool @closeFormModal="closeEditFormModal" :itemData="itemDataForEditing" />
    </FormModal>

    <InfoModal :isModalOpen="isFlashModalOpen" @closeModal="closeFlashModal" :isSuccess="true" header="School Added"
        :paragraph="`You have successfully added '${fullName}'. You can view and manage this school's profile under 'Manage Schools'.`"
        buttonTxt="Add Another School" routerLinkText="Manage Schools" :linkAction="createItemFlashLinkAction"
        :buttonAction="buttonAction">
    </InfoModal>

</template>
<script>
import { computed, onActivated, onMounted, ref, watch } from "vue";
import RoundedButton from "@/components/common/RoundedButton.vue";
import TableView from "@/components/common/TableView.vue";
import FormModal from "@/components/common/FormModal.vue";
import InfoModal from "@/components/common/InfoModals.vue";
import CreateSchool from "@/components/layouts/GodAdmin/Schools/CreateSchool.vue";
import EditUser from "@/components/layouts/GodAdmin/Users/EditUser.vue";
import { useRouter } from 'vue-router';
import getSchools from "@/composables/getSchools";
import { API_BASE_URL } from "@/config.js";
import { capitalizeFirstLetter, getValidToken, hasPermission } from '@/utils/helpers'
import { checkAndRefreshToken } from '@/utils/auth';
import StatCards from "@/components/common/StatCards.vue";
import EditSchool from "@/components/layouts/GodAdmin/Schools/EditSchool.vue";
import { useUserStore } from "@/stores/user";
import { useToast } from "@/composables/useToast";

export default {
    components: {
        EditSchool,
        RoundedButton,
        TableView,
        FormModal,
        CreateSchool,
        EditUser,
        InfoModal,
        StatCards
    },
    emits: ['showLoader', 'flashMessage', 'data-loaded'],
    setup(props, { emit }) {
        document.title = 'Manage Schools'
        const { schoolsPayloadData, fetchSchoolsData, loading, formattedSchoolsData, schoolsData } = getSchools()
        const isFormModalOpen = ref(false);
        const isEditFormModalOpen = ref(false);
        const buttonLoader = ref(false);
        const showDetailsOrIcons = ref(false)
        const router = useRouter()
        const accessToken = getValidToken();
        const isConfirmationDialogOpen = ref(false);
        const isFlashModalOpen = ref(false);
        const fullName = ref('')
        const currentPage = ref(1);
        const itemsPerPage = ref(10); // Default items per page
        const totalPages = ref(0)
        const itemData = ref(null)
        const numOfSchools = ref(0)
        const activeSchools = ref(0)
        const inActiveSchools = ref(0)
        const isSaveSuccessful = ref(false)
        const toast = useToast();

        const buttonAction = () => {
            isFlashModalOpen.value = false;
            openFormModal()
        }

        const openFlashModal = () => {
            isFlashModalOpen.value = true;
        };

        const closeFlashModal = async () => {
            isFlashModalOpen.value = false;
            await fetchSchoolsData(currentPage.value, itemsPerPage.value)
        };

        const createItemFlashLinkAction = () => {
            closeFlashModal()
        }

        const openFormModal = () => {
            isFormModalOpen.value = true;
        };

        const closeFormModal = async (name) => {
            isFormModalOpen.value = false;

            if (name) {
                fullName.value = name
                isFlashModalOpen.value = true
            }

            await fetchSchoolsData(currentPage.value, itemsPerPage.value)
        };

        const closeFormModal1 = async () => {
            isFormModalOpen.value = false;
            await fetchSchoolsData(currentPage.value, itemsPerPage.value)
        }

        const openEditFormModal = () => {
            isEditFormModalOpen.value = true;
        };

        const closeEditFormModal = async (message) => {
            isEditFormModalOpen.value = false;
            await fetchSchoolsData(currentPage.value, itemsPerPage.value)
        };

        const searchStringFromChild = ref("");
        const searchFieldsData = ['itemName.name', 'license_number', 'current_plan', 'next_billing', 'status']

        const searchForItem = (word) => {
            // console.log('got in here', word)
        }

        watch(schoolsData, () => {
            totalPages.value = schoolsPayloadData.value.total_pages

            const stats = schoolsPayloadData.value.results
            numOfSchools.value = stats.total_schools
            activeSchools.value = stats.total_active_schools
            inActiveSchools.value = stats.total_deactivated_schools
        })

        onMounted(() => {
            fetchSchoolsData(currentPage.value, itemsPerPage.value);
            emit('data-loaded')
        })

        onActivated(() => {
            fetchSchoolsData(currentPage.value, itemsPerPage.value);
        })

        const getIdForActions = computed(() => {
            return schoolsData.value.map((item) => item.id);
        });

        const getDataForDetailsView = computed(() => {
            return schoolsData.value
        })

        const viewAction = (item, id) => {
            document.cookie = `schoolId=${id}; path=/`
            router.push(
                {
                    name: 'SchoolDashboard'
                })
        }

        const itemDataForEditing = ref(null)
        const editAction = (item, item_id) => {
            itemData.value = item

            // Find the item to edit
            const itemForEditing = schoolsData.value.find((r) => r.id === item_id);
            itemDataForEditing.value = {
                schoolName: itemForEditing.name,
                schoolMsisdn: itemForEditing.msisdn,
                country: itemForEditing.country,
                state: itemForEditing.state,
                schoolAddress: itemForEditing.address,
                id: item_id
            }

            openEditFormModal()
        }

        const deactivateAction = async (id) => {
            const apiUrl = `${API_BASE_URL}/school/${id}/toggle-status`
            try {
                await checkAndRefreshToken()

                buttonLoader.value = true
                const response = await fetch(apiUrl, {
                    method: 'PUT',
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`
                    }
                })

                if (response.ok) {
                    const result = await response.json()

                    if (result.code === 200) {
                        closeConfirmationDialog()
                        toast.success("School status updated");
                    } else {
                        toast.error(result.message || "Failed to update school status");
                        console.log('error', result.message)
                    }
                } else {
                    const result = await response.json();
                    toast.error(result.message || "Failed to update school status");
                    console.log("error", result.message);
                }
            } catch (error) {
                toast.error("An error occurred while updating school status");
                console.error("An error occured:", error);
            } finally {
                buttonLoader.value = false
            }
        }

        const showFlashMessage = (msg) => {
            if (msg.type === "success") {
                toast.success(msg.apiResponse);
            } else if (msg.type === "error") {
                toast.error(msg.apiResponse);
            } else {
                toast.info(msg.apiResponse);
            }
        };

        const tableHeaders = computed(() => {
            return [
                { label: "School Name", key: "name" },
                { label: "License No.", key: "license_number" },
                { label: "Added On", key: "date" },
                { label: "Current Plan", key: "current_plan" },
                { label: "Next Billing", key: "next_billing" },
                { label: "Acct Status", key: "status" }
            ];
        });

        const itemId = ref('null')
        const itemStatus = ref('')
        const deactivateConfirmationParagraph = ref('')
        const deactivateButtonText = ref('')

        const openConfirmationDialog = (item, id) => {
            itemId.value = id

            itemStatus.value = item.isDeactivated === false ? 'deactivate' : 'activate'
            deactivateConfirmationParagraph.value = `You are about to ${itemStatus.value} this school's account. Are you sure you want to continue this action?.`
            deactivateButtonText.value = `Yes, ${capitalizeFirstLetter(itemStatus.value)} School`
            isConfirmationDialogOpen.value = true;
        };

        const closeConfirmationDialog = () => {
            isConfirmationDialogOpen.value = false;
            fetchSchoolsData(currentPage.value, itemsPerPage.value)
        };

        const performAction = () => {
            deactivateAction(itemId.value)
        }

        const cancelAction = () => {
            closeConfirmationDialog()
        }

        const changePage = (page) => {
            if (page >= 1 && page <= totalPages.value) {
                currentPage.value = page;

                fetchSchoolsData(currentPage.value, itemsPerPage.value)
            }
        };

        const changeItemsPerPage = (perPage) => {
            itemsPerPage.value = perPage;
            currentPage.value = 1; // Reset to the first page when changing items per page

            fetchSchoolsData(currentPage.value, perPage);
        };

        const userStore = useUserStore();
        const user = ref(userStore.user)
        const userPermissions = ref(user.value.permissions)
        const checkPermission = (permission) => hasPermission(userPermissions, permission)

        return {
            tableHeaders,
            schoolsData,
            totalPages,
            formattedSchoolsData,
            getIdForActions,
            loading,
            searchStringFromChild,
            searchFieldsData,
            isFormModalOpen,
            openFormModal,
            closeFormModal,
            closeFormModal1,
            showFlashMessage,
            showDetailsOrIcons,
            viewAction,
            editAction,
            deactivateAction,
            InfoModal,
            isConfirmationDialogOpen,
            openConfirmationDialog,
            closeConfirmationDialog,
            performAction,
            cancelAction,
            buttonLoader,
            isEditFormModalOpen,
            searchForItem,
            openEditFormModal,
            closeEditFormModal,
            itemData,
            isFlashModalOpen,
            openFlashModal,
            closeFlashModal,
            buttonAction,
            fullName,
            createItemFlashLinkAction,
            deactivateConfirmationParagraph,
            deactivateButtonText,
            getDataForDetailsView,
            changePage,
            changeItemsPerPage,
            currentPage,
            itemsPerPage,
            numOfSchools,
            activeSchools,
            inActiveSchools,
            userPermissions,
            hasPermission: checkPermission,
        };
    }
}
</script>