<template>
    <div id="app">
        <router-view></router-view>
        <ToastNotification :show="toast.visible.value" :message="toast.message.value" :type="toast.type.value"
            :duration="toast.duration.value" @close="toast.hideToast" />
    </div>
</template>

<script>
import ToastNotification from '@/components/common/ToastNotification.vue'
import { useToast } from '@/composables/useToast'

export default {
    name: 'App',
    components: {
        ToastNotification
    },
    setup() {
        const toast = useToast()
        // Make the toast globally available for debugging if needed
        window.$toast = toast

        return {
            toast
        }
    }
}
</script>

<style>
#app {
    font-family: "Inter", sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
}
</style>
