<template>
    <BaseSelect :label-text="labelText" :options="options" :select-id="selectId" :model-value="modelValue"
        :class="class" :loading="loading" :is-disabled="isDisabled" :is-required="isRequired"
        :error-message="errorMessage" :label-helper="labelHelper" :label-helper-function="labelHelperFunction"
        :is-multiple="false" @update:model-value="$emit('update:modelValue', $event)"
        @change="$emit('change', $event)" />
</template>

<script>
import BaseSelect from './BaseSelect.vue';

export default {
    name: 'SingleSelectFieldNew',
    components: {
        BaseSelect
    },
    props: {
        labelText: String,
        options: {
            type: Array,
            required: true
        },
        selectId: {
            type: String,
            default: 'example-select'
        },
        modelValue: {
            type: [String, Number, null],
            default: null
        },
        class: String,
        loading: Boolean,
        isDisabled: Boolean,
        isRequired: Boolean,
        errorMessage: String,
        labelHelper: String,
        labelHelperFunction: Function
    },
    emits: ['update:modelValue', 'change']
};
</script>

<style scoped>
.label {
    display: flex;
    justify-content: space-between;
    font-size: 12px !important;
    font-family: "Graphik Medium", sans-serif !important;
}

/* Custom select */
.custom-select-wrapper {
    position: relative;
    display: block;
    width: 100%;
    /* Adjust the width as needed */
}

.custom-select-wrapper select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    background-color: var(--v-primary-white);
    border: 1px solid var(--v-primary-text-light-neutral) !important;
    color: #333333;
}

.custom-select-wrapper select option:checked {
    color: #b8bcca;
}

.invalid-feedback {
    display: block;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 0.75rem;
    color: #f1556c;
}

.linear-loader {
    height: 2px;
    /* Adjust the height as needed */
    background-color: #b8bcca;
    /* Adjust the color as needed */
    width: 100%;
    position: relative;
    top: -2.5px;
    left: 0;
    right: 0;
    overflow: hidden;
}

.train {
    width: 900px;
    /* Adjust the train's width */
    height: 2px;
    /* Should match the height of the loader */
    background-color: #2f5bcf;
    /* Adjust the color as needed */
    position: absolute;
    animation: moveBackAndForth 1s linear infinite;
    /* Adjust the animation speed as needed */
    top: 0;
    left: 0;
}

@keyframes moveBackAndForth {
    0% {
        /* Start off the screen to the left */
        left: 0;
    }

    50% {
        left: 100%;
        /* Move to the right of the screen */
    }

    100% {
        left: 0;
    }
}

.required {
    color: red;
    margin-left: 2px;
    font-weight: bolder;
}

.label-helper {
    font-size: var(--font-size-body);
    color: var(--v-primary-accent);
    cursor: pointer;
}
</style>