<template>
    <form @submit.prevent="saveNewItem">
        <div v-if="currentPage === 1">
            <div class="row mb-30">
                <div class="col-12">
                    <TextInputField :config="{
                        class: 'form-control-gray',
                        placeholder: 'Enter School Name',
                        inputFieldType: 'text',
                    }" labelText="School Name" :errorMessage="schoolNameError" v-model="schoolName"
                        :isRequired="true" />
                </div>
            </div>

            <div class="row mb-30">
                <div class="col-6">
                    <TextInputField :config="{
                        class: 'form-control-gray',
                        placeholder: 'Enter Contact Number',
                        inputFieldType: 'tel',
                    }" labelText="Contact Number" v-model="schoolMsisdn" :isRequired="true"
                        :errorMessage="schoolContactError" />
                </div>

                <div class="col-6">
                    <TextInputField :config="{
                        class: 'form-control-gray',
                        placeholder: 'Enter License Number',
                        inputFieldType: 'text',
                    }" labelText="School License Number" v-model="regNumber" />
                </div>

            </div>

            <div class="row mb-30">
                <div class="col-12">
                    <TextInputField :config="{
                        class: 'form-control-gray',
                        placeholder: 'Enter Email Address',
                        inputFieldType: 'email'
                    }" labelText="Official Email" v-model="schoolEmail" :isRequired="true"
                        :errorMessage="schoolEmailError" />
                </div>
            </div>

            <div class="row mb-30">
                <div class="col-6">
                    <SingleSelectFieldNew labelText="Country of Residence" :options="countrySelectOptions"
                        v-model="selectedCountry" :loading="countryDropdownLoader" selectId="countryOptions"
                        :isRequired="true" @change="handleCountrySelection" />
                </div>

                <div class="col-6">
                    <SingleSelectFieldNew labelText="State of Residence" :options="stateSelectOptions"
                        v-model="selectedState" :loading="statesDropdownLoader" :isRequired="true"
                        selectId="stateOptions" />
                </div>
            </div>

            <div class="row mb-50">
                <div class="col-12">
                    <TextAreaInputField :config="{
                        class: 'form-control-gray',
                        placeholder: 'Enter School Address',
                        inputFieldType: 'text',
                    }" labelText="School Address" errorType="schoolAddressError" v-model="schoolAddress"
                        :isRequired="true" />
                </div>
            </div>
        </div>

        <div v-if="currentPage === 2">
            <div class="row mb-30">
                <div class="col-12">
                    <p class="mb-30">Preferably the person who is to have all rights and access</p>

                    <TextInputField :config="{
                        class: 'form-control-gray',
                        placeholder: 'Enter Email Address',
                        inputFieldType: 'email'
                    }" errorType="emailError" labelText="Official Email" v-model="email" :isRequired="true" />
                </div>
            </div>

            <div class="row mb-30">
                <div class="col-12">
                    <TextInputField :config="{
                        class: 'form-control-gray',
                        placeholder: 'Enter Full Name',
                        inputFieldType: 'text'
                    }" errorType="fullNameError" labelText="Full Name" v-model="fullName" :isRequired="true" />
                </div>
            </div>

            <div class="row mb-30">
                <div class="col-6">
                    <SingleSelectFieldNew labelText="Gender" :options="genderSelectOptions" v-model="selectedGender"
                        selectId="genderOptions" :loading="genderLoader" :isRequired="true" />
                </div>

                <div class="col-6">
                    <TextInputField :config="{
                        class: 'form-control-gray',
                        placeholder: 'Enter Contact Number',
                        inputFieldType: 'tel',
                    }" labelText="Contact Number" v-model="msisdn" :isRequired="true" errorType="msisdnError" />
                </div>
            </div>

            <div class="row mb-50">
                <div class="col-12">
                    <TextAreaInputField :config="{
                        class: 'form-control-gray',
                        placeholder: 'Enter Address',
                        inputFieldType: 'address',
                    }" labelText="Contact Address" errorType="addressError" v-model="address" />
                </div>
            </div>
        </div>


        <div class="mt-20 table-pagination">
            <ul class="pagination pagination-rounded">
                <li v-for="page in pages" :key="page" :class="{ active: currentPage === page }" class="page-item">
                    <a href="#" class="page-link" @click="changePage(page)">{{ page }}</a>
                </li>
            </ul>

            <RoundedButton :buttonText="currentPage < pages ? 'Next' : 'Submit'" class="custom-button"
                :buttonType="currentPage < pages ? '' : 'submit'" @click="nextPage" :loading="buttonLoading" />
        </div>

    </form>
</template>
<script>
import TextInputField from "@/components/common/TextInputField.vue";
import SingleSelectFieldNew from "@/components/common/SingleSelectFieldNew.vue";
import TextAreaInputField from "@/components/common/TextAreaInputField.vue";
import RoundedButton from "@/components/common/RoundedButton.vue";
import { onMounted, ref, watch } from "vue";
import getCountries from "@/composables/getCountries";
import getStates from "@/composables/getStates";
import { capitalizeFirstLetter, getValidToken } from '@/utils/helpers'
import { checkAndRefreshToken } from '@/utils/auth'
import getGender from "@/composables/getGender";
import { API_BASE_URL } from "@/config";
import ImageUploadInput from "@/components/common/ImageUploadInput.vue";
import { useToast } from "@/composables/useToast";

export default {
    components: {
        TextInputField,
        SingleSelectFieldNew,
        TextAreaInputField,
        RoundedButton,
        ImageUploadInput
    },
    setup(props, { emit }) {
        const buttonLoading = ref(false)
        const { fetchCountriesData, countriesList, loading: countryDropdownLoader } = getCountries()
        const { fetchStatesData, statesList, loading: statesDropdownLoader } = getStates()
        const { fetchGender, genderList, loading: genderLoader } = getGender()
        const currentPage = ref(1);
        const genderSelectOptions = ref([])
        const countrySelectOptions = ref([])
        const stateSelectOptions = ref([])
        const selectedGender = ref('')
        const selectedCountry = ref('')
        const selectedState = ref('')
        const message = ref('')
        const pages = 2
        const msisdn = ref('')
        const email = ref('')
        const address = ref('')
        const countryIdMap = ref({});
        const imageFile = ref('')
        const schoolName = ref('')
        const schoolNameError = ref('')
        const schoolMsisdn = ref('')
        const schoolContactError = ref('')
        const regNumber = ref('')
        const schoolEmail = ref('')
        const schoolEmailError = ref('')
        const schoolAddress = ref('')
        const schoolAddressError = ref('')
        const emailError = ref('')
        const fullName = ref('')
        const fullNameError = ref('')
        const addressError = ref('')
        const msisdnError = ref('')
        const toast = useToast();

        onMounted(() => {
            fetchCountriesData()
        })

        const updateGenderOptions = (newData) => {
            genderSelectOptions.value = newData.map(item => ({
                label: capitalizeFirstLetter(item.label), value: item.value
            }))

            if (genderSelectOptions.value.length > 0) {
                selectedGender.value = genderSelectOptions.value[0].value;
            }
        }

        watch(genderList, (newData) => {
            updateGenderOptions(newData)
        })

        const validatePage1 = () => {
            let isValid = true

            schoolNameError.value = ''
            schoolContactError.value = ''
            schoolAddressError.value = ''
            schoolEmailError.value = ''

            if (schoolName.value.length === 0) {
                schoolNameError.value = 'Enter a valid name'
                isValid = false
            }


            if (schoolMsisdn.value.length === 0) {
                schoolContactError.value = 'Enter a valid contact number'
                isValid = false
            }

            if (schoolAddress.value.length === 0) {
                schoolAddressError.value = 'Enter an address'
                isValid = false
            }

            if (schoolEmail.value.length === 0) {
                schoolEmailError.value = 'Enter an email address'
                isValid = false
            }

            return isValid
        }

        const validatePage2 = () => {
            let isValid = true

            emailError.value = ''
            fullNameError.value = ''
            msisdnError.value = ''

            if (email.value.length === 0) {
                emailError.value = 'Enter a valid email'
                isValid = false
            }


            if (fullName.value.length === 0) {
                fullNameError.value = 'Enter a name'
                isValid = false
            }

            if (msisdn.value.length === 0) {
                msisdnError.value = 'Enter a contact number'
                isValid = false
            }

            return isValid
        }

        const nextPage = () => {
            if (currentPage.value === 1) {
                if (validatePage1() && currentPage.value < pages) {
                    currentPage.value++;
                } else {
                    fetchCountriesData()
                }
            } else if (currentPage.value === 2) {
                if (validatePage2() && currentPage.value < pages) {
                    currentPage.value++;
                } else {
                    fetchGender()
                }
            }
        };

        watch(currentPage, (newData) => {
            if (newData === 1) {
                fetchCountriesData()
            } else if (newData === 2) {
                fetchGender()
            }
        })

        const changePage = (page) => {
            if (currentPage.value === 1) {
                if (validatePage1()) {
                    currentPage.value = page;
                }
            } else {
                currentPage.value = page;
            }
        }

        // COUNTRIES
        const handleCountrySelection = () => {
            const selectedCountryId = countryIdMap.value[selectedCountry.value]
            fetchStatesData(selectedCountryId)
        }

        // Update the country dropdown when the modal is opened
        const updateCountryOptions = (newData) => {
            countrySelectOptions.value = newData.map(country => ({
                label: country.country_name, value: country.country_name
            }))

            countryIdMap.value = newData.reduce((map, country) => {
                map[country.country_name] = country.id;
                return map;
            }, {});

            if (countrySelectOptions.value.length > 0) {
                selectedCountry.value = 'Nigeria'
                handleCountrySelection()
            }
        }

        watch(countriesList, (newData) => {
            updateCountryOptions(newData)
        })

        // STATES
        // Update the states options
        const updateStatesOptions = (newData) => {
            stateSelectOptions.value = newData.map(state => ({
                label: state.state_name, value: state.state_name
            }))

            if (stateSelectOptions.value.length > 0) {
                selectedState.value = stateSelectOptions.value[0].value;
            }
        }

        watch(statesList, (newData) => {
            updateStatesOptions(newData)
        })

        // OTHERS
        const showLoaders = () => {
            buttonLoading.value = true;
        };

        const hideLoaders = () => {
            buttonLoading.value = false;
        };

        const saveNewItem = async () => {
            const primaryContact = {
                email: email.value,
                name: fullName.value,
                gender: selectedGender.value,
                msisdn: msisdn.value,
                address: address.value,
            }

            const newItem = {
                name: schoolName.value,
                msisdn: schoolMsisdn.value,
                reg_number: regNumber.value,
                email: schoolEmail.value,
                country: selectedCountry.value,
                state: selectedState.value,
                address: schoolAddress.value,
                primary_contact: primaryContact,
                logo: ''
            }

            // console.log('newItem', newItem)

            try {
                showLoaders()
                await checkAndRefreshToken()

                const apiUrl = `${API_BASE_URL}/school/add-school`
                const accessToken = getValidToken()

                const response = await fetch(apiUrl, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`,
                    },
                    body: JSON.stringify(newItem)
                })


                if (response.ok) {
                    const result = await response.json();

                    if (result.code === 200) {
                        message.value = result.message;
                        toast.success("School created successfully!");
                        emit("closeFormModal");
                    } else {
                        toast.error(result.message || "Failed to create school");
                        console.log("error", result.message);
                        emit("closeFormModal");
                    }
                } else {
                    const result = await response.json();
                    toast.error(result.message || "Failed to create school");
                    console.log("error", result.message);
                }
            } catch (error) {
                toast.error("An error occurred while creating school");
                console.error("An error occured:", error);
            } finally {
                hideLoaders();
            }
        }

        const handleImageFileUpdate = (newVal) => {
            imageFile.value = newVal;
        }

        return {
            buttonLoading,
            handleImageFileUpdate,
            saveNewItem,
            currentPage,
            nextPage,
            pages,
            changePage,
            genderSelectOptions,
            selectedGender,
            msisdn,
            email,
            countrySelectOptions,
            selectedCountry,
            countryDropdownLoader,
            statesDropdownLoader,
            handleCountrySelection,
            selectedState,
            stateSelectOptions,
            address,
            genderLoader,
            imageFile,
            schoolName,
            schoolNameError,
            schoolMsisdn,
            schoolContactError,
            regNumber,
            schoolEmail,
            schoolEmailError,
            schoolAddress,
            schoolAddressError,
            emailError,
            fullName,
            fullNameError,
            addressError,
            msisdnError
        }
    }
}
</script>
<style scoped>
.table-pagination {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.page-item.active .page-link {
    border-color: #2f5bcf;
    z-index: 3;
    color: #fff;
}

.page-item:first-child .page-link {
    margin-left: 0;
}

.pagination-rounded .page-link {
    border-radius: 30px !important;
    margin: 0 3px;
    border: none;
}

.page-link {
    position: relative;
    display: block;
    padding: 0.5rem 0.9rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #323a46;
    background-color: #fff;
    border: 1px solid #dee2e6;
}
</style>