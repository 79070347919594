<template>
    <!-- ========== Left Sidebar Start ========== -->
    <div class="left-side-menu" v-if="isMenuOpen">
        <div class="h-100" data-simplebar>
            <!--- Sidemenu -->
            <div id="sidebar-menu">
                <ul id="side-menu">
                    <li class="menu-title mt-2">MENU</li>

                    <li>
                        <router-link :to="{ name: 'Dashboard' }" :class="{ 'active': route.name === 'Dashboard' }">
                            <i data-feather="calendar"></i>
                            <span> Dashboard </span>
                        </router-link>

                    </li>

                    <li class="menu-title mt-2" v-if="hasPermission('view_school')
                        || hasPermission('view_students')
                        || hasPermission('view_parents')
                        || hasPermission('view_teacher')">
                        ACCOUNTS
                    </li>

                    <li v-if="hasPermission('view_school')">
                        <router-link :to="{ name: 'SchoolsList' }"
                            :class="{ 'active': route.name === 'SchoolsList' || route.name === 'ShowSchool' }">
                            <i data-feather="home"></i>
                            <span> Schools </span>
                        </router-link>
                    </li>

                    <li v-if="hasPermission('view_parents')">
                        <router-link :to="{ name: 'ParentsList' }"
                            :class="{ 'active': route.name === 'ParentsList' || route.name === 'ShowParent' }">
                            <i data-feather="users"></i>
                            <span> Parents </span>
                        </router-link>
                    </li>

                    <li v-if="hasPermission('view_students')">
                        <router-link :to="{ name: 'StudentsList' }"
                            :class="{ 'active': route.name === 'StudentsList' || route.name === 'ShowStudent' }">
                            <i data-feather="users"></i>
                            <span> Students </span>
                        </router-link>
                    </li>

                    <li v-if="hasPermission('view_teacher')">
                        <router-link :to="{ name: 'ColearnersList' }"
                            :class="{ 'active': route.name === 'ColearnersList' || route.name === 'ShowColearner' }">
                            <i data-feather="book-open"></i>
                            <span> Co-learners </span>
                        </router-link>
                    </li>

                    <li class="menu-title mt-2" v-if="hasPermission('view_transactions')
                        || hasPermission('view_subscription')
                        || hasPermission('view_plans')">
                        FINANCIALS
                    </li>

                    <li v-if="hasPermission('view_transactions')">
                        <router-link :to="{ name: 'TransactionsList' }"
                            :class="{ 'active': route.name === 'TransactionsList' || route.name === 'ShowTransaction' }">
                            <i data-feather="dollar-sign"></i>
                            <span> Transactions </span>
                        </router-link>
                    </li>

                    <li v-if="hasPermission('view_subscription') || hasPermission('view_plans')" :class="{
                        'active': !isMembershipExpanded && (
                            route.name === 'SubscriptionList' ||
                            route.name === 'ShowSubscription' ||
                            route.name === 'PlansList' ||
                            route.name === 'ShowPlan'
                        )
                    }">
                        <a href="#sidebarBaseui" data-toggle="collapse" @click="toggleMembership">
                            <i data-feather="dollar-sign"></i>
                            <span> Membership </span>
                            <span class="menu-arrow"></span>
                        </a>
                        <div class="collapse" id="sidebarBaseui" @show.bs.collapse="isMembershipExpanded = true"
                            @hide.bs.collapse="isMembershipExpanded = false">
                            <ul class="nav-second-level">
                                <li v-if="hasPermission('view_subscription')">
                                    <router-link :to="{ name: 'SubscriptionList' }"
                                        :class="{ 'active-item': route.name === 'SubscriptionList' || route.name === 'ShowSubscription' }">
                                        <span> Subscriptions </span>
                                    </router-link>
                                </li>
                                <li v-if="hasPermission('view_subscription')">
                                    <router-link :to="{ name: 'PlansList' }"
                                        :class="{ 'active-item': route.name === 'PlansList' || route.name === 'ShowPlan' }">
                                        <span> Plans </span>
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                    </li>

                    <li class="menu-title mt-2" v-if="hasPermission('view_system_admin')
                        || hasPermission('view_roles')
                        || hasPermission('view_permissions')
                        || hasPermission('view_reports')
                        || hasPermission('view_audits')
                        || hasPermission('view_keywords')
                        || hasPermission('view_keywords')">
                        ORGANIZATION
                    </li>

                    <li v-if="hasPermission('view_roles')
                        || hasPermission('view_permissions')
                        || hasPermission('add_system_admin')" :class="{
                            'active': !isAccessExpanded && (
                                route.name === 'RolesList' ||
                                route.name === 'ShowRoles' ||
                                route.name === 'PermissionsList' ||
                                route.name === 'UsersIndex' ||
                                route.name === 'ShowUser'
                            )
                        }">
                        <a href="#accessList" data-toggle="collapse" @click="toggleAccess">
                            <i data-feather="list"></i>
                            <span> Access </span>
                            <span class="menu-arrow"></span>
                        </a>
                        <div class="collapse" id="accessList" @show.bs.collapse="isAccessExpanded = true"
                            @hide.bs.collapse="isAccessExpanded = false">
                            <ul class="nav-second-level">
                                <li v-if="hasPermission('view_roles')">
                                    <router-link :to="{ name: 'RolesList' }"
                                        :class="{ 'active-item': route.name === 'RolesList' || route.name === 'ShowRoles' }">
                                        <span> Roles </span>
                                    </router-link>
                                </li>
                                <li v-if="hasPermission('view_permissions')">
                                    <router-link :to="{ name: 'PermissionsList' }"
                                        :class="{ 'active-item': route.name === 'PermissionsList' }">
                                        <span> Permissions </span>
                                    </router-link>
                                </li>
                                <li v-if="hasPermission('view_system_admin')">
                                    <router-link :to="{ name: 'UsersIndex' }"
                                        :class="{ 'active-item': route.name === 'UsersIndex' || route.name === 'ShowUser' }">
                                        <span> Users </span>
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                    </li>


                    <li v-if="hasPermission('view_reports')">
                        <a href="#">
                            <i data-feather="calendar"></i>
                            <span> Reports </span>
                        </a>
                    </li>

                    <li v-if="hasPermission('view_audits')">
                        <router-link :to="{ name: 'AuditLogList' }"
                            :class="{ 'active': route.name === 'AuditLogList' || route.name === 'ShowAuditLog' }">
                            <i data-feather="list"></i>
                            <span> Audit Log </span>
                        </router-link>
                    </li>

                    <li v-if="hasPermission('view_keywords')
                        || hasPermission('view_checklist')" :class="{
                            'active': !isBaseRecordsExpanded && (
                                route.name === 'KeywordsList' ||
                                route.name === 'ChecklistList' ||
                                route.name === 'CurriculumList'
                            )
                        }">
                        <a href="#baseRecords" data-toggle="collapse" @click="toggleBaseRecords">
                            <i data-feather="list"></i>
                            <span> Base Records </span>
                            <span class="menu-arrow"></span>
                        </a>
                        <div class="collapse" id="baseRecords" @show.bs.collapse="isBaseRecordsExpanded = true"
                            @hide.bs.collapse="isBaseRecordsExpanded = false">
                            <ul class="nav-second-level">
                                <li v-if="hasPermission('view_keywords')">
                                    <router-link :to="{ name: 'KeywordsList' }"
                                        :class="{ 'active-item': route.name === 'KeywordsList' }">
                                        <span> Project Keywords </span>
                                    </router-link>
                                </li>
                                <li v-if="hasPermission('view_checklist')">
                                    <router-link :to="{ name: 'ChecklistList' }"
                                        :class="{ 'active-item': route.name === 'ChecklistList' }">
                                        <span> Project Checklist </span>
                                    </router-link>
                                </li>
                                <li v-if="hasPermission('view_checklist')">
                                    <router-link :to="{ name: 'CurriculumList' }"
                                        :class="{ 'active-item': route.name === 'CurriculumList' }">
                                        <span> Project Curriculums </span>
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                    </li>

                </ul>
            </div>
            <!-- End Sidebar -->

            <div class="clearfix"></div>
        </div>
        <!-- Sidebar -left -->
    </div>
    <!-- Left Sidebar End -->
</template>

<script>
import { useRoute } from 'vue-router';
import { ref, watch } from "vue";
import { useUserStore } from "@/stores/user";
import { hasPermission } from "@/utils/helpers";

export default {
    props: {
        isMobileMenuOpen: Boolean
    },
    setup(props) {
        const route = useRoute()
        const isMenuOpen = ref(props.isMobileMenuOpen);

        watch(() => props.isMobileMenuOpen, (newValue) => {
            isMenuOpen.value = newValue

            if (isMenuOpen.value) {
                setTimeout(() => {
                    feather.replace();
                }, 100)
            }
        })

        setTimeout(() => {
            feather.replace();
        }, 100)

        const userStore = useUserStore();
        const user = ref(userStore.user)
        const userPermissions = ref(user.value.permissions)
        const checkPermission = (permission) => hasPermission(userPermissions, permission)

        const isAccessExpanded = ref(false);
        const isMembershipExpanded = ref(false);
        const isBaseRecordsExpanded = ref(false);

        const toggleAccess = () => {
            // Let Bootstrap handle the actual toggling
            // The state will be updated via the show.bs.collapse and hide.bs.collapse events
        };

        const toggleMembership = () => {
            // Let Bootstrap handle the actual toggling
        };

        const toggleBaseRecords = () => {
            // Let Bootstrap handle the actual toggling
        };

        return {
            route,
            isMenuOpen,
            hasPermission: checkPermission,
            isAccessExpanded,
            toggleAccess,
            isMembershipExpanded,
            toggleMembership,
            isBaseRecordsExpanded,
            toggleBaseRecords
        }
    }
};
</script>

<style scoped>
.left-side-menu {
    width: 240px;
    background: var(--black) !important;
    bottom: 0;
    padding: 20px 0;
    position: fixed;
    transition: all .1s ease-out;
    top: 70px;
    /* box-shadow: 0 0 35px 0 var(--shadow-medium); */
}

#sidebar-menu>ul>li>a {
    color: #ffffff;
    display: block;
    padding: 12px 20px;
    position: relative;
    transition: all .4s;
    font-family: "Inter";
    font-size: .95rem;
}

#sidebar-menu>ul>li>a:active,
#sidebar-menu>ul>li>a:focus,
#sidebar-menu>ul>li>a:hover {
    color: rgba(255, 255, 255, 0.8);
    text-decoration: none;
    /* background: rgba(255, 255, 255, 0.1); */
}

.menu-title {
    color: rgba(255, 255, 255, 0.7) !important;
    padding: 10px 20px;
    letter-spacing: .05em;
    pointer-events: none;
    cursor: default;
    font-size: .6875rem;
    text-transform: uppercase;
    font-weight: 600;
}

.active {
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.2) !important;
    color: #fff !important;
    font-weight: 500;
}

.active-item {
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.2) !important;
    color: #fff !important;
    font-weight: 500;
    margin: 0 5px;
    padding: 8px 15px !important;
    display: block;
}

.nav-second-level li a {
    padding: 8px 20px;
    color: rgba(255, 255, 255, 0.7);
    display: block;
    position: relative;
    transition: all .4s;
    font-size: 0.9rem;
}

.nav-second-level li a:hover {
    color: rgba(255, 255, 255, 0.9);
    text-decoration: none;
}

@media (max-width: 991px) {
    .left-side-menu {
        display: block !important;
    }
}
</style>