<template>
    <div id="wrapper">
        <MessageSliderCopy :isVisible="displayMessage" :messageText="messageText" :messageType="messageType" />

        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 mx-auto">

                <div class="login-container d-flex align-items-center justify-content-center">
                    <div class="row auth-card-box">
                        <InfiniteLinearLoader :loading="loading" />

                        <div class="image col-lg-6 col-md-6 col-sm-12 p-0">
                            <img src="../../assets/images/login_logo_new.jpeg" alt="login" />
                        </div>

                        <div class="form-content col-lg-6 col-md-6 col-sm-12">
                            <img src="../../assets/key-logo.png" alt="logo" />
                            <p>Welcome back! Log in to your account.</p>

                            <div class="row mb-20">
                                <div class="col-12">
                                    <TextInputField :config="{
                                        class: 'form-control-gray',
                                        placeholder: 'Enter your email address',
                                        inputFieldType: 'email'
                                    }" labelText="Email" :errorMessage="emailError" v-model="email" />
                                </div>
                            </div>

                            <div class="row mb-20">
                                <div class="col-12">
                                    <TextInputField :config="{
                                        class: 'form-control-gray',
                                        placeholder: 'Enter your Password',
                                        inputFieldType: 'password'
                                    }" labelText="Password" :errorMessage="passwordError" v-model="password" />
                                </div>
                            </div>

                            <CheckBoxVue checkboxText="Remember me" class="checkboxVue" v-model="rememberMe" />

                            <p class="error font-italic" v-if="errorMessage">
                                {{ errorMessage }}
                            </p>

                            <RegularButtonVue buttonText="Login" class="custom-button mb-3" @click="handleFormSubmit"
                                :loading="loading" />

                            <p class="forgot-text">
                                Forgot Password? <span><a href="#" @click="navigateToPasswordEmailRequest">Click to
                                        reset your password</a></span>
                            </p>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>
</template>

<script>
import TextInputField from "@/components/common/TextInputFieldNew.vue";
import PasswordInputFieldVue from "@/components/common/PasswordInputField.vue";
import CheckBoxVue from "@/components/common/CheckBox.vue";
import RegularButtonVue from "@/components/common/RegularButton.vue";
import InfiniteLinearLoader from "@/components/common/InfiniteLinearLoader.vue";
import MessageSliderCopy from "@/components/common/MessageSliderCopy.vue";
import { ref, watch } from "vue";
import { useRouter } from "vue-router";
import { flashMessage } from "@/utils/flashMessage.js";
import { useUserStore } from "@/stores/user";
import { getValidRefreshToken, getValidToken } from "@/utils/helpers";
import { API_BASE_URL } from "@/config";
import { useToast } from "@/composables/useToast";
export default {
    components: {
        TextInputField,
        PasswordInputFieldVue,
        CheckBoxVue,
        RegularButtonVue,
        InfiniteLinearLoader,
        MessageSliderCopy,
    },
    setup() {
        const email = ref("");
        const password = ref("");
        const rememberMe = ref(false);

        const loading = ref(false);
        const loadingSpinner = ref(false);
        const router = useRouter();
        const toast = useToast();

        const displayMessage = ref(false);
        const messageText = ref("");
        const messageType = ref("");

        const accessToken = ref(getValidToken());
        const refreshToken = ref(getValidRefreshToken());
        const currentUserData = ref(null)

        const emailError = ref('')
        const passwordError = ref('')
        const errorMessage = ref('')

        const showMessage = (text, type) => {
            flashMessage(text, type, messageText, messageType, displayMessage);
        };

        const showLoaders = () => {
            loading.value = true;
            loadingSpinner.value = true;
        };

        const hideLoaders = () => {
            loading.value = false;
            loadingSpinner.value = false;
        };

        const validateForm = () => {
            let isValid = true

            emailError.value = ''
            passwordError.value = ''

            if (email.value.length === 0) {
                emailError.value = 'Enter an email'
                isValid = false
            }

            if (password.value.length === 0) {
                passwordError.value = 'Enter a password'
                isValid = false
            }

            return isValid
        }

        const handleFormSubmit = async () => {
            errorMessage.value = ''

            if (!validateForm()) {
                return;
            }

            try {
                showLoaders();
                const apiURL = `${API_BASE_URL}/auth/login`
                const loginData = {
                    email: email.value,
                    password: password.value,
                };

                const response = await fetch(apiURL, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(loginData),
                });

                const result = await response.json();

                if (!response.ok) {
                    throw new Error(result.message || 'Login failed');
                }

                if (result.code !== 200) {
                    throw new Error(result.message || 'Login failed');
                }

                // Clear any existing data
                localStorage.clear();

                // Set cookies
                const cookieOptions = '; path=/';
                document.cookie = `uid=${result.data.access_token}${cookieOptions}`;
                document.cookie = `refresh_token=${result.data.refresh_token}${cookieOptions}`;

                if (result.data.school_id) {
                    document.cookie = `schoolId=${result.data.school_id}${cookieOptions}`;
                }

                // Prepare user data
                currentUserData.value = {
                    role_name: result.data.role_name,
                    user_id: result.data.user_id,
                    first_name: result.data.first_name,
                    last_name: result.data.last_name,
                    country: result.data.country,
                    state: result.data.state,
                    residence: result.data.residence,
                    gender: result.data.gender,
                    school_id: result.data.school_id,
                    name: result.data.name,
                    permissions: result.data.permissions,
                    userType: result.data.user_type
                };

                // Check if the user is a parent or student - they should use the mobile app
                if (result.data.user_type === 'parent') {
                    hideLoaders();
                    toast.error('Hi there! As a parent, you\'ll need to use the mobile app to access your account. This web portal is specifically for school administrators.', 7000);
                    return;
                }
                
                if (result.data.user_type === 'student') {
                    hideLoaders();
                    toast.error('Welcome! Students should access their account through the mobile app. This web portal is designed for school administrators.', 7000);
                    return;
                }

                // Update local storage and store
                localStorage.setItem('current_user', JSON.stringify(currentUserData.value));
                const userStore = useUserStore();
                userStore.setUserDetails(currentUserData.value);

                // Update tokens
                accessToken.value = result.data.access_token;
                refreshToken.value = result.data.refresh_token;

                // Show success message
                toast.success('Login successful! Redirecting...', 3000);

                // Navigate based on user type
                const targetRoute = result.data.school_id ? 'SchoolDashboard' : 'Dashboard';
                await router.push({ name: targetRoute });

            } catch (error) {
                console.error('Login error:', error);

                // Handle server error messages
                let errorMsg = 'An error occurred while logging in. Please try again.';

                if (error.message) {
                    // Check for specific credential error
                    if (error.message.includes('username and/or password is incorrect')) {
                        errorMsg = 'Invalid credentials. Please check your email and password.';
                    } else {
                        // Use server's error message if available, but clean it up
                        errorMsg = error.message
                            .replace('Error:', '')
                            .trim();
                    }
                }

                toast.error(errorMsg);
            } finally {
                hideLoaders();
            }
        };

        // Watch the accessToken for changes and check for token expiry
        watch(accessToken, (newAccessToken) => {
            if (newAccessToken) {
                // Assuming your tokens are JWT tokens with an 'exp' claim
                const jwtToken = parseJwt(newAccessToken);
                const expirationTimestamp = jwtToken.exp * 1000; // Convert to milliseconds

                if (Date.now() >= expirationTimestamp) {
                    // Access token has expired, Navigate to dashboard page
                    router.push({ name: "Dashboard" });
                }
            }
        });

        const parseJwt = (token) => {
            try {
                return JSON.parse(atob(token.split(".")[1]));
            } catch (e) {
                return {};
            }
        };

        const navigateToPasswordEmailRequest = () => {
            router.push({ name: "PasswordRequest" })
        }

        return {
            navigateToPasswordEmailRequest,
            email,
            password,
            rememberMe,
            handleFormSubmit,
            loading,
            showMessage,
            displayMessage,
            messageText,
            messageType,
            emailError,
            passwordError,
            errorMessage
        };
    },
};
</script>

<style scoped>
@media (min-width: 1200px) {
    .auth-card-box {
        height: 550px;
        box-shadow: 0 0 15px 0 rgba(184, 188, 202, 0.5);
        position: relative;
    }

    .login-container {
        height: 100vh
    }
}

@media (min-width: 768px) and (max-width: 1199px) {
    .auth-card-box {
        height: 550px;
        box-shadow: 0 0 15px 0 rgba(184, 188, 202, 0.5);
        position: relative;
    }

    .login-container {
        height: 100vh
    }
}

.image {
    height: 100%;
}

.image>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.form-content {
    height: 100%;
    width: 60%;
    display: inline-block;
    position: inherit;
    top: 0;
    padding: 50px 45px;
}

.form-content>img {
    height: 50px;
    width: 80px;
    margin-bottom: 10px;
}

.form-content>p {
    margin-bottom: 30px;
}

.form-content>.forgot-text {
    font-size: 14px;
    color: #333;
}

.form-content>.forgot-text span,
.error {
    color: #f75549;
}

.checkboxVue {
    margin-bottom: 20px;
}
</style>