<template>
    <div class="row">
        <div class="col-lg-3 col-md-3 col-sm-12">
            <div class="card-box-small">
                <div class="card-content">
                    <div class="header-content text-center">
                        <div v-if="schoolDetails">
                            <img class="mb-20 d-block mx-auto rounded-circle img-thumbnail" :src="schoolDetails.logo"
                                alt="school logo" v-if="schoolDetails.logo" @error="handleSchoolImageError" />

                            <AvatarForInitials :avatarText="getUserInitialsForName(schoolName)" iconColor="info"
                                class="avatar-md-150 mb-30 m-auto" v-else />
                        </div>

                        <h4 class="mb-0">{{ schoolName }}</h4>
                        <p class="mb-0">{{ schoolEmail }}</p>
                    </div>

                    <div class="middle-content mb-100">
                        <div class="details-content">
                            <img src="../../../assets/images/icons/user_icon.svg" class="data-img" alt="Mobile Icon" />

                            <div class="detail-content-group">
                                <p class="data-title">Total Administrators</p>
                                <p class="data-text">{{ numOfAdmins }}</p>
                            </div>
                        </div>

                        <div class="details-content">
                            <img src="../../../assets/images/icons/activate_icon.svg" class="data-img"
                                alt="Mobile Icon" />

                            <div class="detail-content-group">
                                <p class="data-title">Active</p>
                                <p class="data-text">{{ numOfActiveAdmins }}</p>
                            </div>
                        </div>

                        <div class="details-content">
                            <img src="../../../assets/images/icons/deactivate_icon2.svg" class="data-img"
                                alt="Mobile Icon" />

                            <div class="detail-content-group">
                                <p class="data-title">Deactivated</p>
                                <p class="data-text">{{ numOfInactiveAdmins }}</p>
                            </div>
                        </div>
                    </div>

                    <div class="buttons-content">
                        <RegularButtonBlock buttonText="Add Account Admin" iconText="mdi-plus"
                            v-if="hasPermission('add_system_admin')" class="custom-button mb-3"
                            @clicked="openFormModal" />
                    </div>
                </div>
            </div>
        </div>

        <div class="col-lg-9 col-md-3 col-sm-12">
            <div class="card-box">
                <div class="row">
                    <div class="col-12">
                        <h4 class="bold mb-30">Administrators</h4>

                        <TableView :data="formattedSchoolUsersData" :headers="tableHeaders" :id="getIdForActions"
                            :detailsData="getDataForDetailsView" :loading="loading"
                            :searchStringFromParent.sync="searchStringFromChild" :viewAction="viewAction"
                            :editAction="editAction"
                            :showActionsColumn="hasPermission('view_school_manager') || hasPermission('modify_school_manager') || hasPermission('deactivate_school_manager')"
                            :deactivateAction="openConfirmationDialog" :searchFields="searchFieldsData"
                            :totalPages="totalPages" :currentPage.sync="currentPage" :changePage="changePage"
                            :changeItemsPerPage="changeItemsPerPage" :itemsPerPage="itemsPerPage"
                            viewPermission="view_school_manager" editPermission="modify_school_manager"
                            deactivatePermission="deactivate_school_manager" />
                    </div>
                </div>
            </div>
        </div>


    </div>


    <FormModal :isFormModalOpen="isFormModalOpen" @closeFormModal="closeFormModal1" modalHeader="Add Account Admin">
        <CreateSchoolUser @closeFormModal="closeFormModal" @hasError="showErrorAlert" />
    </FormModal>

    <FormModal :isFormModalOpen="isEditFormModalOpen" @closeFormModal="closeEditFormModal"
        modalHeader="Edit Account Admin">
        <EditSchoolUser @closeFormModal="closeEditFormModal" :itemData="itemDataForEditing" />
    </FormModal>

    <InfoModal :isModalOpen="isConfirmationDialogOpen" @closeModal="closeConfirmationDialog"
        :successImageURL="require('../../../assets/images/icons/success_icon.svg')"
        :errorImageURL="require('../../../assets/images/icons/error_icon.svg')" header="Hold on!"
        :paragraph="deactivateConfirmationParagraph" :buttonTxt="deactivateButtonText" :buttonAction="performAction"
        buttonColor="error" routerLinkText="No, Cancel Action" buttonLink="/" :linkAction="cancelAction"
        :loading="buttonLoader">
    </InfoModal>

    <InfoModal :isModalOpen="isFlashModalOpen" @closeModal="closeFlashModal" :isSuccess="true" header="Admin Added"
        :paragraph="`You have successfully added '${fullName}'. You can view and manage this admin's profile under 'Account Admins'.`"
        buttonTxt="Add Another Admin" routerLinkText="Manage Account Admins" :linkAction="createItemFlashLinkAction"
        :buttonAction="buttonAction">
    </InfoModal>

    <MessageSliderCopy :isVisible="displayMessage" :messageText="messageText" :messageType="messageType"/>

</template>

<script>
import RegularButtonBlock from "@/components/common/RegularButtonBlock.vue";
import { computed, onActivated, onMounted, ref, watch } from "vue";
import { capitalizeFirstLetter, getSchoolId, getUserInitialsForName, getValidToken, hasPermission } from '@/utils/helpers'
import { checkAndRefreshToken } from '@/utils/auth';
import { API_BASE_URL } from "@/config.js";
import FormModal from "@/components/common/FormModal.vue";
import { useRouter } from "vue-router";
import TableView from "@/components/common/TableView.vue";
import RoundedButton from "@/components/common/RoundedButton.vue";
import InfoModal from "@/components/common/InfoModals.vue";
import EditSchoolUser from "@/views/School_Admin/Users/EditSchoolUser.vue";
import CreateSchoolUser from "@/views/School_Admin/Users/CreateSchoolUser.vue";
import getSchoolAdmins from "@/composables/Schools/getSchoolAdmins";
import AvatarForInitials from "@/components/common/AvatarForInitials.vue";
import { useUserStore } from "@/stores/user";
import MessageSliderCopy from "@/components/common/MessageSliderCopy.vue";

export default {
    methods: { getUserInitialsForName },
    components: {
        AvatarForInitials,
        CreateSchoolUser,
        EditSchoolUser,
        RoundedButton,
        TableView,
        FormModal,
        InfoModal,
        RegularButtonBlock,
        MessageSliderCopy
    },
    props: {
        id: String
    },
    emits: ['showLoader', 'flash-message', 'data-loaded'],
    setup(props, { emit }) {
        document.title = "Manage Admin Users"
        const { usersPayloadData, fetchUsersData, loading, formattedSchoolUsersData, usersData } = getSchoolAdmins()
        const isFormModalOpen = ref(false);
        const isEditFormModalOpen = ref(false);
        const displayMessage = ref(false);
        const buttonLoader = ref(false);
        const messageText = ref("");
        const messageType = ref("");
        const newMessage = ref("");
        const showDetailsOrIcons = ref(false)
        const router = useRouter()
        const accessToken = getValidToken();
        const isConfirmationDialogOpen = ref(false);
        const isFlashModalOpen = ref(false);
        const fullName = ref('')
        const currentPage = ref(1);
        const itemsPerPage = ref(10);
        const totalPages = ref(0)
        const itemData = ref(null)
        const numOfAdmins = ref(0)
        const numOfActiveAdmins = ref(0)
        const numOfInactiveAdmins = ref(0)
        const schoolName = ref('')
        const schoolEmail = ref('')
        const schoolDetails = ref(null)


        const buttonAction = () => {
            isFlashModalOpen.value = false;
            openFormModal()
        }

        const openFlashModal = () => {
            isFlashModalOpen.value = true;
        };

        const closeFlashModal = async () => {
            isFlashModalOpen.value = false;
            await fetchUsersData(currentPage.value, itemsPerPage.value)
        };

        const createItemFlashLinkAction = () => {
            closeFlashModal()
        }

        const openFormModal = () => {
            isFormModalOpen.value = true;
        };

        const closeFormModal = (name) => {
            isFormModalOpen.value = false;

            fullName.value = name
            isFlashModalOpen.value = true
        };

        const closeFormModal1 = () => {
            isFormModalOpen.value = false;

            fetchUsersData(currentPage.value, itemsPerPage.value)
        }

        const showErrorAlert = (error) => {
            messageText.value = error
            messageType.value = 'error'
            displayMessage.value = true
            console.log('error', error)
        }

        const openEditFormModal = () => {
            isEditFormModalOpen.value = true;
        };

        const closeEditFormModal = async (msg) => {
            isEditFormModalOpen.value = false;

            if (msg) {
                flashAlertMessage(msg.type, msg.apiResponse)
            }
            await fetchUsersData(currentPage.value, itemsPerPage.value)
        };

        const searchStringFromChild = ref("");
        const searchFieldsData = ['user.name', 'user.email', 'status', 'gender', 'contact']

        watch(usersData, () => {
            totalPages.value = usersPayloadData.value.total_pages

            const stats = usersPayloadData.value.results
            numOfAdmins.value = stats.num_of_admins
            numOfActiveAdmins.value = stats.num_of_active_admins
            numOfInactiveAdmins.value = stats.num_of_deactivated_admins
        })

        onMounted(() => {
            fetchUsersData(currentPage.value, itemsPerPage.value);
            emit('data-loaded')

            schoolDetails.value = JSON.parse(localStorage.getItem('schoolDetails'))
            schoolName.value = schoolDetails.value.name
            schoolEmail.value = schoolDetails.value.email
        })

        onActivated(() => {
            fetchUsersData(currentPage.value, itemsPerPage.value);
        })

        const getIdForActions = computed(() => {
            return usersData.value.map((item) => item.user_id);
        });

        const getDataForDetailsView = computed(() => {
            return usersData.value
        })

        const viewAction = (item, id) => {
            router.push(
                {
                    name: 'ShowSchoolUser',
                    params: { id: id }
                })
        }

        const itemDataForEditing = ref(null)
        const editAction = (item, item_id) => {
            itemData.value = item

            // Find the item to edit
            const itemForEditing = usersData.value.find((r) => r.id === item_id);
            itemDataForEditing.value = {
                name: itemForEditing.name,
                gender: itemForEditing._gender,
                role: itemForEditing.school_role_id,
                address: itemForEditing.residence,
                id: item_id
            }

            openEditFormModal()
        }

        const deactivateAction = async () => {
            const schoolId = getSchoolId()
            const apiUrl = `${API_BASE_URL}/school/${schoolId}/toggle-school-admin-status`

            try {
                await checkAndRefreshToken()

                buttonLoader.value = true
                const response = await fetch(apiUrl, {
                    method: 'PUT',
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${accessToken}`
                    },
                    body: JSON.stringify({
                        admin_id: itemId.value,
                        reason: 'Not needed anymore'
                    })
                })

                if (response.ok) {
                    const result = await response.json()

                    if (result.code === 200) {
                        closeConfirmationDialog()

                        flashAlertMessage('success', "Admin status updated")
                    } else {
                        flashAlertMessage('error', result.message)
                        console.log('error', result.message)
                    }
                } else {
                    const result = await response.json();

                    flashAlertMessage('error', result.message)
                    console.log("error", result.message);
                }
            } catch (error) {
                const result = await response.json();

                flashAlertMessage('error', result.message)
                console.error("An error occured:", error);
            } finally {
                closeConfirmationDialog()
                buttonLoader.value = false
            }
        }

        const messageFromChild = ref("");
        const handleMessageFromChild = (message) => {
            messageFromChild.value = message
        };

        const tableHeaders = computed(() => {
            return [
                { label: "Name", key: "name" },
                { label: "Contact No", key: "contact" },
                { label: "Role", key: "schroleool" },
                { label: "Added On", key: "date" },
                { label: "Acct Status", key: "status" }
            ];
        });

        const itemId = ref('null')
        const itemStatus = ref('')
        const deactivateConfirmationParagraph = ref('')
        const deactivateButtonText = ref('')

        const openConfirmationDialog = (item, id) => {
            itemId.value = id

            itemStatus.value = item.isDeactivated === false ? 'deactivate' : 'activate'
            deactivateConfirmationParagraph.value = `You are about to ${itemStatus.value} this admin's account. Are you sure you want to continue this action?.`
            deactivateButtonText.value = `Yes, ${capitalizeFirstLetter(itemStatus.value)} Admin`
            isConfirmationDialogOpen.value = true;
        };

        const closeConfirmationDialog = () => {
            isConfirmationDialogOpen.value = false;
            fetchUsersData(currentPage.value, itemsPerPage.value)
        };

        const performAction = () => {
            deactivateAction(itemId.value)
        }

        const cancelAction = () => {
            closeConfirmationDialog()
        }

        const changePage = (page) => {
            if (page >= 1 && page <= totalPages.value) {
                currentPage.value = page;

                fetchUsersData(currentPage.value, itemsPerPage.value)
            }
        };

        const changeItemsPerPage = (perPage) => {
            itemsPerPage.value = perPage;
            currentPage.value = 1; // Reset to the first page when changing items per page

            fetchUsersData(currentPage.value, perPage);
        };

        const flashAlertMessage = (alertType, alertMessage) => {
            emit('flash-message', {
                alertType: alertType,
                alertMessage: alertMessage
            })
        }

        const userStore = useUserStore();
        const user = ref(userStore.user)
        const userPermissions = ref(user.value.permissions)
        const checkPermission = (permission) => hasPermission(userPermissions, permission)

        const handleSchoolImageError = () => {
            schoolDetails.value.logo = null
        }

        return {
            handleSchoolImageError,
            tableHeaders,
            usersData,
            totalPages,
            formattedSchoolUsersData,
            getIdForActions,
            loading,
            searchStringFromChild,
            searchFieldsData,
            isFormModalOpen,
            openFormModal,
            closeFormModal,
            closeFormModal1,
            handleMessageFromChild,
            displayMessage,
            itemDataForEditing,
            messageText,
            messageType,
            newMessage,
            showDetailsOrIcons,
            viewAction,
            editAction,
            deactivateAction,
            InfoModal,
            isConfirmationDialogOpen,
            openConfirmationDialog,
            closeConfirmationDialog,
            performAction,
            cancelAction,
            buttonLoader,
            isEditFormModalOpen,
            openEditFormModal,
            closeEditFormModal,
            itemData,
            isFlashModalOpen,
            openFlashModal,
            closeFlashModal,
            buttonAction,
            fullName,
            createItemFlashLinkAction,
            deactivateConfirmationParagraph,
            deactivateButtonText,
            getDataForDetailsView,
            changePage,
            changeItemsPerPage,
            currentPage,
            itemsPerPage,
            numOfAdmins,
            numOfActiveAdmins,
            numOfInactiveAdmins,
            schoolName,
            schoolEmail,
            hasPermission: checkPermission,
            schoolDetails,
            showErrorAlert
        };

    }

}
</script>

<style scoped>
.data-text {
    color: #0D44B2;
}

.detail-content-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.detail-content-group>p {
    margin: 0;
}

.data-img {
    margin-top: 2px !important;
}

.header-content {
    padding: 30px 0 20px 0;
    position: relative;
    border-bottom: 1px solid #e9eaf3;
}

p {
    color: #9a9a9a;
    font-size: 16px;
}

.details-content {
    width: 100%;
    display: flex;
    align-items: flex-start;
    padding: 30px 30px 0 30px;
}

.details-content:last-child {
    padding-bottom: 30px;
    border-bottom: 1px solid #e9eaf3;
}

.details-content>img {
    margin-right: 15px;
    margin-top: 5px;
}

.details-content .details>p {
    margin-bottom: 0;
}

.logo-text>img {
    margin-right: 15px;
    margin-top: 2px;
}

.logo-text>p {
    color: #333333;
}

.detail-group>p,
.data-title {
    color: #333;
}


.buttons-content {
    padding: 0 15px 40px 15px;
}

.card-box-small {
    padding: 0 15px 0 15px;
    background-color: #fff;
    box-shadow: 0 0.75rem 6rem rgba(56, 65, 74, .03);
    margin-bottom: 24px;
    border-radius: 12px !important;
}

.rounded-circle {
    height: 150px !important;
    width: 150px !important;
    object-fit: contain;
}
</style>