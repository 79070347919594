import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia } from "pinia";
import SweetAlertPlugin from "@/plugins/sweetalert";
import Select2Plugin from "@/plugins/select2";

// Import global styles
import '@/assets/styles/variables.css'

const app = createApp(App);

const pinia = createPinia()
app.use(pinia)
app.use(SweetAlertPlugin)
app.use(Select2Plugin)

app.use(router).mount('#app');
