import { ref } from 'vue'

// Using refs at composable level makes them shared/global
const visible = ref(false)
const message = ref('')
const type = ref('info')
const duration = ref(5000)
let autoHideTimeout = null

// Validate if a message is valid to display
const isValidMessage = (msg) => {
    return msg && typeof msg === 'string' && msg.length > 0
}

export function useToast() {
    // Reset toast state immediately
    const resetToast = () => {
        message.value = ''
        type.value = 'info'
        duration.value = 5000
    }

    // Hide the toast
    const hideToast = () => {
        visible.value = false

        // Clear any existing timeout
        if (autoHideTimeout) {
            clearTimeout(autoHideTimeout)
            autoHideTimeout = null
        }

        // Reset toast after animation completes
        setTimeout(resetToast, 300)
    }

    // Show a toast with the given configuration
    const showToast = (config) => {
        // Don't show toast for invalid messages
        if (!config || !isValidMessage(config.message)) return

        // Clear any existing timeout
        if (autoHideTimeout) {
            clearTimeout(autoHideTimeout)
            autoHideTimeout = null
        }

        // If a toast is already visible, hide it first then show the new one
        if (visible.value) {
            hideToast()

            // Wait for animation to complete before showing the new toast
            setTimeout(() => {
                message.value = config.message
                type.value = config.type || 'info'
                duration.value = config.duration || duration.value
                visible.value = true

                // Auto hide after duration
                autoHideTimeout = setTimeout(hideToast, duration.value)
            }, 300)
        } else {
            // Show the toast immediately if none is visible
            message.value = config.message
            type.value = config.type || 'info'
            duration.value = config.duration || duration.value
            visible.value = true

            // Auto hide after duration
            autoHideTimeout = setTimeout(hideToast, duration.value)
        }
    }

    // Helper methods for different toast types
    const success = (msg, duration) => {
        if (!isValidMessage(msg)) return
        showToast({ message: msg, type: 'success', duration })
    }

    const error = (msg, duration) => {
        if (!isValidMessage(msg)) return
        showToast({ message: msg, type: 'error', duration })
    }

    const warning = (msg, duration) => {
        if (!isValidMessage(msg)) return
        showToast({ message: msg, type: 'warning', duration })
    }

    const info = (msg, duration) => {
        if (!isValidMessage(msg)) return
        showToast({ message: msg, type: 'info', duration })
    }

    // Initialize with reset state
    resetToast()

    return {
        message,
        type,
        duration,
        visible,
        showToast,
        hideToast,
        success,
        error,
        warning,
        info
    }
} 