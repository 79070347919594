<template>
    <div class="form-content">
        <form>
            <div class="col-12">
                <div class="row mb-30">
                    <div class="col-12">
                        <TextInputFieldVue :config="{
                            class: 'form-control-gray',
                            placeholder: 'Enter Activity Name',
                            inputFieldType: 'text',
                        }" labelText="Name" v-model="activity_name" :isRequired="true" />
                    </div>
                </div>

                <div class="row mb-30">
                    <div class="col-lg-6">
                        <TextInputFieldVue :config="{
                            class: 'form-control-gray',
                            placeholder: 'Select Start Date',
                            inputFieldType: 'date',
                        }" labelText="Start Date" v-model="start_date" :isRequired="true" />
                    </div>

                    <div class="col-lg-6">
                        <TextInputFieldVue :config="{
                            class: 'form-control-gray',
                            placeholder: 'Select End Date',
                            inputFieldType: 'date',
                        }" labelText="End Date" v-model="end_date" :isRequired="true" />
                    </div>
                </div>

                <div class="row mb-30">
                    <div class="col-12">
                        <TextAreaInputField :config="{
                            class: 'form-control-gray',
                            placeholder: 'Enter Description',
                            inputFieldType: 'text',
                        }" labelText="Description" v-model="activity_description"
                            :isRequired="true" />
                    </div>
                </div>

                <div class="row mb-30">
                    <div class="col-12">
                        <TextAreaInputField :config="{
                            class: 'form-control-gray',
                            placeholder: 'Enter Learning Objectives',
                            inputFieldType: 'text',
                        }" labelText="Learning Objectives" v-model="learning_objectives"
                            :isRequired="true" />
                    </div>
                </div>

                <div class="row mb-30">
                    <div class="col-12">
                        <TextAreaInputField :config="{
                            class: 'form-control-gray',
                            placeholder: 'Enter Resources',
                            inputFieldType: 'text',
                        }" labelText="Resources" v-model="resources" />
                    </div>
                </div>

                <div class="row mb-30">
                    <div class="col-12">
                        <TextInputFieldVue :config="{
                            class: 'form-control-gray',
                            placeholder: 'Weblinks',
                            inputFieldType: 'text',
                        }" labelText="Supporting Weblinks" v-model="weblink" />
                    </div>
                </div>

                <div class="row mb-30">
                    <div class="col-12">
                        <TextAreaInputField :config="{
                            class: 'form-control-gray',
                            placeholder: 'Enter Text',
                            inputFieldType: 'text',
                        }" labelText="Ways to Extend/Expand" v-model="ways_to_expand" />
                    </div>
                </div>
            </div>

            <RoundedButton buttonText="Save" class="custom-button" :loading="loading" @click="saveNewItem" />
        </form>
    </div>
</template>

<script>
import TextInputFieldVue from "@/components/common/TextInputField.vue";
import TextAreaInputField from "@/components/common/TextAreaInputField.vue";
import RoundedButton from "@/components/common/RoundedButton.vue";
import { ref } from "vue";
import { calculateWorkingDays, getSchoolId, getValidToken } from "@/utils/helpers";
import SimpleDropdown from "@/components/common/SimpleDropdown.vue";
import { checkAndRefreshToken } from "@/utils/auth";
import { API_BASE_URL } from "@/config";
import SelectDropdown from "@/components/common/SelectDropdown.vue";
import MultipleSelectDropdown from "@/components/common/MultipleSelectDropdown.vue";
import RegularButtonBlock from "@/components/common/RegularButtonBlock.vue";
import MultipleSelectField from "@/components/common/MultipleSelectField.vue";

export default {
    props: {
        projectId: [Number, String],
        activities: Number,
        project_duration: String
    },
    components: {
        RegularButtonBlock,
        MultipleSelectField,
        SelectDropdown,
        SimpleDropdown,
        TextInputFieldVue,
        TextAreaInputField,
        RoundedButton,
    },
    setup(props, { emit }) {
        const loading = ref(false);
        const loadingSpinner = ref(false);
        const activity_name = ref("");
        const start_date = ref("");
        const end_date = ref("");
        const activity_description = ref("");
        const learning_objectives = ref("");
        const resources = ref("");
        const weblink = ref("");
        const ways_to_expand = ref("");

        const showLoaders = () => {
            loading.value = true;
            loadingSpinner.value = true;
        };

        const hideLoaders = () => {
            loading.value = false;
            loadingSpinner.value = false;
        };

        const saveNewItem = async () => {

            const newActivity = {
                project_id: props.projectId,
                name: activity_name.value,
                start_date: start_date.value,
                finish_date: end_date.value,
                description: activity_description.value,
                learning_objectives: learning_objectives.value,
                resources: resources.value,
                supporting_weblinks: weblink.value,
                supporting_media: '',
                ways_to_extend: ways_to_expand.value,
            }


            if (props.activities >= calculateWorkingDays(props.project_duration)) {
                const msg = {
                    apiResponse: "You have reached the maximum limit and cannot create more activities, delete an activity to proceed",
                    type: "error",
                }

                emit("closeFormModal", msg);
            } else {
                try {
                    loading.value = true
                    const schoolId = getSchoolId()
                    const apiUrl = `${API_BASE_URL}/school/${schoolId}/activity`
                    const accessToken = getValidToken()

                    await checkAndRefreshToken()

                    const response = await fetch(apiUrl, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${accessToken}`,
                        },
                        body: JSON.stringify(newActivity)
                    });

                    if (response.ok) {
                        const result = await response.json();
                        if (result.code === 201) {
                            const msg = {
                                apiResponse: "Success, Activity Created",
                                type: "success",
                            }

                            emit('refresh_page')
                            emit("closeFormModal", msg);
                        } else {
                            console.log('Activity Creation Error', result.message)
                        }
                    } else {
                        const result = await response.json();
                        console.log("error", result.message);
                    }
                } catch (error) {
                    console.error("An error occurred:", error);
                } finally {
                    loading.value = false
                }
            }
        }


        return {
            loading,
            loadingSpinner,
            showLoaders,
            hideLoaders,
            activity_name,
            start_date,
            end_date,
            activity_description,
            learning_objectives,
            resources,
            weblink,
            ways_to_expand,
            saveNewItem
        };
    },
};
</script>

<style></style>