<template>
    <form @submit.prevent="updateAdminPassword">
        <div class="row">
            <div class="col-12 mb-20">
                <TextInputField :config="{
                    class: 'form-control-gray',
                    placeholder: 'Enter Password',
                    inputFieldType: 'password'
                }" labelText="Password" :errorMessage="passwordError" v-model="password" />
            </div>

            <div class="col-12 mb-20">
                <TextInputField :config="{
                    class: 'form-control-gray',
                    placeholder: 'Confirm Password',
                    inputFieldType: 'password'
                }" labelText="Confirm Password" :errorMessage="confirmPasswordError" v-model="confirmPassword" />
            </div>

            <p v-if="passwordMismatchError" class="error">{{ passwordMismatchError }}</p>
        </div>

        <RoundedButton buttonText="Update Password" class="custom-button" buttonType="submit"
            :loading="buttonLoading" />
    </form>
</template>

<script>
import TextInputField from "@/components/common/TextInputFieldNew.vue";
import RoundedButton from "@/components/common/RoundedButton.vue";
import { ref } from "vue";
import { checkAndRefreshToken } from "@/utils/auth";
import { getValidToken } from "@/utils/helpers";
import { API_BASE_URL } from "@/config";
import { useToast } from "@/composables/useToast";

export default {
    props: {
        userData: {
            type: Object,
            required: true
        }
    },
    components: {
        TextInputField,
        RoundedButton
    },
    emits: ['closeFormModal'],
    setup(props, { emit }) {
        const toast = useToast();
        const password = ref('');
        const confirmPassword = ref('');
        const passwordError = ref('');
        const confirmPasswordError = ref('');
        const passwordMismatchError = ref('');
        const buttonLoading = ref(false);

        const validateForm = () => {
            let isValid = true;

            passwordError.value = '';
            confirmPasswordError.value = '';
            passwordMismatchError.value = '';

            if (password.value.length === 0) {
                passwordError.value = 'Enter a password';
                isValid = false;
            }

            if (confirmPassword.value.length === 0) {
                confirmPasswordError.value = 'Re-enter the password';
                isValid = false;
            }

            if (password.value !== confirmPassword.value) {
                passwordMismatchError.value = 'The passwords do not match';
                isValid = false;
            }

            return isValid;
        };

        const updateAdminPassword = async () => {
            if (!validateForm()) return;

            try {
                buttonLoading.value = true;
                await checkAndRefreshToken();
                const accessToken = getValidToken();
                const apiUrl = `${API_BASE_URL}/auth/set-password`;

                const response = await fetch(apiUrl, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`,
                    },
                    body: JSON.stringify({
                        email: props.userData.email,
                        password: password.value
                    })
                });

                const result = await response.json();

                if (!response.ok) {
                    throw new Error(result.message || 'Failed to update password');
                }

                if (result.code !== 200) {
                    throw new Error(result.message || 'Failed to update password');
                }

                toast.success('Password updated successfully!');
                emit('closeFormModal');

            } catch (error) {
                console.error("Error updating password:", error);
                const errorMessage = error.message?.toLowerCase().includes('api') ? 
                    'Failed to update password. Please try again.' : 
                    error.message || 'Error updating password. Please try again.';
                toast.error(errorMessage);
            } finally {
                buttonLoading.value = false;
            }
        };

        return {
            updateAdminPassword,
            password,
            confirmPassword,
            passwordMismatchError,
            confirmPasswordError,
            passwordError,
            buttonLoading
        };
    }
};
</script>

<style scoped>
.error {
    color: var(--danger) !important;
    font-size: 0.875rem;
    margin-top: -0.5rem;
    margin-bottom: 1rem;
}
</style>