<template>
    <!-- start page title -->
    <div class="row">
        <div class="col-12">
            <div class="page-title-box">
                <h4 class="page-title">Manage Co-Learners</h4>
                <div class="page-title-right">
                    <RoundedButton buttonText="Add Co-Learner" iconText="mdi-plus" @clicked="openFormModal"
                        v-if="hasPermission('view_teacher')" />
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <StatCards statCardText="Total Co-Learners" :statsCardNumber="numOfTeachers" />
        <StatCards statCardText="Active Co-Learners" :statsCardNumber="activeTeachers" />
        <StatCards statCardText="Deactivated Co-Learners" :statsCardNumber="inActiveTeachers" />
    </div>

    <div class="row mainContent">
        <div class="col-sm-12">
            <div class="card-box">
                <TableView :data="coLearnersList" :headers="tableHeaders" :id="getIdForActions" :showSearchField="true"
                    :detailsData="getDataForDetailsView" :loading="loading"
                    :searchStringFromParent.sync="searchStringFromChild" :viewAction="viewAction"
                    :editAction="editAction" :deactivateAction="openConfirmationDialog" :showActionsColumn="true"
                    :searchFields="searchFieldsData" :totalPages="totalPages" :currentPage.sync="currentPage"
                    :changePage="changePage" @searchWord="searchForItem" :changeItemsPerPage="changeItemsPerPage"
                    :itemsPerPage="itemsPerPage" viewPermission="view_teacher" editPermission="modify_teacher"
                    deactivatePermission="deactivate_teacher" />
            </div>
        </div>
    </div>

    <InfoModal :isModalOpen="isConfirmationDialogOpen" @closeModal="closeConfirmationDialog"
        :successImageURL="require('../../../assets/images/icons/success_icon.svg')"
        :errorImageURL="require('../../../assets/images/icons/error_icon.svg')" header="Hold on!"
        :paragraph="deactivateConfirmationParagraph" :buttonTxt="deactivateButtonText" :buttonAction="performAction"
        buttonColor="error" routerLinkText="No, Cancel Action" buttonLink="/" :linkAction="cancelAction"
        :loading="buttonLoader">
    </InfoModal>

    <FormModal :isFormModalOpen="isFormModalOpen" @closeFormModal="closeFormModal1" modalHeader="Add Co-Learner">
        <CreateColearner @closeFormModal="closeFormModal" @message="showFlashMessage" />
    </FormModal>

    <FormModal :isFormModalOpen="isEditFormModalOpen" @closeFormModal="closeEditFormModal"
        modalHeader="Edit Co-Learner">
        <EditColearner @closeFormModal="closeEditFormModal" @message="showFlashMessage"
            :itemData="itemDataForEditing" />
    </FormModal>

    <InfoModal :isModalOpen="isFlashModalOpen" @closeModal="closeFlashModal" :isSuccess="true" header="Co-Learner Added"
        :paragraph="`You have successfully added '${fullName}'. You can view and manage this user's profile under 'Manage Co-Learners'.`"
        buttonTxt="Add Another Co-Learner" routerLinkText="Manage Co-Learners" :linkAction="createItemFlashLinkAction"
        :buttonAction="buttonAction">
    </InfoModal>
</template>
<script>
import { computed, onActivated, onMounted, ref, watch } from "vue";
import RoundedButton from "@/components/common/RoundedButton.vue";
import TableView from "@/components/common/TableView.vue";
import FormModal from "@/components/common/FormModal.vue";
import InfoModal from "@/components/common/InfoModals.vue";
import CreateColearner from "@/components/layouts/GodAdmin/Colearners/CreateColearner.vue"
import EditColearner from "@/components/layouts/GodAdmin/Colearners/EditColearner.vue";
import { useRouter } from 'vue-router';
import getColearners from "@/composables/getColearners";
import { API_BASE_URL } from "@/config.js";
import { capitalizeFirstLetter, formatDateTime, getUserInitials, getValidToken, hasPermission } from '@/utils/helpers'
import { checkAndRefreshToken } from '@/utils/auth';
import StatCards from "@/components/common/StatCards.vue";
import { useUserStore } from "@/stores/user";
import { useToast } from "@/composables/useToast";

export default {
    components: {
        RoundedButton,
        TableView,
        FormModal,
        CreateColearner,
        EditColearner,
        InfoModal,
        StatCards
    },
    emits: [
        'showLoader',
        'show-loader',
        'flashMessage',
        'data-loaded',
        'closeFormModal',
        'message',
        'refreshPage'
    ],
    setup(props, { emit }) {
        document.title = 'Manage Co-Learners'
        const {
            colearnersPayload,
            fetchColearnersData,
            loading,
            formattedColearnersData,
            colearnersUserData
        } = getColearners()
        const isFormModalOpen = ref(false);
        const isEditFormModalOpen = ref(false);
        const buttonLoader = ref(false);
        const newMessage = ref("");
        const showDetailsOrIcons = ref(false)
        const router = useRouter()
        const accessToken = getValidToken();
        const isConfirmationDialogOpen = ref(false);
        const isFlashModalOpen = ref(false);
        const fullName = ref('')
        const currentPage = ref(1);
        const itemsPerPage = ref(10); // Default items per page
        const totalPages = ref(0)
        const userData = ref(null)
        const numOfTeachers = ref(0)
        const activeTeachers = ref(0)
        const inActiveTeachers = ref(0)
        const itemData = ref(null)
        const coLearnersList = ref([])
        const toast = useToast();

        const buttonAction = () => {
            isFlashModalOpen.value = false;
            openFormModal()
        }

        const openFlashModal = () => {
            isFlashModalOpen.value = true;
        };

        const closeFlashModal = async () => {
            isFlashModalOpen.value = false;
            await fetchColearnersData(currentPage.value, itemsPerPage.value)
        };

        const createItemFlashLinkAction = () => {
            closeFlashModal()
        }

        const openFormModal = () => {
            isFormModalOpen.value = true;
        };

        const closeFormModal = (itemFullName) => {
            isFormModalOpen.value = false;

            fullName.value = itemFullName
            isFlashModalOpen.value = true
        };

        const closeFormModal1 = () => {
            isFormModalOpen.value = false;
        }

        const openEditFormModal = () => {
            isEditFormModalOpen.value = true;
        };

        const closeEditFormModal = async (message) => {
            isEditFormModalOpen.value = false;
            await fetchColearnersData(currentPage.value, itemsPerPage.value)
        };

        const searchStringFromChild = ref("");
        const searchFieldsData = ['user.name', 'gender', 'status']

        watch(colearnersUserData, () => {
            coLearnersList.value = formattedColearnersData.value
            totalPages.value = colearnersPayload.value.total_pages

            const stats = colearnersPayload.value.results
            numOfTeachers.value = stats.num_of_teachers
            activeTeachers.value = stats.total_active_teachers
            inActiveTeachers.value = stats.total_deactivated_teachers
        })

        onMounted(() => {
            fetchColearnersData(currentPage.value, itemsPerPage.value);
            emit('data-loaded')
        })

        onActivated(() => {
            fetchColearnersData(currentPage.value, itemsPerPage.value);
        })

        const getIdForActions = computed(() => {
            return coLearnersList.value.map((colearner) => {
                return colearner.user.id
            });
        });

        const getDataForDetailsView = computed(() => {
            return colearnersUserData.value
        })

        const viewAction = (item, id) => {
            router.push(
                {
                    name: 'ShowColearner',
                    params: { id: id }
                })
        }

        const itemDataForEditing = ref(null)
        const editAction = (item, item_id) => {
            itemData.value = item

            // Find the user to edit
            const itemForEditing = colearnersUserData.value.find((r) => r.user_id === item_id);

            itemDataForEditing.value = {
                firstName: itemForEditing.first_name,
                lastName: itemForEditing.last_name,
                gender: itemForEditing._gender,
                msisdn: itemForEditing.msisdn,
                email: itemForEditing.email,
                country: itemForEditing.country,
                state: itemForEditing.state,
                address: itemForEditing.address,
                has_bachelors_degree: itemForEditing.has_bachelors_degree,
                early_years_education: itemForEditing.early_years_education,
                how_you_heard_about_us: itemForEditing.how_you_heard_about_us,
                linkedin: itemForEditing.linkedin,
                years_of_experience: itemForEditing.years_of_experience,
                purpose_using_the_app: itemForEditing.purpose_using_the_app,
                id: item_id
            }

            openEditFormModal()
        }

        const deactivateAction = async (id) => {
            // console.log('item id', id)
            const apiUrl = `${API_BASE_URL}/teacher/delete-teacher`
            try {
                await checkAndRefreshToken()

                buttonLoader.value = true
                const response = await fetch(apiUrl, {
                    method: 'DELETE',
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`
                    },
                    body: JSON.stringify({
                        teacher_id: id,
                        reason: ''
                    })
                })

                if (response.ok) {
                    const result = await response.json()

                    if (result.code === 200) {
                        closeConfirmationDialog()
                        toast.success("Co-Learner status updated")
                    } else {
                        toast.error(result.message)
                        console.log('error', result.message)
                    }
                } else {
                    const result = await response.json();
                    toast.error(result.message)
                    console.log("error", result.message);
                }
            } catch (error) {
                toast.error("An error occurred while updating co-learner status")
                console.error("An error occured:", error);
            } finally {
                buttonLoader.value = false
            }
        }

        const messageFromChild = ref("");
        const handleMessageFromChild = (message) => {
            messageFromChild.value = message
        };

        const showFlashMessage = (msg) => {
            if (msg.type === 'success') {
                toast.success(msg.apiResponse)
            } else if (msg.type === 'error') {
                toast.error(msg.apiResponse)
            } else {
                toast.info(msg.apiResponse)
            }
        };

        const tableHeaders = computed(() => {
            return [
                { label: "Name", key: "name" },
                { label: "Gender", key: "gender" },
                { label: "School", key: "school" },
                { label: "Added On", key: "createdAt" },
                { label: "Acct Status", key: "status" }
            ];
        });

        const userId = ref('null')
        const userStatus = ref('')
        const deactivateConfirmationParagraph = ref('')
        const deactivateButtonText = ref('')

        const openConfirmationDialog = (item, id) => {
            userId.value = id

            userStatus.value = item.isDeactivated === false ? 'deactivate' : 'activate'
            deactivateConfirmationParagraph.value = `You are about to ${userStatus.value} this user's account. Are you sure you want to continue this action?.`
            deactivateButtonText.value = `Yes, ${capitalizeFirstLetter(userStatus.value)} User`
            isConfirmationDialogOpen.value = true;
        };

        const closeConfirmationDialog = () => {
            isConfirmationDialogOpen.value = false;
            fetchColearnersData(currentPage.value, itemsPerPage.value)
        };

        const performAction = () => {
            deactivateAction(userId.value)
        }

        const cancelAction = () => {
            closeConfirmationDialog()
        }

        const changePage = (page) => {
            if (page >= 1 && page <= totalPages.value) {
                currentPage.value = page;

                fetchColearnersData(currentPage.value, itemsPerPage.value)
            }
        };

        const changeItemsPerPage = (perPage) => {
            itemsPerPage.value = perPage;
            currentPage.value = 1; // Reset to the first page when changing items per page

            fetchColearnersData(currentPage.value, perPage);
        };

        const searchForItem = async (word) => {
            const apiUrl = `${API_BASE_URL}/teacher/search-teacher?query=${word}`
            try {
                loading.value = true
                await checkAndRefreshToken()

                const response = await fetch(apiUrl, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                })

                if (response.ok) {
                    const result = await response.json()

                    if (result.code === 200) {
                        coLearnersList.value = result.data.map((colearner) => {

                            // Format the created at
                            const { date, time } = formatDateTime(colearner.created_at);

                            const formattedColearner = {
                                user: {
                                    name: capitalizeFirstLetter(`${colearner.first_name} ${colearner.last_name}`),
                                    initials: getUserInitials(colearner.first_name, colearner.last_name),
                                    avatarColor: 'info',
                                    id: colearner.user_id
                                },
                                gender: colearner._gender,
                                school: '',
                                createdAt: date,
                                status: colearner.isDeactivated ? 'Deactivated' : 'Active',
                            };

                            return {
                                ...formattedColearner,
                            };
                        })

                        totalPages.value = coLearnersList.value.length
                    } else {
                        toast.error(result.message)
                        console.log('error', result.message)
                    }
                } else {
                    const result = await response.json();
                    toast.error(result.message)
                    console.log("error", result.message);
                }
            } catch (error) {
                toast.error("An error occurred while searching")
                console.error("An error occured:", error);
            } finally {
                loading.value = false
            }
        }

        const userStore = useUserStore();
        const user = ref(userStore.user)
        const userPermissions = ref(user.value.permissions)
        const checkPermission = (permission) => hasPermission(userPermissions, permission)

        return {
            tableHeaders,
            colearnersUserData,
            totalPages,
            formattedColearnersData,
            getIdForActions,
            loading,
            searchStringFromChild,
            searchFieldsData,
            isFormModalOpen,
            openFormModal,
            closeFormModal,
            closeFormModal1,
            handleMessageFromChild,
            showFlashMessage,
            newMessage,
            showDetailsOrIcons,
            viewAction,
            editAction,
            deactivateAction,
            InfoModal,
            isConfirmationDialogOpen,
            openConfirmationDialog,
            closeConfirmationDialog,
            performAction,
            cancelAction,
            buttonLoader,
            isEditFormModalOpen,
            openEditFormModal,
            closeEditFormModal,
            userData,
            itemDataForEditing,
            isFlashModalOpen,
            openFlashModal,
            closeFlashModal,
            buttonAction,
            fullName,
            createItemFlashLinkAction,
            deactivateConfirmationParagraph,
            deactivateButtonText,
            getDataForDetailsView,
            changePage,
            changeItemsPerPage,
            currentPage,
            itemsPerPage,
            numOfTeachers,
            activeTeachers,
            inActiveTeachers,
            itemData,
            searchForItem,
            coLearnersList,
            hasPermission: checkPermission
        };
    }
}
</script>
<style></style>