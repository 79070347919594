<template>
    <div v-if="currentPage === 1">
        <div class="row mb-30">
            <div class="col-lg-6 col-sm-12">
                <TextInputField :config="{
                    class: 'form-control-gray',
                    placeholder: 'Enter First Name',
                    inputFieldType: 'text'
                }" labelText="First Name" :isRequired=true v-model="firstName" :errorMessage="firstNameError" />
            </div>

            <div class="col-lg-6 col-sm-12">
                <TextInputField :config="{
                    class: 'form-control-gray',
                    placeholder: 'Enter Last Name',
                    inputFieldType: 'text'
                }" labelText="Last Name" :isRequired=true v-model="lastName" :errorMessage="lastNameError" />
            </div>
        </div>

        <div class="row mb-30">
            <div class="col-lg-6 col-sm-12">
                <SingleSelectFieldNew labelText="Gender" :options="genderSelectOptions" v-model="selectedGender"
                    :isRequired="true" :errorMessage="genderError" selectId="genderOptions" :loading="genderLoader" />
            </div>

            <div class="col-lg-6 col-sm-12">
                <SingleSelectFieldNew labelText="Bachelor's degree in an education related field?"
                    :options="bachelorDegreeOptions" v-model="selectedBachelorDegreeOption" :isRequired=true
                    selectId="bachelorDegreeOptions" :selectedItem="selectedBachelorDegreeOption"
                    :errorMessage="bachelorFieldError" />
            </div>
        </div>

        <div class="row mb-30">
            <div class="col-lg-6 col-sm-12">
                <SingleSelectFieldNew labelText="Country of Residence" :options="countrySelectOptions"
                    v-model="selectedCountry" :loading="countryDropdownLoader" selectId="countryOptions"
                    :isRequired=true :selectedItem="selectedCountry" @change="handleCountrySelection" />
            </div>

            <div class="col-lg-6 col-sm-12">
                <SingleSelectFieldNew labelText="State of Residence" :options="stateSelectOptions"
                    v-model="selectedState" :loading="statesDropdownLoader" :isRequired=true selectId="stateOptions"
                    :selectedItem="selectedState" />
            </div>
        </div>

        <div class="row mb-50">
            <div class="col-12">
                <TextAreaInputField :config="{
                    class: 'form-control-gray',
                    placeholder: 'Enter Address',
                    inputFieldType: 'address'
                }" labelText="Residential Address" v-model="address" />
            </div>
        </div>

    </div>

    <div v-if="currentPage === 2">
        <div class="row mb-30">
            <div class="col-lg-6 col-sm-12">
                <TextInputField :config="{
                    class: 'form-control-gray',
                    placeholder: '',
                    inputFieldType: 'years_of_experience',
                }" labelText="How many years experience do you have teaching early years / preschool aged children?"
                    v-model="years_of_experience" />
            </div>

            <div class="col-lg-6 col-sm-12">
                <SingleSelectFieldNew
                    labelText="Are you familiar with Early Years Education / EYFS / Pre-school education?"
                    :options="earlyYearsEducationOptions" v-model="selectedEarlyYearEducation" :isRequired=true
                    :errorMessage="earlyYearError" selectId="earlyYearsEducationOptions"
                    :selectedItem="selectedEarlyYearEducation" />
            </div>
        </div>

        <div class="row mb-30">
            <div class="col-lg-6 col-sm-12">
                <TextInputField :config="{
                    class: 'form-control-gray',
                    placeholder: 'Enter LinkedIn Link',
                    inputFieldType: 'text',
                }" labelText="LinkedIn URL" v-model="linkedin" />
            </div>

            <div class="col-lg-6 col-sm-12">
                <SingleSelectFieldNew labelText="How did you hear about the KEY app?" :isRequired=true
                    :errorMessage="hearAboutUsError" :options="heardAboutUsOptions"
                    v-model="selectedHeardAboutUsEducation" selectId="heardAboutUsOptions"
                    :selectedItem="selectedHeardAboutUsEducation" />
            </div>
        </div>

        <div class="row mb-50">
            <div class="col-12">
                <TextAreaInputField :config="{
                    class: 'form-control-gray',
                    placeholder: 'Enter purpose',
                    inputFieldType: 'text',
                }" labelText="What is your purpose / objective for using the KEY app?" :isRequired=true
                    :errorMessage="purposeError" v-model="purpose_using_the_app" />
            </div>
        </div>
    </div>


    <div class="mt-20 table-pagination">
        <ul class="pagination pagination-rounded">
            <li v-for="page in pages" :key="page" :class="{ active: currentPage === page }" class="page-item">
                <a href="#" class="page-link" @click="changePage(page)">{{ page }}</a>
            </li>
        </ul>

        <RoundedButton :buttonText="currentPage < pages ? 'Next' : 'Submit'" class="custom-button" @click="nextPage"
            :loading="buttonLoading" />

    </div>
</template>
<script>
import TextInputField from "@/components/common/TextInputField.vue";
import TextAreaInputField from "@/components/common/TextAreaInputField.vue";
import RoundedButton from "@/components/common/RoundedButton.vue";
import { onMounted, ref, watch } from "vue";
import getCountries from "@/composables/getCountries";
import getStates from "@/composables/getStates";
import { capitalizeFirstLetter, getValidToken } from '@/utils/helpers'
import getGender from "@/composables/getGender";
import { checkAndRefreshToken } from "@/utils/auth";
import { API_BASE_URL } from "@/config";
import SingleSelectFieldNew from "@/components/common/SingleSelectFieldNew.vue";
import { useToast } from "@/composables/useToast";


export default {
    emits: [
        'showLoader',
        'show-loader',
        'flashMessage',
        'data-loaded',
        'closeFormModal',
        'message',
        'refreshPage'
    ],
    props: {
        itemData: {
            type: Object,
            required: true
        }
    },
    components: {
        SingleSelectFieldNew,
        TextInputField,
        TextAreaInputField,
        RoundedButton,
    },
    setup(props, { emit }) {
        const { success, error: showError } = useToast();
        const buttonLoading = ref(false);
        const message = ref('');
        const { fetchCountriesData, countriesList, loading: countryDropdownLoader } = getCountries();
        const { fetchStatesData, statesList, loading: statesDropdownLoader } = getStates();
        const { fetchGender, genderList, loading: genderLoader } = getGender();

        // Form data
        const firstName = ref(props.itemData.firstName || '');
        const lastName = ref(props.itemData.lastName || '');
        const address = ref(props.itemData.address || '');
        const purpose_using_the_app = ref(props.itemData.purpose_using_the_app || '');
        const linkedin = ref(props.itemData.linkedin || '');
        const years_of_experience = ref(props.itemData.years_of_experience || '');

        // Select options
        const genderSelectOptions = ref([]);
        const countrySelectOptions = ref([]);
        const stateSelectOptions = ref([]);
        const earlyYearsEducationOptions = ref([]);
        const heardAboutUsOptions = ref([]);
        const bachelorDegreeOptions = ref([]);

        // Selected values
        const selectedGender = ref(props.itemData.gender);
        const selectedCountry = ref(props.itemData.country);
        const selectedState = ref(props.itemData.state);
        const selectedEarlyYearEducation = ref('');
        const selectedHeardAboutUsEducation = ref(props.itemData.how_you_heard_about_us || '');
        const selectedBachelorDegreeOption = ref('');

        // Form validation errors
        const firstNameError = ref('');
        const lastNameError = ref('');
        const genderError = ref('');
        const bachelorFieldError = ref('');
        const earlyYearError = ref('');
        const hearAboutUsError = ref('');
        const purposeError = ref('');

        // Page navigation
        const pages = 2;
        const currentPage = ref(1);

        // Utility refs
        const countryIdMap = ref({});

        // Helper function to normalize yes/no values
        const normalizeYesNoValue = (value) => {
            if (value === true || value === 'true' || value === 'Yes' || value === 'yes' || value === 'YES') {
                return 'yes';
            }
            return 'no';
        };

        // Helper function to load static options with consistent timing
        const loadStaticOptions = (optionsRef, data, delay = 200) => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    optionsRef.value = data;
                    resolve(optionsRef.value);
                }, delay);
            });
        };

        // Initialize all static select options
        const initializeStaticOptions = async () => {
            await Promise.all([
                loadStaticOptions(bachelorDegreeOptions, [
                    { label: "Yes", value: 'yes' },
                    { label: "No", value: 'no' }
                ]),
                loadStaticOptions(earlyYearsEducationOptions, [
                    { label: "Yes", value: 'yes' },
                    { label: "No", value: 'no' }
                ]),
                loadStaticOptions(heardAboutUsOptions, [
                    { label: "Google Search", value: 'Google Search' },
                    { label: "Website/Blog", value: 'Website/Blog' },
                    { label: "Email Newsletter", value: 'Email Newsletter' },
                    { label: "Advertisement", value: 'Advertisement' },
                    { label: "Instagram", value: 'Instagram' },
                    { label: "X", value: 'X' },
                    { label: "Tiktok", value: 'Tiktok' },
                    { label: "Referral", value: 'Referral' },
                    { label: "Facebook", value: 'Facebook' },
                    { label: "Event", value: 'Event' }
                ])
            ]);

            // Set initial values after options are loaded
            selectedBachelorDegreeOption.value = normalizeYesNoValue(props.itemData.has_bachelors_degree);
            selectedEarlyYearEducation.value = normalizeYesNoValue(props.itemData.early_years_education);
        };

        // Load page data based on current page
        const loadPageData = async (page) => {
            if (page === 1) {
                await Promise.all([
                    fetchGender(),
                    fetchCountriesData(),
                ]);
            }
        };

        // Update the country dropdown when data is received
        const updateCountryOptions = (newData) => {
            if (!newData || !newData.length) return;

            countrySelectOptions.value = newData.map(country => ({
                label: country.country_name,
                value: country.country_name
            }));

            countryIdMap.value = newData.reduce((map, country) => {
                map[country.country_name] = country.id;
                return map;
            }, {});

            // Set initial country and fetch states
            if (countrySelectOptions.value.length > 0 && selectedCountry.value) {
                handleCountrySelection();
            }
        };

        // Handle country selection and fetch corresponding states
        const handleCountrySelection = () => {
            const selectedCountryId = countryIdMap.value[selectedCountry.value];
            if (selectedCountryId) {
                fetchStatesData(selectedCountryId);
            }
        };

        // Update the states options when data is received
        const updateStatesOptions = (newData) => {
            if (!newData || !newData.length) return;

            stateSelectOptions.value = newData.map(state => ({
                label: state.state_name,
                value: state.state_name
            }));

            // If we have states and the current state doesn't match or isn't valid, set a default
            if (stateSelectOptions.value.length > 0) {
                const stateExists = stateSelectOptions.value.some(state => state.value === selectedState.value);
                if (!stateExists) {
                    selectedState.value = stateSelectOptions.value[0].value;
                }
            }
        };

        // Update gender options when data is received
        const updateGenderOptions = (newData) => {
            if (!newData || !newData.length) return;

            genderSelectOptions.value = newData.map(item => ({
                label: capitalizeFirstLetter(item.label),
                value: item.value
            }));
        };

        // Form validation for page 1
        const validatePageOne = () => {
            // Reset all error messages first
            firstNameError.value = '';
            lastNameError.value = '';
            genderError.value = '';
            bachelorFieldError.value = '';

            let isValid = true;

            // First name validation
            if (!firstName.value.trim()) {
                firstNameError.value = 'First name is required';
                isValid = false;
            }

            // Last name validation
            if (!lastName.value.trim()) {
                lastNameError.value = 'Last name is required';
                isValid = false;
            }

            // Gender validation
            if (!selectedGender.value) {
                genderError.value = 'Please select a gender';
                isValid = false;
            }

            // Bachelor's degree validation
            if (!selectedBachelorDegreeOption.value) {
                bachelorFieldError.value = 'Please select an option';
                isValid = false;
            }

            return isValid;
        };

        // Form validation for page 2
        const validatePageTwo = () => {
            // Reset all error messages first
            earlyYearError.value = '';
            hearAboutUsError.value = '';
            purposeError.value = '';

            let isValid = true;

            // Early years education validation
            if (!selectedEarlyYearEducation.value) {
                earlyYearError.value = 'Please select an option';
                isValid = false;
            }

            // How did you hear about us validation
            if (!selectedHeardAboutUsEducation.value) {
                hearAboutUsError.value = 'Please select an option';
                isValid = false;
            }

            // Purpose validation
            if (!purpose_using_the_app.value.trim()) {
                purposeError.value = 'Please enter your purpose for using the app';
                isValid = false;
            }

            return isValid;
        };

        // Handle next page or submit
        const nextPage = () => {
            if (currentPage.value === 1 && validatePageOne()) {
                currentPage.value++;
                loadPageData(2);
            } else if (currentPage.value === 2 && validatePageTwo()) {
                updateItem();
            }
        };

        // Change to specific page
        const changePage = (page) => {
            if (page === currentPage.value) return;

            currentPage.value = page;
            loadPageData(page);
        };

        // Show loading state
        const showLoaders = () => {
            buttonLoading.value = true;
        };

        // Hide loading state
        const hideLoaders = () => {
            buttonLoading.value = false;
        };

        // API endpoint
        const apiUrl = `${API_BASE_URL}/teacher/update-teacher?user_id=${props.itemData.id}`;

        // Submit form data to the API
        const updateItem = async () => {
            const item = {
                school_id: 1,
                first_name: firstName.value,
                last_name: lastName.value,
                gender: selectedGender.value,
                country: selectedCountry.value,
                state: selectedState.value,
                address: address.value,
                purpose_using_the_app: purpose_using_the_app.value,
                linkedin: linkedin.value,
                years_of_experience: years_of_experience.value,
                early_years_education: selectedEarlyYearEducation.value === 'yes' ? 'true' : 'false',
                how_you_heard_about_us: selectedHeardAboutUsEducation.value,
                has_bachelors_degree: selectedBachelorDegreeOption.value === 'yes' ? 'true' : 'false',
            };

            try {
                showLoaders();
                await checkAndRefreshToken();
                const accessToken = getValidToken();

                const response = await fetch(apiUrl, {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`,
                    },
                    body: JSON.stringify(item)
                });

                let result;
                try {
                    result = await response.json();
                } catch (parseError) {
                    console.error("Error parsing response:", parseError);
                    showError("Error processing server response");
                    return;
                }

                if (response.ok && result?.code === 200) {
                    message.value = result.message;
                    emit("closeFormModal");
                    emit("refreshPage");
                    success("Co-Learner updated successfully");
                } else {
                    const errorMsg = result?.message || "Error updating co-learner";
                    console.error("API Error:", errorMsg);
                    showError(errorMsg);
                }
            } catch (error) {
                console.error("An error occurred:", error);
                showError("An unexpected error occurred. Please try again.");
            } finally {
                hideLoaders();
            }
        };

        // Set up watchers
        watch(genderList, updateGenderOptions);
        watch(countriesList, updateCountryOptions);
        watch(statesList, updateStatesOptions);

        // Initialize component
        onMounted(async () => {
            await initializeStaticOptions();
            await loadPageData(currentPage.value);
        });

        return {
            // Form data
            firstName,
            lastName,
            address,
            purpose_using_the_app,
            linkedin,
            years_of_experience,

            // Select options
            genderSelectOptions,
            countrySelectOptions,
            stateSelectOptions,
            earlyYearsEducationOptions,
            heardAboutUsOptions,
            bachelorDegreeOptions,

            // Selected values
            selectedGender,
            selectedCountry,
            selectedState,
            selectedEarlyYearEducation,
            selectedHeardAboutUsEducation,
            selectedBachelorDegreeOption,

            // Form validation errors
            firstNameError,
            lastNameError,
            genderError,
            bachelorFieldError,
            earlyYearError,
            hearAboutUsError,
            purposeError,

            // Page navigation
            pages,
            currentPage,
            nextPage,
            changePage,

            // Loading state
            buttonLoading,
            countryDropdownLoader,
            statesDropdownLoader,
            genderLoader,

            // Functions
            handleCountrySelection,
            updateItem,
            normalizeYesNoValue
        };
    }
}
</script>
<style scoped>
@media (max-width: 767px) {

    .first_name,
    .country {
        margin-bottom: 30px;
    }
}

@media (max-width: 992px) {

    .first_name,
    .country {
        margin-bottom: 30px;
    }
}

.table-pagination {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.page-item.active .page-link {
    border-color: #2f5bcf;
    z-index: 3;
    color: #fff;
}

.page-item:first-child .page-link {
    margin-left: 0;
}

.pagination-rounded .page-link {
    border-radius: 30px !important;
    margin: 0 3px;
    border: none;
}

.page-link {
    position: relative;
    display: block;
    padding: 0.5rem 0.9rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #323a46;
    background-color: #fff;
    border: 1px solid #dee2e6;
}
</style>