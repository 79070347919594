<template>
    <!-- start page title -->
    <div class="row">
        <div class="col-12">
            <div class="page-title-box">
                <h4 class="page-title">Manage Plans</h4>
                <div class="page-title-right">
                    <RoundedButton buttonText="Add Plan" iconText="mdi-plus" @clicked="openFormModal"/>
                </div>
            </div>
        </div>
    </div>

    <div class="row mainContent">
        <div class="col-sm-12">
            <div class="card-box">
                <TableView :data="formattedPlansData" :headers="tableHeaders"
                           :id="getIdForActions" :showSearchField="true"
                           :detailsData="getDataForDetailsView" :loading="loading"
                           :searchStringFromParent.sync="searchStringFromChild"
                           :deactivateAction="openConfirmationDialog" :showActionsColumn="true"
                           :searchFields="searchFieldsData"/>
            </div>
        </div>
    </div>

    <InfoModal :isModalOpen="isConfirmationDialogOpen" @closeModal="closeConfirmationDialog"
               :successImageURL="require('../../../assets/images/icons/success_icon.svg')"
               :errorImageURL="require('../../../assets/images/icons/error_icon.svg')" header="Hold on!"
               :paragraph="deactivateConfirmationParagraph"
               :buttonTxt="deactivateButtonText" :buttonAction="performAction" buttonColor="error"
               routerLinkText="No, Cancel Action" buttonLink="/"
               :linkAction="cancelAction" :loading="buttonLoader">
    </InfoModal>

    <FormModal :isFormModalOpen="isFormModalOpen" @closeFormModal="closeFormModal1" modalHeader="Add Plan">
        <CreatePlan @closeFormModal="closeFormModal" @message="showFlashMessage"/>
    </FormModal>

    <FormModal :isFormModalOpen="isEditFormModalOpen" @closeFormModal="closeEditFormModal"
               modalHeader="Edit Plan">
        <EditPlan @closeFormModal="closeEditFormModal" @message="showFlashMessage" :itemData="itemDataForEditing"/>
    </FormModal>


    <MessageSliderCopy :isVisible="displayMessage" :messageText="messageText" :messageType="messageType"/>

    <InfoModal :isModalOpen="isFlashModalOpen" @closeModal="closeFlashModal" :isSuccess="true" header="Plan Added"
               :paragraph="`You have successfully added the '${fullName}' plan. You can view and manage this plan under 'Manage Plans'.`"
               buttonTxt="Add Another Plan" routerLinkText="Manage Plans"
               :linkAction="createItemFlashLinkAction"
               :buttonAction="buttonAction">
    </InfoModal>
</template>
<script>
import {computed, onActivated, onMounted, ref} from "vue";
import RoundedButton from "@/components/common/RoundedButton.vue";
import TableView from "@/components/common/TableView.vue";
import FormModal from "@/components/common/FormModal.vue";
import InfoModal from "@/components/common/InfoModals.vue";
import MessageSliderCopy from "@/components/common/MessageSliderCopy.vue";
import CreatePlan from "@/components/layouts/GodAdmin/Plans/CreatePlan.vue"
import EditPlan from "@/components/layouts/GodAdmin/Plans/EditPlan.vue";
import {flashMessage} from "@/utils/flashMessage.js";
import {useRouter} from 'vue-router';
import {API_BASE_URL} from "@/config.js";
import {capitalizeFirstLetter, getValidToken} from '@/utils/helpers'
import {checkAndRefreshToken} from '@/utils/auth';
import StatCards from "@/components/common/StatCards.vue";
import getPlans from "@/composables/getPlans";

export default {
    components: {
        RoundedButton,
        TableView,
        FormModal,
        MessageSliderCopy,
        CreatePlan,
        EditPlan,
        InfoModal,
        StatCards
    },
    emits: ['showLoader', 'show-loader', 'flashMessage', 'data-loaded'],
    setup(props, {emit}) {
        document.title = 'Manage Plans'
        const {
            fetchPlansData,
            loading,
            formattedPlansData,
            plansData
        } = getPlans()
        const isFormModalOpen = ref(false);
        const isEditFormModalOpen = ref(false);
        const displayMessage = ref(false);
        const buttonLoader = ref(false);
        const messageText = ref("");
        const messageType = ref("");
        const newMessage = ref("");
        const showDetailsOrIcons = ref(false)
        const router = useRouter()
        const accessToken = getValidToken();
        const isConfirmationDialogOpen = ref(false);
        const isFlashModalOpen = ref(false);
        const fullName = ref('')
        const itemData = ref(null)


        const buttonAction = () => {
            isFlashModalOpen.value = false;
            openFormModal()
        }

        const openFlashModal = () => {
            isFlashModalOpen.value = true;
        };

        const closeFlashModal = async () => {
            isFlashModalOpen.value = false;
            await fetchPlansData()
        };

        const createItemFlashLinkAction = () => {
            closeFlashModal()
        }

        const openFormModal = () => {
            isFormModalOpen.value = true;
        };

        const closeFormModal = (itemName) => {
            isFormModalOpen.value = false;

            fullName.value = itemName
            isFlashModalOpen.value = true
        };

        const closeFormModal1 = () => {
            isFormModalOpen.value = false;
        }

        const openEditFormModal = () => {
            isEditFormModalOpen.value = true;
        };

        const closeEditFormModal = async (message) => {
            isEditFormModalOpen.value = false;
            await fetchPlansData()
        };

        const searchStringFromChild = ref("");
        const searchFieldsData = ['itemName.name', 'amount', 'billing_cycle', 'status']

        onMounted(() => {
            fetchPlansData();
            emit('data-loaded')
        })

        onActivated(() => {
            fetchPlansData();
        })

        const getIdForActions = computed(() => {
            return plansData.value.map((item) => item.id);
        });

        const getDataForDetailsView = computed(() => {
            return plansData.value
        })

        const viewAction = (item, id) => {
            router.push(
                {
                    name: 'ShowPlan',
                    params: {id: id}
                })
        }

        const itemDataForEditing = ref(null)
        const editAction = (item, item_id) => {
            itemData.value = item

            // Find the user to edit
            const itemForEditing = plansData.value.find((r) => r.id === item_id);

            // console.log('itemForEditing', itemForEditing)

            itemDataForEditing.value = {
                name: itemForEditing.name,
                amount: itemForEditing.amount,
                description: itemForEditing.description,
                bill_cycle: itemForEditing.bill_cycle,
                features: itemForEditing.features,
                id: item_id
            }

            openEditFormModal()
        }

        const deactivateAction = async (id) => {
            const apiUrl = `${API_BASE_URL}/subscription/toggle-plan-status/${id}`

            try {
                await checkAndRefreshToken()

                buttonLoader.value = true
                const response = await fetch(apiUrl, {
                    method: 'PUT',
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`
                    }
                })

                if (response.ok) {
                    const result = await response.json()

                    if (result.code === 200) {
                        closeConfirmationDialog()

                        const msg = {
                            apiResponse: "Plan status updated",
                            type: "success"
                        }
                        showFlashMessage(msg)
                    } else {
                        const msg = {
                            apiResponse: result.message,
                            type: "success"
                        }
                        showFlashMessage(msg)
                        console.log('error', result.message)
                    }
                } else {
                    const result = await response.json();
                    console.log("error", result.message);
                }
            } catch (error) {
                console.error("An error occured:", error);
            } finally {
                buttonLoader.value = false
            }
        }

        const messageFromChild = ref("");
        const handleMessageFromChild = (message) => {
            messageFromChild.value = message
        };

        const showFlashMessage = (msg) => {
            flashMessage(
                msg.apiResponse,
                msg.type,
                messageText,
                messageType,
                displayMessage
            );
        };

        const tableHeaders = computed(() => {
            return [
                {label: "Name", key: "name"},
                {label: "Billing Cycle", key: "bill_cycle"},
                {label: "Amount", key: "amount"},
                {label: "Added On", key: "createdAt"},
                {label: "Acct Status", key: "status"}
            ];
        });

        const itemId = ref('null')
        const itemStatus = ref('')
        const deactivateConfirmationParagraph = ref('')
        const deactivateButtonText = ref('')

        const openConfirmationDialog = (item, id) => {
            itemId.value = id

            itemStatus.value = item.isActive === false ? 'activate' : 'deactivate'
            deactivateConfirmationParagraph.value = `You are about to ${itemStatus.value} this plan. Are you sure you want to continue this action?.`
            deactivateButtonText.value = `Yes, ${capitalizeFirstLetter(itemStatus.value)} Plan`
            isConfirmationDialogOpen.value = true;
        };

        const closeConfirmationDialog = () => {
            isConfirmationDialogOpen.value = false;
            fetchPlansData()
        };

        const performAction = () => {
            deactivateAction(itemId.value)
        }

        const cancelAction = () => {
            closeConfirmationDialog()
        }

        return {
            tableHeaders,
            plansData,
            formattedPlansData,
            getIdForActions,
            loading,
            searchStringFromChild,
            searchFieldsData,
            isFormModalOpen,
            openFormModal,
            closeFormModal,
            closeFormModal1,
            handleMessageFromChild,
            showFlashMessage,
            displayMessage,
            messageText,
            messageType,
            newMessage,
            showDetailsOrIcons,
            viewAction,
            editAction,
            deactivateAction,
            InfoModal,
            isConfirmationDialogOpen,
            openConfirmationDialog,
            closeConfirmationDialog,
            performAction,
            cancelAction,
            buttonLoader,
            isEditFormModalOpen,
            openEditFormModal,
            closeEditFormModal,
            itemDataForEditing,
            isFlashModalOpen,
            openFlashModal,
            closeFlashModal,
            buttonAction,
            fullName,
            createItemFlashLinkAction,
            deactivateConfirmationParagraph,
            deactivateButtonText,
            getDataForDetailsView,
            itemData
        };
    }
}
</script>
<style></style>