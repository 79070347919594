<template>
    <div class="row">
        <div class="col-12">
            <div class="page-title-box">
                <BreadCrumbs />
            </div>
        </div>
    </div>

    <div class="row" v-if="itemData">
        <div class="col-sm-4">
            <div class="card-box">
                <div class="card-content">
                    <div class="header-content text-center">
                        <div v-if="itemData.profile_image">
                            <img class="mb-20 d-block mx-auto" :src="itemData.profile_image" alt="Profile Image" />

                            <input ref="fileInput" type="file" accept=".png, .jpg" @change="handleFileChange"
                                style="display: none" />
                        </div>

                        <AvatarForInitials :avatarText="getUserInitialsForName(itemName)" iconColor="primary"
                            class="avatar-md-150 mb-30 m-auto" v-else />

                        <h4 class="mb-0">{{ itemName }}</h4>
                        <p class="mb-0">{{ itemData.email }}</p>
                        <p class="mb-20">{{ itemData.msisdn }}</p>
                    </div>

                    <div class="middle-content mb-100">

                        <div class="details-content">
                            <img src="../../../assets/images/icons/calendar_icon.svg" alt="Mobile Icon" />
                            <div class="details">
                                <p class="muted-text-sm">Added On</p>
                                <p class="result-text">{{ userCreatedAt }}</p>
                            </div>
                        </div>

                        <div class="details-content">
                            <img src="../../../assets/images/icons/folder_icon.svg" class="data-img"
                                alt="Mobile Icon" />

                            <div class="detail-content-group">
                                <p class="data-title">Children</p>
                                <p class="data-text">{{ itemData.students.length }}</p>
                            </div>
                        </div>
                    </div>

                    <div class="buttons-content">
                        <RegularButtonBlock buttonText="Edit Profile" iconText="mdi-account-edit"
                            class="custom-button mb-3" @clicked="editAction" />
                    </div>
                </div>
            </div>
        </div>

        <div class="col-sm-8">
            <div class="card-box-no-pd">
                <div class="page-title-box d-flex flex-column">
                    <div class="page-title-gray mb-0">
                        <h4 class="mb-0">Parent's Profile</h4>
                    </div>

                    <ul class="tab-container pb-30">
                        <li v-for="(tab, index) in tabs" :key="index" @click="selectTab(index)" class="tab"
                            :class="{ 'active-tab': activeTab === index }">
                            {{ tab }}
                        </li>
                    </ul>
                </div>


                <div v-show="activeTab === 0">
                    <div class="card-content border-bottom plr-50">
                        <div class="row">
                            <div class="col-lg-6 col-sm-12">
                                <div class="detail-group mb-30">
                                    <span class="muted-text-sm">Full Name</span>
                                    <p>{{ itemName }}</p>
                                </div>

                                <div class="detail-group mb-30">
                                    <span class="muted-text-sm">Contact Number</span>
                                    <p>{{ itemData.msisdn }}</p>
                                </div>

                                <div class="detail-group mb-30">
                                    <span class="muted-text-sm">Country of Residence</span>
                                    <p>{{ itemData.country }}</p>
                                </div>
                            </div>

                            <div class="col-lg-6 col-sm-12">
                                <div class="detail-group mb-30">
                                    <span class="muted-text-sm">Gender</span>
                                    <p>{{ itemData._gender }}</p>
                                </div>

                                <div class="detail-group mb-30">
                                    <span class="muted-text-sm">Email Address</span>
                                    <p>{{ itemData.email }}</p>
                                </div>

                                <div class="detail-group mb-30">
                                    <span class="muted-text-sm">State of Residence</span>
                                    <p>{{ itemData.state }}</p>
                                </div>
                            </div>

                            <div class="col-lg-6 col-sm-12">
                                <div class="detail-group mb-30">
                                    <span class="muted-text-sm">Residential Address</span>
                                    <p>{{ itemData.address }}</p>
                                </div>
                            </div>

                            <div class="col-lg-6 col-sm-12">
                                <div class="detail-group mb-30">
                                    <span class="muted-text-sm">Relationship to Child(ren)</span>
                                    <p>{{ userRelationshipToStudent }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div v-show="activeTab === 1">
                    <div class="card-content border-bottom plr-50">
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="detail-group mb-30">
                                    <span class="muted-text-sm">Work Contact Number</span>
                                    <p>{{ itemData.work_msisdn }}</p>
                                </div>
                            </div>


                            <div class="col-sm-6">
                                <div class="detail-group mb-30">
                                    <span class="muted-text-sm">Email Address</span>
                                    <p>{{ itemData.work_email }}</p>
                                </div>
                            </div>

                            <div class="col-sm-12">
                                <div class="detail-group mb-30">
                                    <span class="muted-text-sm">Work Address</span>
                                    <p>{{ itemData.work_address }}</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>


                <div v-show="activeTab === 2">
                    <div class="card-content border-bottom plr-50">

                        <div class="row m-0" v-if="itemData.students.length === 0">
                            <div class="col-12 mb-20 pl-0">
                                <p class="p-0">No Children Yet</p>
                            </div>
                        </div>

                        <div class="row mb-30" v-else>
                            <div class="col-lg-3 col-md-6 col-sm-12" v-for="student in itemData.students"
                                :key="student">
                                <UserAvatarBox2 :studentFirstName="student.first_name"
                                    :studentLastName="student.last_name" :viewAction="viewStudent" :id="student.user_id"
                                    :studentSchool="capitalizeFirstLetter(student.school)" />
                            </div>
                        </div>

                    </div>
                </div>


                <div v-show="activeTab === 3">
                    <div class="card-content border-bottom plr-50">
                        <div class="row">
                            <div class="col-lg-6 col-sm-12">
                                <div class="detail-group mb-30">
                                    <span class="muted-text-sm">Emailed child's birth certificate?</span>
                                    <p>{{ itemData.has_emailed_child_kyc === true ? 'Yes' : 'No' }}</p>
                                </div>
                            </div>


                            <div class="col-lg-6 col-sm-12">
                                <div class="detail-group mb-30">
                                    <span class="muted-text-sm">
                                        Agreed with terms and conditions
                                    </span>
                                    <p>{{ itemData.agree_with_terms === true ? 'Yes' : 'No' }}</p>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-lg-6 col-sm-12">
                                <div class="detail-group mb-30">
                                    <span class="muted-text-sm">
                                        Child's first language
                                    </span>
                                    <p>{{ capitalizeFirstLetter(itemData.child_first_language) }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="ptb-30 plr-50">
                    <p class="dark-p mb-20">Turn on/off to activate or deactivate this account</p>
                    <Switch :switchText="switchTextLabel" v-model="checkedStatus" @change="handleSwitchToggle"
                        :description="checkedStatus ? 'Account is currently active' : 'Account is currently deactivated'"
                        class="mb-20" :loading="loading" />
                </div>

                <FormModal :isFormModalOpen="isEditFormModalOpen" @closeFormModal="closeEditFormModal"
                    modalHeader="Edit Parent">
                    <EditParent @closeFormModal="closeEditFormModal" @message="showFlashMessage"
                        :itemData="itemDataForEditing" @refreshPage="fetchItemData" />
                </FormModal>
            </div>
        </div>
    </div>
</template>

<script>
import { useToast } from '@/composables/useToast';
import Switch from "@/components/common/Switch.vue";
import RegularButtonBlock from "@/components/common/RegularButtonBlock.vue";
import BreadCrumbs from "@/components/common/BreadCrumbs.vue";
import { onMounted, ref, watch } from "vue";
import {
    capitalizeFirstLetter,
    formatDateTime,
    getCurrentUser,
    getUserInitialsForName,
    getValidToken
} from '@/utils/helpers'
import { checkAndRefreshToken } from '@/utils/auth';
import { API_BASE_URL } from "@/config.js";
import FormModal from "@/components/common/FormModal.vue";
import EditParent from "@/components/layouts/GodAdmin/Parents/EditParent.vue";
import { useRoute, useRouter } from "vue-router";
import UserAvatarBox2 from "@/components/common/UserAvatarBox2.vue";
import AvatarForInitials from "@/components/common/AvatarForInitials.vue";


export default {
    components: {
        AvatarForInitials,
        UserAvatarBox2,
        EditParent,
        FormModal,
        Switch,
        RegularButtonBlock,
        BreadCrumbs
    },
    props: {
        id: String
    },
    emits: ['showLoader', 'show-loader', 'flashMessage', 'data-loaded'],
    setup(props, { emit }) {
        const toast = useToast();
        const isEditFormModalOpen = ref(false);
        const itemData = ref(null)
        const itemName = ref('')
        const userCreatedAt = ref('')
        const userRelationshipToStudent = ref('')
        const loading = ref(false)
        const checkedStatus = ref(false)
        const switchTextLabel = ref('')
        const currentUser = getCurrentUser()
        const tabs = ref(['Basic Details', 'Work Details', 'Children', 'Other Information'])
        const activeTab = ref(0)
        const accessToken = getValidToken();
        const newRouter = useRouter()
        const router = useRoute()
        const itemId = router.params.id

        const fetchItemData = async () => {
            const apiUrl = `${API_BASE_URL}/parent/get-parent/${itemId}`;

            try {
                emit('show-loader')
                const response = await fetch(apiUrl, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    }
                })

                if (response.ok) {
                    const result = await response.json()
                    itemData.value = result.data

                    // console.log('result', result.data)
                } else {
                    console.log("response", response);
                    console.error("Error fetching data from the API");
                }
            } catch (error) {
                console.error('An error occured: ', error)
            }
        }

        onMounted(() => {
            fetchItemData()
        })

        watch(itemData, (item) => {
            itemName.value = capitalizeFirstLetter(item.first_name, item.last_name)
            const { date, time } = formatDateTime(item.created_at)
            userCreatedAt.value = `${date}, ${time}`
            userRelationshipToStudent.value = item.relationship_to_student ? capitalizeFirstLetter(item.relationship_to_student) : ''

            checkedStatus.value = !item.isDeactivated
            switchTextLabel.value = item.isDeactivated ? 'Account Deactivated' : 'Account Active'

            emit('data-loaded')
        })

        const selectTab = (index) => {
            activeTab.value = index;
        };

        const openEditFormModal = () => {
            isEditFormModalOpen.value = true;
        };

        const closeEditFormModal = async (message) => {
            isEditFormModalOpen.value = false;
        };


        const itemDataForEditing = ref(null)
        const editAction = () => {
            itemDataForEditing.value = {
                firstName: itemData.value.first_name,
                lastName: itemData.value.last_name,
                gender: itemData.value._gender,
                msisdn: itemData.value.msisdn,
                country: itemData.value.country,
                state: itemData.value.state,
                address: itemData.value.address,
                workEmail: itemData.value.work_email,
                workMsisdn: itemData.value.work_msisdn,
                workAddress: itemData.value.work_address,
                hasEmailedChildKyc: itemData.value.has_emailed_child_kyc,
                agreeWithTerms: itemData.value.agree_with_terms,
                childFirstLanguage: itemData.value.child_first_language,
                relationshipToStudent: itemData.value.relationship_to_student,
                id: itemId
            }

            openEditFormModal()
        }

        const handleSwitchToggle = () => {
            toggleUserStatus(itemId);
        }

        const toggleUserStatus = async (id) => {
            const apiUrl = `${API_BASE_URL}/parent/delete-parent`

            try {
                await checkAndRefreshToken()
                const accessToken = getValidToken()

                loading.value = true

                // API expects isDeactivated flag
                const requestBody = {
                    parent_id: id,
                    isDeactivated: !checkedStatus.value, // Invert current status
                    reason: ''
                }

                const response = await fetch(apiUrl, {
                    method: 'DELETE',
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`
                    },
                    body: JSON.stringify(requestBody)
                })

                if (response.ok) {
                    const result = await response.json()

                    if (result.code === 200) {
                        toast.success(`Parent account ${checkedStatus.value ? 'activated' : 'deactivated'}`);
                        await fetchItemData()
                    } else {
                        toast.error(result.message || "Failed to update status");
                        console.log('Error:', result.message)
                    }
                } else {
                    const result = await response.json();
                    toast.error(result.message || "Failed to update status");
                    console.log("Error:", result.message);
                }
            } catch (error) {
                toast.error("An error occurred while updating status");
                console.error("Exception:", error);
            } finally {
                loading.value = false
            }
        }

        const showFlashMessage = (msg) => {
            if (msg.type === 'success') {
                toast.success(msg.apiResponse);
            } else if (msg.type === 'error') {
                toast.error(msg.apiResponse);
            } else {
                toast.info(msg.apiResponse);
            }
        };

        const viewStudent = (studentId) => {
            newRouter.push({
                name: 'ShowStudent',
                params: {
                    id: studentId
                }
            })
        }


        return {
            loading,
            getUserInitialsForName,
            itemData,
            itemName,
            userCreatedAt,
            checkedStatus,
            switchTextLabel,
            handleSwitchToggle,
            currentUser,
            editAction,
            closeEditFormModal,
            isEditFormModalOpen,
            showFlashMessage,
            itemDataForEditing,
            tabs,
            activeTab,
            selectTab,
            fetchItemData,
            viewStudent,
            userRelationshipToStudent,
            capitalizeFirstLetter,
            toast
        }
    }
};
</script>

<style scoped>
.data-text {
    color: #0D44B2;
}

.detail-content-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.detail-content-group>p {
    margin: 0;
}

.data-img {
    margin-top: 2px !important;
}

.border-bottom {
    border-bottom: 2px solid #F5F6FA;
}

.header-content {
    padding: 30px 0 20px 0;
    position: relative;
    border-bottom: 1px solid #e9eaf3;
}

p {
    color: #9a9a9a;
    font-size: 16px;
}

.details-content {
    width: 100%;
    display: flex;
    align-items: flex-start;
    padding: 30px 30px 0 30px;
}

.details-content:last-child {
    padding-bottom: 30px;
}

.details-content:first-child {
    border-bottom: 1px solid #e9eaf3;
    padding-bottom: 30px;
}

.details-content>img {
    margin-right: 15px;
    margin-top: 5px;
}

.details-content .details>p {
    margin-bottom: 0;
}

.logo-text>img {
    margin-right: 15px;
    margin-top: 2px;
}

.logo-text>p {
    color: #333333;
}

.detail-group>p,
.result-text,
.data-title {
    color: #333;
}

.item>.count {
    color: #2f5bcf;
}

.buttons-content {
    padding: 0 15px 40px 15px;
}

.card-box {
    padding: 0 15px 0 15px
}

.page-title-box {
    display: flex;
}

.page-title-gray {
    background-color: #F8FAFC;
    margin: 0;
    color: #323a46;
    padding: 20px 50px 10px 50px;
    width: 100%;
}

.page-title-gray h4 {
    font-size: 1.25rem;
    line-height: 1.5;
    margin: 0;
}

.tab-container {
    display: flex;
    background-color: #F8FAFC;
    padding-left: 0;
    margin-top: 0;
    width: 100%;
    padding: 0px 50px 10px 50px;
}

.tab {
    list-style-type: none;
    margin: 0 20px 0 0;
    text-align: center;
    cursor: pointer;
}

.tab:first-child {
    margin-left: 0;
    padding-left: 0;
}

.active-tab {
    border-bottom: 2px solid #0d44b2;
    padding-bottom: 10px;
}
</style>