<template>
    <!-- start page title -->
    <div class="row">
        <div class="col-12">
            <div class="page-title-box">
                <h4 class="page-title">Manage Project Checklist Questions</h4>
                <div class="page-title-right">
                    <RoundedButton buttonText="Add Question" iconText="mdi-plus" @clicked="openFormModal"
                                   v-if="hasPermission('add_checklist')"/>
                </div>
            </div>
        </div>
    </div>

    <div class="row mainContent">
        <div class="col-sm-12">
            <div class="card-box">
                <TableView :data="formattedChecklistData" :headers="tableHeaders" :id="idForActions"
                           :detailsData="getDataForDetailsView"
                           :loading="checklistLoader" :searchStringFromParent.sync="searchStringFromChild"
                           :showActionsColumn="true" :showSearchField="true"
                           :editAction="editAction" :deleteAction="openConfirmationDialog"
                           :searchFields="searchFields"
                           viewPermission="view_checklist"
                           editPermission="update_checklist" deletePermission="delete_checklist"/>
            </div>
        </div>
    </div>

    <InfoModal :isModalOpen="isConfirmationDialogOpen" @closeModal="closeConfirmationDialog"
               :successImageURL="require('../../../assets/images/icons/success_icon.svg')"
               :errorImageURL="require('../../../assets/images/icons/error_icon.svg')" header="Hold on!"
               paragraph="You are about to delete this question. This action cannot be undone once completed. Are you sure you want to continue this action?."
               buttonTxt="Yes, Delete Question" :buttonAction="performAction" buttonColor="error"
               routerLinkText="No, Cancel Action" buttonLink="/"
               :linkAction="cancelAction" :loading="buttonLoader">
    </InfoModal>

    <FormModal :isFormModalOpen="isFormModalOpen" @closeFormModal="closeFormModal" modalHeader="Create Question">
        <CreateChecklist @closeFormModal="closeFormModal" @message="showFlashMessage"/>
    </FormModal>

    <FormModal :isFormModalOpen="isEditFormModalOpen" @closeFormModal="closeEditFormModal" modalHeader="Edit Question">
        <EditChecklist @closeFormModal="closeEditFormModal" @message="showFlashMessage" :itemData="checklistDataForEditing"/>
    </FormModal>


    <MessageSliderCopy :isVisible="displayMessage" :messageText="messageText" :messageType="messageType"/>
</template>

<script>
import {computed, onActivated, onMounted, ref, watch} from "vue";
import RoundedButton from "@/components/common/RoundedButton.vue";
import TableView from "@/components/common/TableView.vue";
import FormModal from "@/components/common/FormModal.vue";
import InfoModal from "@/components/common/InfoModals.vue";
import MessageSliderCopy from "@/components/common/MessageSliderCopy.vue";
import {flashMessage} from "@/utils/flashMessage.js";
import {API_BASE_URL} from "@/config.js";
import {getValidToken, hasPermission} from "@/utils/helpers";
import getChecklist from "@/composables/getProjectChecklist";
import CreateChecklist from "@/views/God_Admin/ProjectsChecklist/CreateChecklist.vue";
import EditChecklist from "@/views/God_Admin/ProjectsChecklist/EditChecklist.vue";
import {useUserStore} from "@/stores/user";

export default {
    components: {
        EditChecklist,
        CreateChecklist,
        RoundedButton,
        TableView,
        FormModal,
        MessageSliderCopy,
        InfoModal,
    },
    emits: ['showLoader', 'show-loader', 'flashMessage', 'data-loaded'],
    setup(props, {emit}) {
        const isFormModalOpen = ref(false);
        const isEditFormModalOpen = ref(false);
        const displayMessage = ref(false);
        const buttonLoader = ref(false);
        const messageText = ref("");
        const messageType = ref("");
        const newMessage = ref("");
        const showDetailsOrIcons = ref(false)
        const accessToken = getValidToken();
        const isConfirmationDialogOpen = ref(false);
        const checklistId = ref('null')
        document.title = "Manage Project Checklist"

        const openFormModal = () => {
            isFormModalOpen.value = true;
        };

        const closeFormModal = async (message) => {
            isFormModalOpen.value = false;
            await fetchChecklistData()
        };

        const openEditFormModal = () => {
            isEditFormModalOpen.value = true;
        };

        const closeEditFormModal = async (message) => {
            isEditFormModalOpen.value = false;
            await fetchChecklistData()
        };

        const searchStringFromChild = ref("");
        const searchFields = ['question']
        const {fetchChecklistData, checklistData, formattedChecklistData, loading: checklistLoader} = getChecklist()

        onMounted(async () => {
            emit('data-loaded')
            await fetchChecklistData();
        })

        onActivated(() => {
            fetchChecklistData();
        })

        const idForActions = ref([])
        watch(() => checklistData.value, (newChecklistData) => {
            idForActions.value = newChecklistData.map((item) => item.id)
        })

        const getDataForDetailsView = computed(() => checklistData.value)

        const checklistDataForEditing = ref(null)
        const editAction = (item, item_id) => {
            const itemForEditing = checklistData.value.find((r) => r.id === item_id);

            checklistDataForEditing.value = {
                question: itemForEditing.question,
                id: item_id
            }

            openEditFormModal()
        }

        const deleteAction = async (id) => {
            const apiUrl = `${API_BASE_URL}/checklist/${id}`
            try {
                buttonLoader.value = true
                const response = await fetch(apiUrl, {
                    method: 'DELETE',
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`
                    }
                })

                if (response.ok) {
                    const result = await response.json()

                    if (result.code === 200) {
                        closeConfirmationDialog()

                        const msg = {
                            apiResponse: result.message,
                            type: "success"
                        }
                        showFlashMessage(msg)
                    } else {
                        const msg = {
                            apiResponse: result.message,
                            type: "success"
                        }
                        showFlashMessage(msg)
                        console.log('error', result.message)
                    }
                } else {
                    const result = await response.json();
                    console.log("error", result.message);
                }
            } catch (error) {
                console.error("An error occured:", error);
            } finally {
                buttonLoader.value = false
            }
        }

        const messageFromChild = ref("");
        const handleMessageFromChild = (message) => {
            messageFromChild.value = message
        };

        const showFlashMessage = (msg) => {
            flashMessage(
                msg.apiResponse,
                msg.type,
                messageText,
                messageType,
                displayMessage
            );
        };

        const tableHeaders = computed(() => {
            return [
                {label: "Question", key: "question"},
                {label: "Created On", key: "date"},
            ];
        });

        const openConfirmationDialog = (detail, id) => {
            checklistId.value = id
            isConfirmationDialogOpen.value = true;
        };

        const closeConfirmationDialog = () => {
            isConfirmationDialogOpen.value = false;
            fetchChecklistData()
        };

        const performAction = () => {
            deleteAction(checklistId.value)
        }

        const cancelAction = () => {
            closeConfirmationDialog()
        }

        const userStore = useUserStore();
        const user = ref(userStore.user)
        const userPermissions = ref(user.value.permissions)
        const checkPermission = (permission) => hasPermission(userPermissions, permission)

        return {
            tableHeaders,
            formattedChecklistData,
            idForActions,
            searchStringFromChild,
            isFormModalOpen,
            openFormModal,
            closeFormModal,
            handleMessageFromChild,
            showFlashMessage,
            displayMessage,
            messageText,
            messageType,
            newMessage,
            showDetailsOrIcons,
            editAction,
            deleteAction,
            InfoModal,
            isConfirmationDialogOpen,
            openConfirmationDialog,
            closeConfirmationDialog,
            performAction,
            cancelAction,
            buttonLoader,
            isEditFormModalOpen,
            openEditFormModal,
            closeEditFormModal,
            checklistDataForEditing,
            checklistLoader,
            getDataForDetailsView,
            searchFields,
            hasPermission: checkPermission
        };
    },
};
</script>

