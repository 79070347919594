// Initialize Select2 globally
export default {
    install(app) {
        app.config.globalProperties.$initializeSelect2 = (element, options = {}) => {
            // Make sure jQuery and Select2 are available
            if (typeof $ === 'undefined' || typeof $.fn.select2 === 'undefined') {
                console.warn('jQuery or Select2 is not loaded');
                return null;
            }

            try {
                // Set defaults that match our application style
                const defaultOptions = {
                    placeholder: 'Select an option',
                    allowClear: true,
                    width: '100%',
                    theme: 'default'
                };

                // Merge default options with user options
                const mergedOptions = { ...defaultOptions, ...options };

                // Initialize Select2 on the element
                const $element = $(element);
                $element.select2(mergedOptions);

                return $element;
            } catch (error) {
                console.error('Error initializing Select2:', error);
                return null;
            }
        };
    }
}; 