<template>
    <form @submit.prevent="saveNewAdmin">
        <div class="row mb-30">
            <div class="col-lg-6">
                <TextInputField :config="{
                    class: 'form-control-gray',
                    placeholder: 'Enter First Name',
                    inputFieldType: 'text',
                }" labelText="First Name" errorType="nameError" v-model="first_name" />
            </div>

            <div class="col-lg-6">
                <TextInputField :config="{
                    class: 'form-control-gray',
                    placeholder: 'Enter Last Name',
                    inputFieldType: 'text',
                }" labelText="Last Name" errorType="nameError" v-model="last_name" />
            </div>
        </div>

        <div class="row mb-30">
            <div class="col-lg-4">
                <SingleSelectFieldNew labelText="Gender" :options="genderSelectOptions" v-model="selectedGender" />
            </div>

            <div class="col-lg-8">
                <!-- <PhoneNumberFieldInput :config="{
                }" labelText="Contact Number" v-model="msisdn"/> -->

                <TextInputField :config="{
                    class: 'form-control-gray',
                    placeholder: 'Enter Contact Number',
                    inputFieldType: 'tel',
                }" labelText="Contact Number" v-model="msisdn" />
            </div>
        </div>

        <TextInputField :config="{
            class: 'form-control-gray mb-30',
            placeholder: 'Enter Email',
            inputFieldType: 'text',
        }" labelText="Email" errorType="nameError" v-model="email" />


        <div class="row mb-30">
            <div class="col-lg-6">
                <SingleSelectFieldNew labelText="Country of Residence" :options="countrySelectOptions"
                    v-model="selectedCountry" :loading="countryDropdownLoader" selectId="countryOptions"
                    @change="handleCountrySelection" />
            </div>

            <div class="col-lg-6">
                <SingleSelectFieldNew labelText="State of Residence" :options="stateSelectOptions"
                    v-model="selectedState" :loading="statesDropdownLoader" selectId="stateOptions" />
            </div>
        </div>

        <div class="row mb-30">
            <div class="col-12">
                <TextAreaInputField :config="{
                    class: 'form-control-gray mb-30',
                    placeholder: 'Enter Address',
                    inputFieldType: 'address',
                }" labelText="Residential Address" errorType="addressError" v-model="address" />
            </div>
        </div>

        <div class="row mb-50">
            <div class="col-12">
                <SingleSelectFieldNew labelText="Role on Portal" :options="rolesSelectOptions" v-model="selectedRole"
                    class="mb-30" :loading="dropdownLoader" selectId="roleOptions" />
            </div>
        </div>


        <RoundedButton buttonText="Submit" class="custom-button" buttonType="submit" :loading="buttonLoading" />

    </form>
</template>
<script>
import ImageUploadInput from "@/components/common/ImageUploadInput.vue";
import TextInputField from "@/components/common/TextInputField.vue";
import SingleSelectFieldNew from "@/components/common/SingleSelectFieldNew.vue";
import TextAreaInputField from "@/components/common/TextAreaInputField.vue";
import RoundedButton from "@/components/common/RoundedButton.vue";
import PhoneNumberFieldInput from "@/components/common/PhoneNumberFieldInput.vue";
import { onMounted, ref, watch } from "vue";
import getRoles from '@/composables/getRoles'
import getCountries from '@/composables/getCountries'
import getStates from '@/composables/getStates'
import { capitalizeFirstLetter, getValidToken } from '@/utils/helpers'
import { checkAndRefreshToken } from '@/utils/auth';
import { API_BASE_URL } from "@/config.js";

export default {
    props: {},
    components: {
        ImageUploadInput,
        TextInputField,
        SingleSelectFieldNew,
        TextAreaInputField,
        RoundedButton,
        PhoneNumberFieldInput,
    },
    setup(props, { emit }) {
        const { fetchCountriesData, countriesList, loading: countryDropdownLoader } = getCountries()
        const { fetchRolesData, rolesData, loading: dropdownLoader } = getRoles()
        const { fetchStatesData, statesList, loading: statesDropdownLoader } = getStates()
        const buttonLoading = ref(false)
        const loadingSpinner = ref(false);
        const first_name = ref('')
        const last_name = ref('')
        const email = ref('')
        const msisdn = ref('')
        const address = ref('')
        const selectedGender = ref('')
        const selectedCountry = ref('')
        const selectedState = ref('')
        const selectedRole = ref(0)
        const message = ref('')
        const countrySelectOptions = ref([])
        const countryIdMap = ref({});
        const rolesSelectOptions = ref([])
        const stateSelectOptions = ref([])

        const genderSelectOptions = [
            { label: "Select Gender", value: '' },
            { label: "Male", value: 'Male' },
            { label: "Female", value: 'Female' }
        ]

        onMounted(() => {
            fetchRolesData()
            fetchCountriesData()
        })

        // ROLES
        const updateRoleOptions = (newData) => {
            rolesSelectOptions.value = newData.map(role => ({ label: capitalizeFirstLetter(role.name), value: role.id }));

            if (rolesSelectOptions.value.length > 0) {
                selectedRole.value = rolesSelectOptions.value[0].value;
            }
        }

        watch(rolesData, (newData) => {
            updateRoleOptions(newData)
        })


        // COUNTRIES
        const handleCountrySelection = () => {
            const selectedCountryId = countryIdMap.value[selectedCountry.value]
            fetchStatesData(selectedCountryId)
        }

        // Update the country dropdown when the modal is opened
        const updateCountryOptions = (newData) => {
            countrySelectOptions.value = newData.map(country => ({
                label: country.country_name, value: country.country_name
            }))

            countryIdMap.value = newData.reduce((map, country) => {
                map[country.country_name] = country.id;
                return map;
            }, {});

            if (countrySelectOptions.value.length > 0) {
                selectedCountry.value = 'Nigeria'
                handleCountrySelection()
            }
        }

        watch(countriesList, (newData) => {
            updateCountryOptions(newData)
        })

        // STATES
        // Update the states options
        const updateStatesOptions = (newData) => {
            stateSelectOptions.value = newData.map(state => ({
                label: state.state_name, value: state.state_name
            }))

            if (stateSelectOptions.value.length > 0) {
                selectedState.value = stateSelectOptions.value[0].value;
            }
        }

        watch(statesList, (newData) => {
            updateStatesOptions(newData)
        })

        // OTHERS
        const showLoaders = () => {
            buttonLoading.value = true;
            loadingSpinner.value = true;
        };

        const hideLoaders = () => {
            buttonLoading.value = false;
            loadingSpinner.value = false;
        };

        const apiUrl = `${API_BASE_URL}/admin/add-admin`
        const saveNewAdmin = async () => {
            const newAdmin = {
                first_name: first_name.value,
                last_name: last_name.value,
                email: email.value,
                msisdn: msisdn.value,
                gender: selectedGender.value,
                country: selectedCountry.value,
                state: selectedState.value,
                address: address.value,
                role: selectedRole.value,
            }

            // console.log('newAdmin', newAdmin)

            try {
                showLoaders()
                await checkAndRefreshToken()
                const accessToken = getValidToken()

                const response = await fetch(apiUrl, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`,
                    },
                    body: JSON.stringify(newAdmin)
                })


                if (response.ok) {
                    const result = await response.json();

                    if (result.code === 200) {
                        message.value = result.message;

                        const adminFullName = capitalizeFirstLetter(`${newAdmin.first_name} ${newAdmin.last_name}`)
                        emit("closeFormModal", adminFullName);
                    } else {
                        console.log("error", result.message);
                    }
                } else {
                    const result = await response.json();
                    console.log("error", result.message);
                }
            } catch (error) {
                console.error("An error occured:", error);
            } finally {
                hideLoaders();
            }

        }


        return {
            buttonLoading,
            loadingSpinner,
            first_name,
            last_name,
            msisdn,
            email,
            address,
            saveNewAdmin,
            selectedCountry,
            selectedState,
            selectedGender,
            genderSelectOptions,
            countrySelectOptions,
            stateSelectOptions,
            dropdownLoader,
            rolesSelectOptions,
            selectedRole,
            countryDropdownLoader,
            statesDropdownLoader,
            handleCountrySelection
        }
    }
}
</script>
<style></style>