<template>
    <transition name="slide-in">
        <div :class="['flash-message', messageTypeClass]" v-if="isVisible">
            <img
                src="../../assets/images/icons/success_icon_2.svg"
                alt="Success"
                width="40"
                class="mr-2"
                v-if="messageType === 'success'"
            />

            <img
                src="../../assets/images/icons/warning_icon.svg"
                alt="Success"
                width="30"
                class="mr-2"
                v-if="messageType === 'error'"
            />
            <span class="messageText">{{ messageText }}</span>
        </div>
    </transition>
</template>

<script>
export default {
    props: {
        isVisible: Boolean,
        messageText: String,
        messageType: String,
    },
    computed: {
        messageTypeClass() {
            return this.messageType === "success" ? "success" : "error";
        },
    },
};
</script>

<style scoped>
.flash-message {
    position: fixed;
    top: 100px;
    right: 25px;
    padding: 15px 30px;
    color: #333;
    border-radius: 5px;
    font-weight: bold;
    animation: slide-in 0.5s forwards; /* Apply the slide-in animation */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Add a shadow */
    display: flex;
    align-items: center;
    z-index: 1000;
}

.messageText {
    font-size: 17px;
}

.success {
    background-color: #6dccab;
}

.error {
    background-color: #f4786f;
}

/* Define the slide-in animation using keyframes */
@keyframes slide-in {
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(0);
    }
}

.slide-in-leave-active {
    animation: slide-out 0.3s forwards; /* Apply the slide-out animation */
}

/* Define the slide-out animation using keyframes */
@keyframes slide-out {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(100%);
    }
}
</style>