<template>
    <form @submit.prevent="updateAdmin">
        <div class="row mb-30">
            <div class="col-lg-6 first_name">
                <TextInputField :config="{
                    class: 'form-control-gray',
                    placeholder: 'Enter First Name',
                    inputFieldType: 'text',
                }" labelText="First Name" errorType="nameError" v-model="firstName" />
            </div>

            <div class="col-lg-6">
                <TextInputField :config="{
                    class: 'form-control-gray',
                    placeholder: 'Enter Last Name',
                    inputFieldType: 'text',
                }" labelText="Last Name" errorType="nameError" v-model="lastName" />
            </div>
        </div>

        <div class="row mb-30">
            <div class="col-12">
                <SingleSelectFieldNew labelText="Gender" :options="genderSelectOptions" v-model="selectedGender"
                    :loading="countryDropdownLoader" class="form-control-gray" />
            </div>
        </div>

        <div class="row mb-30">
            <div class="col-lg-6 country">
                <SingleSelectFieldNew labelText="Country of Residence" :options="countrySelectOptions"
                    v-model="selectedCountry" :loading="countryDropdownLoader" selectId="countryOptions"
                    @change="handleCountrySelection" class="form-control-gray" />
            </div>

            <div class="col-lg-6">
                <SingleSelectFieldNew labelText="State of Residence" :options="stateSelectOptions"
                    v-model="selectedState" :loading="statesDropdownLoader" selectId="stateOptions"
                    class="form-control-gray" />
            </div>
        </div>

        <TextAreaInputField :config="{
            class: 'form-control-gray mb-30',
            placeholder: 'Enter Address',
            inputFieldType: 'text',
        }" labelText="Residential Address" errorType="addressError" v-model="address" />

        <SingleSelectFieldNew labelText="Role on Portal" :options="rolesSelectOptions" v-model="selectedRole"
            class="mb-30 form-control-gray" :loading="dropdownLoader" selectId="roleOptions" />

        <RoundedButton buttonText="Submit" class="custom-button" buttonType="submit" :loading="buttonLoading" />

    </form>
</template>
<script>
import ImageUploadInput from "@/components/common/ImageUploadInput.vue";
import TextInputField from "@/components/common/TextInputField.vue";
import SingleSelectFieldNew from "@/components/common/SingleSelectFieldNew.vue";
import TextAreaInputField from "@/components/common/TextAreaInputField.vue";
import RoundedButton from "@/components/common/RoundedButton.vue";
import PhoneNumberFieldInput from "@/components/common/PhoneNumberFieldInput.vue";
import { onMounted, ref, watch } from "vue";
import getRoles from '@/composables/getRoles'
import getCountries from '@/composables/getCountries'
import getStates from '@/composables/getStates'
import { capitalizeFirstLetter, getValidToken } from '@/utils/helpers'
import { checkAndRefreshToken } from '@/utils/auth';
import { API_BASE_URL } from "@/config.js";

export default {
    props: {
        userData: {
            type: Object,
            required: true
        }
    },
    components: {
        ImageUploadInput,
        TextInputField,
        SingleSelectFieldNew,
        TextAreaInputField,
        RoundedButton,
        PhoneNumberFieldInput
    },
    setup(props, { emit }) {
        const { fetchCountriesData, countriesList, loading: countryDropdownLoader } = getCountries()
        const { fetchRolesData, rolesData, loading: dropdownLoader } = getRoles()
        const { fetchStatesData, statesList, loading: statesDropdownLoader } = getStates()
        const buttonLoading = ref(false)
        const loadingSpinner = ref(false);
        const firstName = ref(props.userData.firstName)
        const lastName = ref(props.userData.lastName)
        const address = ref(props.userData.address)
        const selectedGender = ref(props.userData.gender)
        const selectedCountry = ref(props.userData.country)
        const selectedState = ref(props.userData.state)
        const selectedRole = ref(props.userData.roleId)
        const message = ref('')
        const rolesSelectOptions = ref([])
        const countrySelectOptions = ref([])
        const countryIdMap = ref({});
        const stateSelectOptions = ref([])

        const genderSelectOptions = [
            { label: "Select Gender", value: '' },
            { label: "Male", value: 'Male' },
            { label: "Female", value: 'Female' }
        ]


        const updateOptions = (newData) => {
            rolesSelectOptions.value = newData.map(role => ({ label: capitalizeFirstLetter(role.name), value: role.id }));

            if (rolesSelectOptions.value.length > 0) {
                selectedRole.value = props.userData.roleId;
            }
        }
        watch(rolesData, (newData) => {
            updateOptions(newData)
        })



        const updateCountryOptions = (newData) => {
            countrySelectOptions.value = newData.map(country => ({
                label: country.country_name, value: country.country_name
            }))

            countryIdMap.value = newData.reduce((map, country) => {
                map[country.country_name] = country.id;
                return map;
            }, {});

            if (countrySelectOptions.value.length > 0) {
                selectedCountry.value = props.userData.country
                selectedGender.value = props.userData.gender
                handleCountrySelection()
            }
        }
        watch(countriesList, (newData) => {
            updateCountryOptions(newData)
        })



        const updateStatesOptions = (newData) => {
            stateSelectOptions.value = newData.map(state => ({
                label: state.state_name, value: state.state_name
            }))

            if (stateSelectOptions.value.length > 0) {
                selectedState.value = selectedCountry.value === props.userData.country ? props.userData.state : stateSelectOptions.value[0].value
            }
        }
        watch(statesList, (newData) => {
            updateStatesOptions(newData)
        })


        const handleCountrySelection = () => {
            const selectedCountryId = countryIdMap.value[selectedCountry.value]
            fetchStatesData(selectedCountryId)
        }


        onMounted(() => {
            fetchRolesData()
            fetchCountriesData()
        })

        const showLoaders = () => {
            buttonLoading.value = true;
            loadingSpinner.value = true;
        };

        const hideLoaders = () => {
            buttonLoading.value = false;
            loadingSpinner.value = false;
        };


        const updateAdmin = async () => {
            const admin = {
                first_name: firstName.value,
                last_name: lastName.value,
                gender: selectedGender.value,
                country: selectedCountry.value,
                state: selectedState.value,
                residence: address.value,
                role: selectedRole.value,
            }

            // console.log('admin', admin)

            try {
                showLoaders()
                await checkAndRefreshToken()
                const accessToken = getValidToken()
                const apiUrl = `${API_BASE_URL}/admin/update-admin?user_id=${props.userData.id}`

                // console.log('apiUrl', apiUrl)

                const response = await fetch(apiUrl, {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`,
                    },
                    body: JSON.stringify(admin)
                })


                if (response.ok) {
                    const result = await response.json();

                    if (result.code === 200) {
                        message.value = result.message;

                        emit("closeFormModal");
                        emit("refreshPage");
                        emit("message", {
                            apiResponse: "Admin updated successfully",
                            type: "success",
                        });
                    } else {
                        console.log("error", result.message);
                    }
                } else {
                    const result = await response.json();
                    console.log("error", result.message);
                }
            } catch (error) {
                console.error("An error occured:", error);
            } finally {
                hideLoaders();
            }
        }


        return {
            buttonLoading,
            loadingSpinner,
            firstName,
            lastName,
            address,
            updateAdmin,
            selectedCountry,
            selectedState,
            selectedGender,
            genderSelectOptions,
            countrySelectOptions,
            stateSelectOptions,
            dropdownLoader,
            rolesSelectOptions,
            selectedRole,
            countryDropdownLoader,
            statesDropdownLoader,
            handleCountrySelection
        }
    }
}
</script>
<style scoped>
@media (max-width: 767px) {

    .first_name,
    .country {
        margin-bottom: 30px;
    }
}

@media (max-width:992px) {

    .first_name,
    .country {
        margin-bottom: 30px;
    }
}
</style>